(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("AssetDialogController", AssetDialogController)

        .directive("fileread", [
            function () {
                "use strict";

                return {
                    restrict: "A",

                    link: function ($scope, element) {
                        element.change(function (event) {
                            $scope.$apply(function () {
                                $scope.myFile = element[0].files[0];
                                $scope.asset = $scope.assetname;
                            });
                        });
                    },
                };
            },
        ])

        .directive("fileModel", [
            "$parse",
            function ($parse) {
                return {
                    restrict: "A",
                    link: function (
                        scope,
                        element,
                        attrs,
                        $rootScope,
                        $broadcast
                    ) {
                        var model = $parse(attrs.fileModel);
                        var modelSetter = model.assign;

                        element.bind("change", function () {
                            var files = element[0].files;
                            var allowedTypes = {
                                "image/png": true,
                                "image/jpg": true,
                                "image/jpeg": true,
                            };

                            var data = {
                                file: files,
                                types: allowedTypes,
                            };
                            scope.$emit("callFunction", data);
                            //uploadFiles("reference", allowedTypes,files);
                        });
                    },
                };
            },
        ])

        .directive("fileStyle", function () {
            return {
                link: function (scope, element) {
                    $(element).filestyle({
                        buttonBefore: true,
                    });
                    $(element).filestyle("buttonText", "Browse File");
                },
            };
        });

    AssetDialogController.$inject = [
        "$timeout",
        "$scope",
        "$stateParams",
        "AssetImages",
        "GroupAssets",
        "ParseLinks",
        "AlertService",
        "entity",
        "Asset",
        "User",
        "$state",
        "AssetUpload",
        "AllAssetsTypes",
        "AllCustomAssetTypeAttribute",
        "UserGroupIDs",
        "AssetByID",
        "AssetGroupSearch",
        "UserAccount",
        "GeofenceIDs",
        "UserGeofences",
        "AssetGroupES",
        "Geofence",
        "$localStorage",
        "Configs",
        "$rootScope"
    ];

    function AssetDialogController(
        $timeout,
        $scope,
        $stateParams,
        AssetImages,
        GroupAssets,
        ParseLinks,
        AlertService,
        entity,
        Asset,
        User,
        $state,
        AssetUpload,
        AllAssetsTypes,
        AllCustomAssetTypeAttribute,
        UserGroupIDs,
        AssetByID,
        AssetGroupSearch,
        UserAccount,
        GeofenceIDs,
        UserGeofences,
        AssetGroupES,
        Geofence,
        $localStorage,
        Configs,
        $rootScope
    ) {
        var vm = this;

        vm.asset = entity;

        if(vm.asset.id!=null && vm.asset.assetType.layout=='SPREAD'){
            vm.asset.assetCoordinates.sort(function (a, b) {
                // Sort by sortOrder, placing null or undefined at the end
                if (a.sortOrder == null && b.sortOrder != null) {
                    return 1;
                } else if (a.sortOrder != null && b.sortOrder == null) {
                    return -1;
                } else if (a.sortOrder != null && b.sortOrder != null) {
                    // Both have sortOrder, compare numerically
                    return a.sortOrder - b.sortOrder;
                } else {
                    // Both sortOrder are null, fallback to id comparison
                    return a.id - b.id;
                }
            });
        }
        var entity_old = angular.copy(vm.asset);
        vm.clear = clear;
        vm.save = save;
        vm.assetType = entity.assetType;

        vm.upload = upload;
        vm.showAssetImage = false;

        vm.assettypes = loadPage();
        vm.selectattributes = selectedAssetTypeAttributes;
        vm.coOrdinates = [];
        vm.coOrdinate = null;
        var newFence = false;
        var latlng;
        var locations = {};
        var centerlatlng = null;
        vm.customAttributesMap = {};
        var formData, maxSize = '2021440';
        var spreadAssets = [];
        vm.parentLatLng = [];
        vm.userAdmin = {};
        vm.userGeofenceList = null;

        vm.selectedGeofenceId = null;
        vm.prevSelectedGeofenceId = null;
        vm.userGeofences = [];
        var openedInfoWindow = null;
        var prevInfoWindow = null;
        vm.asset.assetImages = [];

        vm.navigateCreateAsset = navigateCreateAsset;
        vm.navigateCreateGroup = navigateCreateGroup;
        vm.navigateAssetType = navigateAssetType;
        vm.filterParentAssets = filterParentAssets;
        vm.filterAssetsOnMapView = filterAssetsOnMapView;
        vm.closeMapFilterModal = closeMapFilterModal;
        vm.saveFilter = saveFilter;
        vm.selectAssetCordinates = false;
        vm.showFilter = false;
        vm.fileWarning = false;
        //vm.referenceImages = false;
        //vm.assetRefAttributes = [];
        var markers = [];
        var polylines = [];
        var noDataText = null;
        var rolloutRoles = [
            "ROLE_ROLLOUT_INFRA",
            "ROLE_ROLLOUT_SURVEY",
            "ROLE_ROLLOUT_ADMIN",
        ];
        var planRoles = [
            "ROLE_PLAN_ADMIN",
            "ROLE_PLAN_INFRA",
            "ROLE_PLAN_SURVEY",
        ];
        var groupPlanRollout = ["Rollout Group", "Planning Group"];
        vm.roleAccess = false;
        vm.groupAccess = false;
        vm.roleRolloutAdmin = false;

        vm.onSelectPhase = function () {
            vm.asset.phase = vm.asset.phase[0].configKey;
        };

        $scope.$on("callFunction", function (event, data) {
            // Call the function in this controller
            uploadFiles(
                "reference",
                data.types,
                data.file,
                event.targetScope.attribute.id
            );
        });

        function loadPage() {
            if (!vm.asset.id) {
                vm.asset.phase = "ROLLOUT";
            }

            vm.multiGroup = [];
            var selectedGroup = [];

            vm.multiassets = [];
            vm.multiassetsLink = [];
            var selectedAssets = [];

            Configs.get("AssetImage", function (response) {
                if (response.length > 0) {
                    if (response[0].configValue == "True") {
                        vm.showAssetImage = true;
                    }
                }
            });

            Configs.get("MaxFileSize", function (response) {
                maxSize = response[0].configValue;
            });

            if (vm.asset.id) {
                
                if (vm.asset.assetReferenceAttributes.length) {
                    vm.assetRefAttributes = [];
                    vm.asset.assetReferenceAttributes.forEach(function (get) {
                        if (get["assetAttributeName"] != null) {
                            get[
                                "assetAttributeName"
                            ].assetTypeAttribute.attributeValue =
                                get["assetAttributeName"].attributeValue;
                            vm.assetRefAttributes.push(
                                get["assetAttributeName"].assetTypeAttribute
                            );
                        }

                        if (get["assetAttributeValue"] != null) {
                            get[
                                "assetAttributeValue"
                            ].assetTypeAttribute.attributeValue =
                                get["assetAttributeValue"].attributeValue;
                            vm.assetRefAttributes.push(
                                get["assetAttributeValue"].assetTypeAttribute
                            );
                        }

                        if (get["assetAttributeImage"] != null)   {
                            get[
                                "assetAttributeImage"
                            ].assetTypeAttribute.attributeValue =
                                get["assetAttributeImage"].image;
                            get[
                                    "assetAttributeImage"
                                ].assetTypeAttribute.attributeName =
                                    get["assetAttributeImage"].imageName;
                            get[
                                        "assetAttributeImage"
                                    ].assetTypeAttribute.imageId =
                                        get["assetAttributeImage"].imageId;
                                        get[
                                            "assetAttributeImage"
                                        ].assetTypeAttribute.attributeType =
                                            get["assetAttributeImage"].imageType;
                            vm.assetRefAttributes.push(
                                get["assetAttributeImage"].assetTypeAttribute
                            );
                        }
                    });
                }else{
                    vm.assetRefAttributes = [];
                    if (vm.asset.assetType.assetTypeReferenceAttributes.length) {
                        vm.asset.assetType.assetTypeReferenceAttributes.forEach(function (get) {
                            get["assetAttributeName"].attributeValue =
                            get["assetAttributeName"].defaultValue;
                        vm.assetRefAttributes.push(get["assetAttributeName"]);
                        get["assetAttributeValue"].attributeValue =
                            get["assetAttributeValue"].defaultValue;
                        vm.assetRefAttributes.push(get["assetAttributeValue"]);
                        vm.assetRefAttributes.push(get["assetAttributeImage"]);
                        });
                     }
                    
                }
            }

            vm.imageInNewTab = function (img) {
                var imgWindow = window.open();
                imgWindow.document.write(
                    '<img width="25%"   src="data:image/jpeg;base64,' +
                        img +
                        '">'
                );
                imgWindow.document.close();
            };

            if (vm.asset.id != null) {
                vm.asset.ownedBy.forEach(function (asset) {
                    selectedAssets.push(asset.id);
                    vm.selectedParent = asset.name;
                    AssetGroupSearch.query(
                        {
                            search: asset.name,
                        },
                        onSuccess,
                        onError
                    );

                    function onSuccess(data, headers) {
                        vm.parentAssets = data;
                    }

                    function onError(error) {
                        AlertService.error(error.data.message);
                    }
                });

                vm.asset.groups.forEach(function (group) {
                    selectedGroup.push(group.id);
                });
            }

            var assetTypeAttributeValuesList =
                vm.asset.assetTypeAttributeValues;

            try {
                for (var index in vm.asset.assetType.assetTypeAttributes) {
                    if (
                        !checkIfAssetTypeExists(
                            vm.asset.assetType.assetTypeAttributes[index],
                            vm.asset.assetTypeAttributeValues
                        )
                    ) {
                        var assetTypeAttribute1 =
                            vm.asset.assetType.assetTypeAttributes[index];
                        assetTypeAttributeValuesList.push({
                            assetTypeAttribute: assetTypeAttribute1,
                            attributeValue:
                                vm.asset.assetType.assetTypeAttributes[index]
                                    .defaultValue,
                        });
                    }
                }
            } catch (e) {}

            function checkIfAssetTypeExists(assetType, assetTypeAttrVal) {
                var hasAssetType = false;
                for (var i = 0; i < assetTypeAttrVal.length; i++) {
                    var name = assetTypeAttrVal[i].assetTypeAttribute.name;
                    if (name == assetType.name) {
                        hasAssetType = true;
                        break;
                    }
                }
                return hasAssetType;
            }

            // assetTypeAttributeValuesList.sort(function (a, b) {
            //     var nameA = a.assetTypeAttribute.name.toLowerCase(),
            //         nameB = b.assetTypeAttribute.name.toLowerCase();
            //     if (nameA < nameB)
            //         // sort string ascending
            //         return -1;
            //     if (nameA > nameB) return 1;
            //     return 0; // default return value (no sorting)
            // });
            // vm.asset.assetTypeAttributeValues = assetTypeAttributeValuesList;

            UserGroupIDs.getAll(function (response) {
                response.forEach(function (group) {
                    if (selectedGroup.indexOf(group.id) !== -1) {
                        vm.multiGroup.push({
                            name: group.name,
                            id: group.id,
                            ticked: true,
                        });
                    } else {
                        vm.multiGroup.push({
                            name: group.name,
                            id: group.id,
                            ticked: false,
                        });
                    }

                    if (groupPlanRollout.includes(group.name)) {
                        vm.groupAccess = true;
                    }
                });
            });
            //            if (!entity.assetImport) {
            //                getGroupAsset(0, vm.multiassets, selectedAssets);
            //            }

            AllAssetsTypes.getAll(function (response) {
                vm.assettypes = response;
            });

            AllCustomAssetTypeAttribute.getAll(function (response) {
                // vm.customCaseTypeAttributes = response;
                response.forEach(function (arrayItem) {
                    vm.customAttributesMap[arrayItem.name] =
                        arrayItem.customAssetTypeAttributeValues;
                });
            });

            vm.multiassets.sort(function (a, b) {
                var nameA = a.name.toLowerCase(),
                    nameB = b.name.toLowerCase();
                if (nameA < nameB)
                    // sort string ascending
                    return -1;
                if (nameA > nameB) return 1;
                return 0; // default return value (no sorting)
            });

            UserAccount.get(function (data) {
                vm.userAdmin = data;
                var isAdmin = false;
                data.authorities.forEach(function (val) {
                    if (val == "ROLE_USER_ADMIN") {
                        isAdmin = true;
                    }
                    if (val == "ROLE_HOD") {
                        isAdmin = true;
                    }

                    if (
                        val == "ROLE_ROLLOUT_ADMIN" &&
                        vm.asset.phase == "ROLLOUT"
                    ) {
                        vm.roleRolloutAdmin = true;
                    }

                    if (rolloutRoles.includes(val) || planRoles.includes(val)) {
                        vm.roleAccess = true;
                    }
                });
                if (isAdmin) {
                    GeofenceIDs.getAll(function (response) {
                        response.forEach(function (geofence) {
                            if (geofence.name != "Bangalore") {
                                vm.userGeofences.push(geofence);
                                if (vm.selectedGeofenceId == null) {
                                    vm.selectedGeofenceId = geofence.id;
                                    vm.canFilter = true;
                                }
                            }
                        });
                    });
                } else {
                    UserGeofences.getAll(function (response) {
                        vm.userGeofenceList = response;
                        response.forEach(function (geofence) {
                            if (geofence.name != "Bangalore") {
                                vm.userGeofences.push(geofence);
                                if (vm.selectedGeofenceId == null) {
                                    vm.selectedGeofenceId = geofence.id;
                                    vm.canFilter = true;
                                }
                            }
                        });
                    });
                }
            });
            if (vm.asset.id) {
                vm.nameImages = [];
                vm.asset.assetImages.forEach(function (item) {
                    vm.nameImages.push(item);
                });
            }
        }

        $scope.newTabDisplay = function (id,name,str) {
            var request = new XMLHttpRequest(), apiImage = name == 'reference' ? "api/trakeyeassetrefimages/" : "api/trakeyeassetimages/";
            
            request.open(
                    "GET",
                    apiImage + vm.asset.id + "/" + id,
                    true
            );
            
            request.setRequestHeader(
                "Authorization",
                "Bearer " + $localStorage.authenticationToken
            );
            request.responseType = "blob";
            request.onload = response;

            function response(e) {


                /* if (this.status === 200) {
                    var urlCreator = window.URL || window.webkitURL;
                    var imageUrl = urlCreator.createObjectURL(this.response);
                    window.open(imageUrl, "_blank");
                } */

                if (this.status === 200) {
                    var urlCreator = window.URL || window.webkitURL;
                  
                    
                    if(str == "MP4"){
                        var videoUrl = urlCreator.createObjectURL(this.response);
                        
                        var video = document.createElement('video');
                        video.src = videoUrl;
                        video.controls = true;
                        video.autoplay = true;
                
                        var videoWindow = window.open("", "_blank");
                        videoWindow.document.body.appendChild(video);
                    }else{
                        var imageUrl = urlCreator.createObjectURL(this.response);
                        window.open(imageUrl, "_blank");
                    }
                   
                }

            }
            request.send();
        };

        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
                {
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#EFEFEF",
                        },
                    ],
                }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles(),
            });
        }

        function geoLocation(map) {
            var infoWindow = new google.maps.InfoWindow();
            var locationButton = document.createElement("button");
            locationButton.setAttribute("title", "Locate Me");
            locationButton.classList.add("custom-map-control-button");
            var geoIcon = document.createElement("i");
            geoIcon.classList.add("fa");
            geoIcon.classList.add("fa-crosshairs");
            geoIcon.classList.add("geo");
            locationButton.appendChild(geoIcon);
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
                locationButton
            );

            locationButton.addEventListener("click", function () {
                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            var pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");
                            infoWindow.open(map);
                            map.setCenter(pos);
                            map.setZoom(18);
                        },
                        function () {
                            handleLocationError(
                                true,
                                infoWindow,
                                map.getCenter()
                            );
                        },
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                } else {
                    // Browser doesn't support Geolocation
                    handleLocationError(false, infoWindow, map.getCenter());
                }
            });

            function handleLocationError(
                browserHasGeolocation,
                infoWindow,
                pos
            ) {
                infoWindow.setPosition(pos);
                infoWindow.setContent(
                    browserHasGeolocation
                        ? "Error: The Geolocation service failed."
                        : "Error: Your browser doesn't support geolocation."
                );
                infoWindow.open(map);
            }
        }

        function filterParentAssets() {
            // if (vm.asset.user.length > 2 && vm.asset.user != null && vm.asset.user != "" && vm.asset.user != "." && !angular.isUndefined(vm.asset.user)) {
            if (vm.selectedParent.length > 1) {
                AssetGroupSearch.query(
                    {
                        search: vm.selectedParent,
                    },
                    onSuccess,
                    onError
                );

                function onSuccess(data, headers) {
                    vm.parentAssets = data;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            }
            // }
        }

        Configs.get("Phases", function (response) {
            vm.phases = response;
            /*  response.forEach(function (item){
                 if(!vm.asset.phase){
                     item.ticked = (item.configKey == "ROLLOUT") ? true : false
                      
                 }else{
                     item.ticked = (vm.asset.phase == item.configKey) ? true : false
                 }

                 if(vm.roleRolloutAdmin){
                     item.roleRolloutAdmin = false
                 }else {
                     item.roleRolloutAdmin = true
                 }
             }) */
        });

        function getGroupAsset(pageNum, mapAssetLists, selectedAssets) {
            GroupAssets.query(
                {
                    page: pageNum,
                    size: 500,
                },
                onSuccess,
                onError
            );

            function onSuccess(data, headers) {
                vm.multiassets = mapAssetLists;
                data.forEach(function (asset) {
                    if (selectedAssets.indexOf(asset.id) !== -1) {
                        vm.multiassets.push({
                            lat:
                                asset.assetCoordinates[
                                    asset.assetCoordinates.length - 1
                                ].latitude,
                            lng:
                                asset.assetCoordinates[
                                    asset.assetCoordinates.length - 1
                                ].longitude,
                            name: asset.name,
                            layout: asset.assetType.layout,
                            assetType: asset.assetTypeName,
                            assetCoordinates: asset.assetCoordinates,
                            id: asset.id,
                            ownedBy: asset.ownedBy,
                            ticked: true,
                        });
                    } else {
                        try {
                            vm.multiassets.push({
                                lat:
                                    asset.assetCoordinates[
                                        asset.assetCoordinates.length - 1
                                    ].latitude,
                                lng:
                                    asset.assetCoordinates[
                                        asset.assetCoordinates.length - 1
                                    ].longitude,
                                name: asset.name,
                                assetType: asset.assetTypeName,
                                layout: asset.assetType.layout,
                                assetCoordinates: asset.assetCoordinates,
                                id: asset.id,
                                ownedBy: asset.ownedBy,
                                ticked: false,
                            });
                        } catch (e) {
                            // TODO: handle exception
                        }
                    }
                });
                if (vm.assetTotalPage == 0) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.assetTotalPage = Math.ceil(vm.totalItems / 500);
                }

                vm.assetCurrentPage = pageNum;

                if (vm.assetTotalPage > 1) {
                    if (vm.assetCurrentPage != vm.assetTotalPage) {
                        //                        getGroupAsset(pageNum + 1, vm.multiassets, selectedAssets);
                    } else {
                        var lastAsset =
                            vm.multiassets[vm.multiassets.length - 1];
                        if (lastAsset.assetCoordinates.length > 0) {
                            var latestLatLng = {
                                lat: lastAsset.assetCoordinates[0].latitude,
                                lng: lastAsset.assetCoordinates[0].longitude,
                            };
                            map.setCenter(latestLatLng);
                        }
                    }
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function upload() {
            formData = new FormData();

            formData.append("assettype", vm.importassettype.name);
            formData.append("file", $scope.myFile);

            AssetUpload.assetupload(formData, function (response, headers) {
                if (response.status == 200) {
                    vm.error = null;
                    vm.success = "OK";
                    $scope.spreadassetname = "";

                    $timeout(function () {
                        vm.success = "";
                    }, 4000);
                }
                if (response.status == 400) {
                    vm.success = null;
                    vm.error = "error";
                }
            });
        }

        $(":file").filestyle({
            buttonBefore: true,
        });
        // $(":file").filestyle('placeholder', 'Choose File');
        $(":file").filestyle("buttonText", "Browse File");

        //		$scope.importxlsx = /^(.*?)\.(xlsx)$/;
        $scope.importxlsx = /^(.*?)\.(xlsx|kml)$/;

        /* import end */
        vm.createNewFence = function () {
            newFence = true;
            vm.asset.assetCoordinates = [];
            vm.createfence();
        };

        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        $("#assetImages").change(function () {
            $scope.allimages = false; // to close preview table
            var allowedTypes = {
                "image/png": true,
                "image/jpg": true,
                "image/jpeg": true,
                "video/mp4": true
            };

            uploadFiles("assetImages", allowedTypes);
        });

        $("#assetAttachment").change(function () {
            var allowedTypes = {
                "application/pdf": true,
            };

            uploadFiles("assetAttachment", allowedTypes);
        });

        function uploadFiles(id, allowedTypes, fileReference, referenceId) {
            if (id == "reference") {
                filesSelected = fileReference;
            } else if (id != "reference") {
                var filesSelected = document.getElementById(id).files;
            } //vm.attributeImage = [] 1039
            for (var i = 0; i < filesSelected.length; i++) {
                if (
                    allowedTypes[filesSelected[i].type] &&
                    filesSelected[i].size <= maxSize
                ) {
                    if (id != "reference") {
                        var fileReader = new FileReader();
                        fileReader.onload = function (fileLoadedEvent) {
                            vm.asset.assetImages.push({
                                image: window.btoa(
                                    fileLoadedEvent.target.result
                                ),
                                asset: {
                                    id: entity_old.id,
                                },
                                imageName: fileLoadedEvent.target.name,
                                imageType: fileLoadedEvent.target.type,
                            });
                        };
                        fileReader.name = filesSelected[i].name.split(".")[0];
                        fileReader.type = filesSelected[i].type
                            .split("/")[1]
                            .toUpperCase();

                            if(allowedTypes[filesSelected[i].type] == "video/mp4"){
                                fileReader.readAsDataURL(filesSelected[i]);
                            }else{
                                fileReader.readAsBinaryString(filesSelected[i]);
                            }
                    } else if (id == "reference") {
                        vm.assetRefAttributes.forEach(function (val) {
                            if (val.id == referenceId) {
                                var fileReader_reference = new FileReader();
                                fileReader_reference.onload = function (
                                    fileLoadedEvent
                                ) {
                                    val.attributeValue = window.btoa(
                                            fileLoadedEvent.target.result
                                        );
                                    val.attributeName =  fileLoadedEvent.target.name;
                                    val.attributeType =  fileLoadedEvent.target.type;
                                };
                                fileReader_reference.name = filesSelected[i].name.split(".")[0];
                                fileReader_reference.type = filesSelected[i].type
                                    .split("/")[1]
                                    .toUpperCase();
                                fileReader_reference.readAsBinaryString(
                                    filesSelected[i]
                                );
                            }
                        });
                    }

                    if (
                        vm.fileWarning ||
                        vm.imageWarning ||
                        vm.referenceWarning
                    ) {
                        boolWarning(id, false);
                    }
                } else {
                    boolWarning(id, true);
                }
            }
        }

        function boolWarning(id, bool) {
            switch (id) {
                case "assetAttachment":
                    vm.fileWarning = bool;
                    break;
                case "assetImages":
                    vm.imageWarning = bool;
                    break;
                case "referenceImages":
                    vm.referenceWarning = bool;
                    break;
            }
            $scope.$apply();
        }

        $scope.deleteDisplayImage = function (id) {
            vm.asset.assetImages.splice(id, 1);
            $(":file").filestyle("clear");
        };

        $scope.toggle = function (id) {
            switch (id) {
                case "images":
                    $scope.allimages = !$scope.allimages;
                    break;

                case "pdf":
                    vm.showAttachments = !vm.showAttachments;
                    vm.showPdf = !vm.showPdf;
                    break;

                case "existingImg":
                    vm.showExistingImage = !vm.showExistingImage;
                    vm.showImg = !vm.showImg;
                    break;

                default:
                    console.log("");
            }
            /* //$("#soundtrack").get(0).play();
            
            if ($scope.allimages) {
                AssetImages.query(
                    {
                        assetId: vm.asset.id,
                    },
                    onSuccess,
                    onError
                );

                function onSuccess(data, headers) {
                    vm.assetImages = data;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            }*/
        };

        function save() {
            if (vm.asset.assetCoordinates.length > 0) {
                vm.asset.ownedBy = [];
                var parentLat = 0.0;
                var parentLng = 0.0;
                if (vm.parentAssets != null) {
                    vm.parentAssets.forEach(function (value) {
                        if (value.name == vm.selectedParent) {
                            var tempAsset = {};
                            tempAsset.id = value.id;
                            tempAsset.name = value.name;
                            parentLat =
                                value.assetCoordinates[
                                    value.assetCoordinates.length - 1
                                ].latitude;
                            parentLng =
                                value.assetCoordinates[
                                    value.assetCoordinates.length - 1
                                ].longitude;
                            vm.asset.ownedBy.push(tempAsset);
                        }
                    });
                }
                //vm.isSaving = true;
                var fiberDistance = 0.0;
                if (vm.asset.assetType.layout == "SPREAD") {
                    var spreadCoordinates = vm.asset.assetCoordinates;
                    if (spreadCoordinates.length > 0) {
                        for (var index in spreadCoordinates) {
                            if (index > 0) {
                                fiberDistance += distanceCal(
                                    spreadCoordinates[index].latitude,
                                    spreadCoordinates[index].longitude,
                                    spreadCoordinates[index - 1].latitude,
                                    spreadCoordinates[index - 1].longitude
                                );
                            }
                        }
                    }
                    fiberDistance = fiberDistance.toFixed(2);
                    var spreadAV = vm.asset.assetTypeAttributeValues;
                    for (
                        var spreadAssetIndex = 0;
                        spreadAssetIndex < spreadAV.length;
                        spreadAssetIndex++
                    ) {
                        if (
                            spreadAV[spreadAssetIndex].assetTypeAttribute
                                .name === "Fiber Distance (km)"
                        ) {
                            spreadAV[
                                spreadAssetIndex
                            ].attributeValue = fiberDistance;
                        }
                    }
                    vm.asset.assetTypeAttributeValues = spreadAV;
                    // var tempCoordinates = [];
                    // tempCoordinates.push({ latitude: parentLat, longitude: parentLng });
                    // vm.asset.assetCoordinates.forEach(function(value) {
                    //     tempCoordinates.push(value);
                    // });
                    // vm.asset.assetCoordinates = tempCoordinates;
                }

                /* vm.asset.assetTypeAttributeValues.forEach(function (attribute){
                    if(attribute.assetTypeAttribute.attributeDataType.name == 'IMAGE'){
                        attribute.attributeValue =  vm.attributeImage
                    }
                }) 1039 */
                vm.asset.assetReferenceAttributes = [];
                if (vm.assetRefAttributes != null) {
                    for (var i = 0; i < vm.assetRefAttributes.length; i += 3) {
                        var obj = {};

                        obj["assetAttributeName"] = vm.assetRefAttributes[i]
                            ? {
                                  assetTypeAttribute: vm.assetRefAttributes[i],
                                  attributeValue:
                                      vm.assetRefAttributes[i].attributeValue,
                                  order: 0,
                                  popupDisplay:
                                      vm.assetRefAttributes[i]
                                          .assetTypePopupDisplay,
                              }
                            : null;

                        obj["assetAttributeValue"] = vm.assetRefAttributes[
                            i + 1
                        ]
                            ? {
                                  assetTypeAttribute:
                                      vm.assetRefAttributes[i + 1],
                                  attributeValue:
                                      vm.assetRefAttributes[i + 1]
                                          .attributeValue,
                                  order: 1,
                                  popupDisplay:
                                      vm.assetRefAttributes[i + 1]
                                          .assetTypePopupDisplay,
                              }
                            : null;


                        obj["assetAttributeImage"] = vm.assetRefAttributes[
                            i + 2
                        ]
                            ? {
                                  assetTypeAttribute:
                                      vm.assetRefAttributes[i + 2],
                                  image:
                                      vm.assetRefAttributes[i + 2]
                                          .attributeValue,
                                  imageName : vm.assetRefAttributes[i + 2]
                                          .attributeName,
                                  imageType : vm.assetRefAttributes[i + 2]
                                  .attributeType,
                                  imageId : vm.assetRefAttributes[i + 2]
                                  .imageId,
                                  attributeValue: "images",
                                  order: 2,
                                  popupDisplay:
                                      vm.assetRefAttributes[i + 2]
                                          .assetTypePopupDisplay,
                              }
                            : null;
                        vm.asset.assetReferenceAttributes.push(obj);
                    }
                }
                vm.assetTypeReferenceAttributesVal = [];
                vm.asset.assetReferenceAttributes.forEach(function (value) {
                    var assetTypeReferenceAttributesValue = value;
                    vm.assetType.assetTypeReferenceAttributes.forEach(function (
                        assettyperef
                    ) {
                        if (
                            value.assetAttributeName.assetTypeAttribute.id ==
                            assettyperef.assetAttributeName.id
                        ) {
                            assetTypeReferenceAttributesValue.assetRfrncAttribOrder =
                                assettyperef.assetTypeRfrncAttribOrder;
                        }
                    });
                    vm.assetTypeReferenceAttributesVal.push(
                        assetTypeReferenceAttributesValue
                    );
                });
                vm.asset.assetReferenceAttributes =
                    vm.assetTypeReferenceAttributesVal;

                if (vm.asset.id !== null) {
                    Asset.update(vm.asset, onSaveSuccess, onSaveError);
                } else {
                    Asset.save(vm.asset, onSaveSuccess, onSaveError);
                }
            } else {
                vm.selectAssetCordinates = true;
                // alert("Asset Coordinates should not be empty");
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = true;
            if (vm.asset.groups.length > 0) {
                vm.isSaving = false;
                $scope.$emit("trakeyeApp:assetUpdate", result);
                var pageValue = sessionStorage.getItem("prevPage");
                var previousId = sessionStorage.getItem("prevId");
                var prevEditDetail = sessionStorage.getItem("prevEditId");
                var preEditPage = sessionStorage.getItem("firstEditPage");
                var preEditDetailPage = sessionStorage.getItem(
                    "firstEditDetailPage"
                );

                var assetToassetVar = sessionStorage.getItem(
                    "assetCreateToassetEdit"
                );
                var assetTypeToAssetVar = sessionStorage.getItem(
                    "AssetTypeToEditAsset"
                );
                var groupToAssetVar = sessionStorage.getItem(
                    "groupToEditAsset"
                );

                var groupToEditAssetDetailVar = sessionStorage.getItem(
                    "groupToEditAssetDetail"
                );
                var AssetTypeToEditDetailsVar = sessionStorage.getItem(
                    "AssetTypeToEditDetails"
                );
                var assetCreateToassetEditDetailsVar = sessionStorage.getItem(
                    "assetCreateToassetEditDetails"
                );

                if (pageValue == "geofence.new") {
                    $state.go("geofence.new");
                } else if (pageValue == "asset") {
                    $state.go("asset");
                } else if (previousId) {
                    if (
                        preEditPage == "asset" ||
                        groupToAssetVar == "groupToasset" ||
                        assetTypeToAssetVar == "assetTypeToAsset" ||
                        assetToassetVar == "assetToasset"
                    ) {
                        $state.go("asset", {
                            id: previousId,
                        });
                    } else if (preEditPage == "assetEdit") {
                        sessionStorage.setItem(
                            "assetCreateToassetEdit",
                            "assetToasset"
                        );
                        $state.go("asset.edit", {
                            id: previousId,
                        });
                    }
                } else if (prevEditDetail) {
                    if (
                        preEditDetailPage == "assetDetail" ||
                        groupToEditAssetDetailVar == "groupToassetDetail" ||
                        AssetTypeToEditDetailsVar ==
                            "assetTypeToAssetDetails" ||
                        assetCreateToassetEditDetailsVar ==
                            "assetToassetDetails"
                    ) {
                        $state.go("asset", {
                            id: prevEditDetail,
                        });
                    } else if (preEditDetailPage == "assetEditDetails") {
                        sessionStorage.setItem(
                            "assetCreateToassetEditDetails",
                            "assetToassetDetails"
                        );
                        $state.go("asset-detail.edit", {
                            id: prevEditDetail,
                        });
                    }
                } else {
                    $state.go("asset");
                }
            }
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function selectedAssetTypeAttributes() {
            vm.createfence()
            vm.showFilter = true;
            vm.asset.ownedBy = [];
            //vm.referenceAttribute = [];
            if (vm.parentAssets != null) {
                vm.parentAssets.forEach(function (value) {
                    if (value.name == vm.selectedParent) {
                        var tempAsset = {};
                        tempAsset.id = value.id;
                        tempAsset.lat = value.assetCoordinates[0].latitude;
                        tempAsset.lng = value.assetCoordinates[0].longitude;
                        vm.asset.ownedBy.push(tempAsset);
                    }
                });
            }
            if (vm.assetType) {
                if (vm.assetType.assetTypeReferenceAttributes.length) {
                    vm.assetRefAttributes = [];
                    vm.assetType.assetTypeReferenceAttributes.forEach(function (
                        get
                    ) {
                        get["assetAttributeName"].attributeValue =
                            get["assetAttributeName"].defaultValue;
                        vm.assetRefAttributes.push(get["assetAttributeName"]);
                        get["assetAttributeValue"].attributeValue =
                            get["assetAttributeValue"].defaultValue;
                        vm.assetRefAttributes.push(get["assetAttributeValue"]);
                        vm.assetRefAttributes.push(get["assetAttributeImage"]);
                    });
                }
                if (vm.asset.ownedBy != "") {
                    for (var i = 0; i < vm.multiassets.length; i++) {
                        if (vm.multiassets[i].id == vm.asset.ownedBy[0].id) {
                            if (vm.multiassets[i].layout == "FIXED") {
                                vm.fixedArray = [];
                                var coordinates = {};
                                coordinates.latitude =
                                    vm.multiassets[
                                        i
                                    ].assetCoordinates[0].latitude;
                                coordinates.longitude =
                                    vm.multiassets[
                                        i
                                    ].assetCoordinates[0].longitude;
                                vm.fixedArray.push(coordinates);
                                vm.asset.assetCoordinates = vm.fixedArray;
                                vm.parentLatLng.push({
                                    latitude:
                                        vm.multiassets[i].assetCoordinates[0]
                                            .latitude,
                                    longitude:
                                        vm.multiassets[i].assetCoordinates[0]
                                            .longitude,
                                });
                            }
                        }
                    }
                }

                vm.asset.assetType = vm.assetType;
                if (
                    entity_old.assetType &&
                    vm.assetType.id === entity_old.assetType.id
                ) {
                    vm.asset.assetTypeAttributeValues = [];
                    vm.asset.assetTypeAttributeValues =
                        entity_old.assetTypeAttributeValues;
                } else {
                    vm.asset.assetTypeAttributeValues = [];
                    $.each(
                        vm.assetType.assetTypeAttributes,
                        function (key, value) {
                            vm.asset.assetTypeAttributeValues.push({
                                assetTypeAttribute:
                                    vm.assetType.assetTypeAttributes[key],
                            });
                        }
                    );
                    /* vm.asset.assetReferenceAttributes = [];
                    $.each(
                        vm.assetType.assetTypeReferenceAttributes,
                        function (key, value) {
                            vm.asset.assetReferenceAttributes.push([
                                vm.assetType.assetTypeReferenceAttributes[key]
                            ]);
                        }
                    );
                    var referenceAttribute = [];
                    vm.assetType.assetTypeReferenceAttributes.forEach(function(value){
                        var attrName = {};
                        attrName["assetTypeAttribute"] = value.assetAttributeName;
                        attrName["attributeValue"] = value.assetAttributeName.defaultValue;
                        attrName["order"] = value.assetAttributeName.assetTypeAttribOrder;
                        attrName["popupDisplay"] = value.assetAttributeName.assetTypePopupDisplay;
                        var attrValue = {};
                        attrValue["assetTypeAttribute"] = value.assetAttributeValue;
                        attrValue["attributeValue"] = value.assetAttributeValue.defaultValue;
                        attrValue["order"] = value.assetAttributeValue.assetTypeAttribOrder;
                        attrValue["popupDisplay"] = value.assetAttributeValue.assetTypePopupDisplay;
                        referenceAttribute.push({
                            assetAttributeName: attrName,
                            assetAttributeValue: attrValue
                        });
                    });
                    vm.asset.assetReferenceAttributes = referenceAttribute; */
                }
            }
        }
        vm.createfence = function () {
            var elem = document.getElementById("map_canvas");
            vm.asset.ownedBy = [];
            if (vm.parentAssets != null) {
                vm.parentAssets.forEach(function (value) {
                    if (value.name == vm.selectedParent) {
                        var tempAsset = {};
                        tempAsset.id = value.id;
                        tempAsset.lat = value.assetCoordinates[0].latitude;
                        tempAsset.lng = value.assetCoordinates[0].longitude;
                        vm.asset.ownedBy.push(tempAsset);
                    }
                });
            }
            /* if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                //Firefox
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                //Chrome, Safari & Opera
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                //IE/Edge
                elem.msRequestFullscreen();
            } */

            if (vm.asset.ownedBy == "") {
                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas"),
                    {
                        center: {
                            lat: parseFloat($rootScope.DEFAULT_LATITUDE),
                            lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
                        },
                        zoom: 13,
                        fullscreenControl: true,
                        styles: mapStyles(),
                    }
                );
                geoLocation(locations.map);
            } else {
                var coordinates = [];
                if (vm.asset.ownedBy.length > 0) {
                    coordinates.push({
                        lat: vm.asset.ownedBy[0].lat,
                        lng: vm.asset.ownedBy[0].lng,
                    });
                }
                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas"),
                    {
                        center: coordinates[0],
                        zoom: 13,
                        fullscreenControl: true,
                        styles: mapStyles(),
                    }
                );
                geoLocation(locations.map);
            }

            if (vm.assetType.layout == "SPREAD") {
                setGeofences();
                drawingManagerSpread();
                drawingManagerValue("polylinecomplete", locations.map);
            } else if (vm.assetType.layout == "FIXED") {
                setGeofences();
                drawingManagerFixed();
                drawingManagerValue("click", locations.map);
            }

            if (entity.id && entity.id != null && !newFence) {
                if (vm.assetType.layout == "SPREAD") {
                    drawPolyline(function (polyline) {
                        polyline.setMap(locations.map);
                        google.maps.event.addListener(
                            polyline.getPath(),
                            "insert_at",
                            function () {
                                setGoefenceCoordinate(polyline);
                            }
                        );
                        google.maps.event.addListener(
                            polyline.getPath(),
                            "set_at",
                            function (path) {
                                setGoefenceCoordinate(polyline);
                            }
                        );

                        vm.drawingManager.setOptions({
                            drawingControl: false,
                        });
                        vm.drawingManager.setMap(null);
                    });
                } else if (vm.assetType.layout == "FIXED") {
                    if (vm.asset.assetCoordinates.length > 1) {
                        drawPolyline(function (polyline) {
                            polyline.setMap(locations.map);
                            google.maps.event.addListener(
                                polyline.getPath(),
                                "insert_at",
                                function () {
                                    setGoefenceCoordinate(polyline);
                                }
                            );
                            google.maps.event.addListener(
                                polyline.getPath(),
                                "set_at",
                                function (path) {
                                    setGoefenceCoordinate(polyline);
                                }
                            );

                            vm.drawingManager.setOptions({
                                drawingControl: false,
                            });
                            vm.drawingManager.setMap(null);
                        });
                    } else {
                        var latitudelongitude = {
                            latitude: vm.asset.assetCoordinates[0].latitude,
                            longitude: vm.asset.assetCoordinates[0].longitude,
                        };

                        setValues();
                        drawingManagerFixed();
                        drawMarker(locations.map, vm.coOrdinate);
                        // drawFixedAssetImages(vm.coOrdinate);
                    }
                }
                vm.mapAssets = []
                getGroupAssetsFromES(vm.asset.geofences[0].id, null, vm.mapAssets);

            }

            function drawPolyline(callback) {
                setValues();
                JSON.parse(vm.coOrdinate).forEach(function (value, key) {
                    centerlatlng = {
                        lat: value.lat,
                        lng: value.lng,
                    };
                });
                callback(
                    new google.maps.Polyline({
                        path: JSON.parse(vm.coOrdinate),
                        geodesic: true,
                        strokeColor: vm.asset.assetType.colorcode,
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: vm.asset.assetType.colorcode,
                        fillOpacity: 0.3,
                        editable: true,
                    })
                );
                locations.map.setCenter(centerlatlng);
            }

            spreadAssets = [];
            var multiAssetList = vm.multiassets;
            var latlngs = [];
            for (var i = 0; i < multiAssetList.length; i++) {
                var assetCoordinateValues = multiAssetList[i].assetCoordinates;
                if (assetCoordinateValues.length == 1) {
                    latlngs.push({
                        lat:
                            assetCoordinateValues[
                                assetCoordinateValues.length - 1
                            ].latitude,
                        lng:
                            assetCoordinateValues[
                                assetCoordinateValues.length - 1
                            ].longitude,
                        name: multiAssetList[i].name,
                        assetType: multiAssetList[i].assetType,
                    });
                }
                drawPolylineForGeneratedLoc(
                    assetCoordinateValues,
                    map,
                    "yellow"
                );
            }

            for (var i = 0; i < vm.multiassetsLink.length; i++) {
                var assetCoordinateValues =
                    vm.multiassetsLink[i].assetCoordinates;
                drawPolylineForGeneratedLoc(
                    assetCoordinateValues,
                    map,
                    "yellow"
                );
            }

            for (var i = 0; i < latlngs.length; i++) {
                var myLatLng = { lat: latlngs[i].lat, lng: latlngs[i].lng };
                if (
                    latlngs[i].assetType != "WDM" &&
                    latlngs[i].assetType != "Router" &&
                    latlngs[i].assetType != "NTTN" &&
                    latlngs[i].assetType != "Others" &&
                    latlngs[i].assetType != "BackToBack"
                ) {
                    createMarkerOfAllAssets(myLatLng, map, latlngs[i].name);
                }
            }

            for (var i = 0; i < multiAssetList.length; i++) {
                var array_element = multiAssetList[i];
                if (array_element.layout == "SPREAD") {
                    spreadAssets.push(array_element);
                }
            }
            for (var i = 0; i < spreadAssets.length; i++) {
                var spreadAssetAC = spreadAssets[i].assetCoordinates;
                for (var j = 0; j < spreadAssetAC.length; j++) {
                    if (j > 0) {
                        var locValues = [];
                        locValues.push(spreadAssetAC[j - 1]);
                        locValues.push(spreadAssetAC[j]);
                        drawPolylineForGeneratedLoc(locValues, map, "black");
                    }
                }
            }
        };

        function navigateCreateAsset() {
            var previousId = sessionStorage.getItem("prevId");
            var editDetailValue = sessionStorage.getItem("prevEditId");
            sessionStorage.setItem("firstEditPage", "assetEdit");
            sessionStorage.setItem("firstEditDetailPage", "assetEditDetails");
            if (previousId) {
                $state.go("asset.new", {
                    id: previousId,
                });
            }
            if (editDetailValue) {
                $state.go("asset.new", {
                    id: editDetailValue,
                });
            }
        }

        function navigateCreateGroup() {
            sessionStorage.setItem("createGroup", "createAssetToCreateGroup");
            $state.go("group.new", {});
            // var previousId = sessionStorage.getItem("prevId");
            // var editDetailValue = sessionStorage.getItem("prevEditId");
            // console.log('previousId', previousId);
            // console.log('editDetailValue', editDetailValue);
            // if (previousId) {
            //     $state.go("group.new", {
            //         id: previousId
            //     });
            // } else if (editDetailValue) {
            //     $state.go("group.new", {
            //         id: editDetailValue
            //     });
            // }
        }

        function navigateAssetType() {
            sessionStorage.setItem(
                "createAssetType",
                "createAssetToCreateAssetType"
            );
            $state.go("asset-type.new", {});
            // var pageValue = sessionStorage.getItem("prevId");
            // var editDetailValue = sessionStorage.getItem("prevEditId");
            // if (pageValue) {
            //     $state.go("asset-type.new", {
            //         id: pageValue
            //     });
            // } else if (editDetailValue) {
            //     $state.go("asset-type.new", {
            //         id: editDetailValue
            //     });
            // }
        }

        function createMarkerOfAllAssets(myLatLng, map, myLocName, index) {
            var marker = new google.maps.Marker({
                position: myLatLng,
                map: map,
                icon: {
                    scaledSize: new google.maps.Size(24, 24),
                    origin: new google.maps.Point(0, 0),
                    url: "content/images/yellow.png",
                    anchor: new google.maps.Point(16, 16),
                },
                //                label: { color: 'black', fontWeight: 'medium', fontSize: '12px', text: myLocName, backgroundcolor: "#ff9800" },
            });
        }

        function setGeofences() {
            var isUserAdmin = false;
            vm.userAdmin.authorities.forEach(function (val) {
                if (val == "ROLE_USER_ADMIN") {
                    isUserAdmin = true;
                }
                if (val == "ROLE_HOD") {
                    isUserAdmin = true;
                }
            });
            if (isUserAdmin) {
                // Geofence.query({
                //     page: 0,
                //     size: 500,
                // }, onSuccess, onError);

                // function onSuccess(data, headers) {
                //     vm.selectedGeofence = [];
                //     data.forEach(function (value) {
                //         if (value.zone) {
                //             value.type = "MASTER";
                //         } else {
                //             value.type = "MINI";
                //         }
                //         vm.selectedGeofence.push(value);
                //     });
                //     drawLatestGeofence(vm.selectedGeofence);
                // }

                // function onError(error) {
                //     // AlertService.error(error.data.message);
                // }

                GeofenceIDs.getAll(function (response) {
                    vm.selectedGeofence = [];
                    response.forEach(function (value) {
                        if (value.zone) {
                            value.type = "MASTER";
                        } else {
                            value.type = "MINI";
                        }
                        vm.selectedGeofence.push(value);
                    });
                    drawLatestGeofence(vm.selectedGeofence);
                });
            } else {
                drawLatestGeofence(vm.userGeofenceList);
            }
        }

        function drawLatestGeofence(response) {
            if (response) {
                initializeGeofenceMap(response, function (latlang) {
                    locations.latlng = latlang;
                    // function() {
                    $.each(response, function (index, value) {
                        try {
                            if (
                                value.type != "MASTER" &&
                                value.coordinates != undefined
                            ) {
                                var path = JSON.parse(value.coordinates);
                                // drawPolylineForGeoFence(path, locations.map, value.name);
                                var infowindow = new google.maps.InfoWindow({
                                    content:
                                        '<div class="infoWindowhead">' +
                                        "name" +
                                        '<div class="infoWindowContent"> <b>Description :</b> ' +
                                        "des" +
                                        "</div></div>",
                                });

                                infowindow.setPosition(latlang);
                                var tempGeofences = new google.maps.Polyline({
                                    path: path,
                                    geodesic: true,
                                    strokeColor: "#ff0000",
                                    strokeOpacity: 0.5,
                                    strokeWeight: 3,
                                });
                                google.maps.event.addListener(
                                    tempGeofences,
                                    "mouseout",
                                    function (event) {
                                        infowindow.close();
                                    }
                                );

                                google.maps.event.addListener(
                                    tempGeofences,
                                    "click",
                                    function (event) {
                                        if (openedInfoWindow != null) {
                                            openedInfoWindow.close();
                                        }
                                        infowindow.setContent(
                                            '<div class="infoWindowhead">' +
                                                "<b>Geofence Name : </b><br><br>" +
                                                value.name +
                                                "</br></div>"
                                        );

                                        infowindow.mousemove;
                                        infowindow.open(locations.map);
                                        infowindow.setPosition(event.latLng);
                                        openedInfoWindow = infowindow;
                                    }
                                );
                                tempGeofences.setMap(locations.map);
                            }
                        } catch (err) {
                            console.log(err);
                        }
                    });
                });
            }
        }

        function initializeGeofenceMap(latlangs, callback) {
            if (latlangs && latlangs.length > 0) {
                latlng = new google.maps.LatLng(
                    latlangs[0].pinLat,
                    latlangs[0].pinLong
                );
                callback(latlng);
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            latlng = new google.maps.LatLng(
                                position.coords.latitude,
                                position.coords.longitude
                            );
                            callback(latlng);
                        },
                        function () {
                            callback(
                                new google.maps.LatLng("5.431587", "101.128162")
                            );
                        }
                    );
                } else {
                    callback(new google.maps.LatLng("5.431587", "101.128162"));
                }
            }
        }

        function drawingManagerSpread() {
            vm.drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: google.maps.drawing.OverlayType.POLYLINE,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: ["polyline"],
                },
                polylineOptions: {
                    fillColor: "#ffff00",
                    fillOpacity: 1,
                    strokeWeight: 5,
                    clickable: true,

                    editable: true,
                    zIndex: 1,
                    geodesic: true,
                },
            });
        }

        function drawingManagerFixed() {
            vm.drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: google.maps.drawing.OverlayType.MARKER,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: ["marker"],
                },
                markerOptions: {
                    icon: {
                        size: new google.maps.Size(220, 220),
                        scaledSize: new google.maps.Size(32, 32),
                        origin: new google.maps.Point(0, 0),
                        // url : "data:image/png;base64," +
                        // vm.asset.assetType.image,
                        // url : vm.asset.assetType.imagePath,
                        anchor: new google.maps.Point(16, 16),
                    },
                },
            });
        }

        var recursiveOwnedByAsset = function (ownedBy) {
            var names = [];
            if (ownedBy.length > 0) {
                if (ownedBy[0].ownedBy != null) {
                    if (ownedBy[0].ownedBy.length > 0) {
                        var assetCoordinateValues2 = ownedBy[0].ownedBy;
                        names.push({
                            lat:
                                assetCoordinateValues2[0].assetCoordinates[0]
                                    .latitude,
                            lng:
                                assetCoordinateValues2[0].assetCoordinates[0]
                                    .longitude,
                        });
                        if (assetCoordinateValues2.length > 0) {
                            Array.prototype.push.apply(
                                names,
                                recursiveOwnedByAsset(assetCoordinateValues2)
                            );
                        }
                    }
                }
            }
            return names;
        };

        function drawPolylineForGeneratedLoc(latlngsToDraw, map, color) {
            var spreadAssetCoordinates = [];
            $.each(latlngsToDraw, function (index, value) {
                spreadAssetCoordinates.push({
                    lat: value.latitude,
                    lng: value.longitude,
                });
            });

            if (spreadAssetCoordinates.length > 0) {
                var midLatLng = {
                    lat:
                        spreadAssetCoordinates[
                            spreadAssetCoordinates.length - 1
                        ].lat,
                    lng:
                        spreadAssetCoordinates[
                            spreadAssetCoordinates.length - 1
                        ].lng,
                };
                var infowindow = new google.maps.InfoWindow({
                    content:
                        '<div class="infoWindowhead">' +
                        "name" +
                        '<div class="infoWindowContent"> <b>Description :</b> ' +
                        "des" +
                        "</div></div>",
                });
                infowindow.setPosition(midLatLng);

                var ColorValue = "#ff0000";
                if (color == "red") {
                    ColorValue = "#ff0000";
                } else if (color == "black") {
                    ColorValue = "#000000";
                } else if (color == "yellow") {
                    ColorValue = "#F4A500";
                } else {
                    ColorValue = "#208000";
                }
                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: ColorValue,
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    fillColor: ColorValue,
                    fillOpacity: 0.3,
                    editable: false,
                });

                polylinepath.setOptions({
                    strokeColor: ColorValue,
                });

                polylinepath.setMap(map);
            }
        }

        function drawingManagerValue(option, map) {
            var parent = {};
            // for (var i = 0; i < vm.multiassets.length; i++) {
            //     if (vm.multiassets[i].ticked == true) {
            //         parent = vm.multiassets[i];
            //     }
            // }
            if (vm.parentAssets && vm.parentAssets.length > 0) {
                for (var i = 0; i < vm.parentAssets.length; i++) {
                    if (vm.parentAssets[i].status == true) {
                        parent = vm.parentAssets[i];
                    }
                }
            }
            if (parent.id != null) {
                AssetByID.get(parent.id, function (response) {
                    parent = response;
                    var latlngs = [];
                    try {
                        var len = parent.ownedBy;
                        var assetCoordinateValuesParent =
                            parent.assetCoordinates;
                        latlngs.push({
                            lat:
                                assetCoordinateValuesParent[
                                    assetCoordinateValuesParent.length - 1
                                ].latitude,
                            lng:
                                assetCoordinateValuesParent[
                                    assetCoordinateValuesParent.length - 1
                                ].longitude,
                        });

                        if (len.length > 0) {
                            var assetCoordinateValues = len[0].assetCoordinates;
                            latlngs.push({
                                lat:
                                    assetCoordinateValues[
                                        assetCoordinateValues.length - 1
                                    ].latitude,
                                lng:
                                    assetCoordinateValues[
                                        assetCoordinateValues.length - 1
                                    ].longitude,
                            });

                            if (recursiveOwnedByAsset(len) != null) {
                                Array.prototype.push.apply(
                                    latlngs,
                                    recursiveOwnedByAsset(len)
                                );
                            }
                        }

                        for (var i = 0; i < latlngs.length; i++) {
                            createMarker(latlngs[i], map, i);
                        }

                        if (latlngs.length > 0) {
                            moveToLocation(latlngs[0].lat, latlngs[0].lng, map);
                            map.setZoom(16);
                        }
                    } catch (err) {
                        var event232 = err;
                    }
                });
            }

            if (option == "polylinecomplete") {
                vm.selectAssetCordinates = false;
                google.maps.event.addListener(
                    vm.drawingManager,
                    option,
                    function (event) {
                        google.maps.event.addListener(
                            event.getPath(),
                            "insert_at",
                            function () {
                                setGoefenceCoordinate(event);
                            }
                        );
                        google.maps.event.addListener(
                            event.getPath(),
                            "set_at",
                            function (path) {
                                setGoefenceCoordinate(event);
                            }
                        );
                        vm.drawingManager.setOptions({
                            drawingControl: false,
                        });
                        vm.drawingManager.setMap(null);
                        setGoefenceCoordinate(event);
                    }
                );
                var assetCoorVal = [];

                vm.parentLatLng.forEach(function (value) {
                    assetCoorVal.push(value);
                });
                vm.asset.assetCoordinates.forEach(function (assetlatlng) {
                    assetCoorVal.push(assetlatlng);
                });
                vm.asset.assetCoordinates = assetCoorVal;
                vm.drawingManager.setMap(map);
            } else if (option == "click") {
                vm.selectAssetCordinates = false;
                google.maps.event.addListener(map, "click", function (e) {
                    placeMarker(e.latLng, map);
                    vm.fixedArray = [];
                    var coordinates = {};
                    coordinates.latitude = e.latLng.lat();
                    coordinates.longitude = e.latLng.lng();
                    vm.fixedArray.push(coordinates);
                    vm.asset.assetCoordinates = vm.fixedArray;
                });
            }
        }

        function moveToLocation(lat, lng, map) {
            var center = new google.maps.LatLng(lat, lng);
            // using global variable:
            map.panTo(center);
        }

        function createMarker(myLatLng, map, index) {
            if (index == 0) {
                var marker = new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                    icon:
                        "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                    animation: google.maps.Animation.DROP,
                });
            } else {
                var marker = new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                    icon:
                        "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                });
            }
        }

        var marker;

        function placeMarker(location, map) {
            if (marker) {
                marker.setPosition(location);
            } else {
                marker = new google.maps.Marker({
                    position: location,
                    map: map,
                    icon: {
                        size: new google.maps.Size(220, 220),
                        scaledSize: new google.maps.Size(32, 32),
                        origin: new google.maps.Point(0, 0),
                        // url : "data:image/png;base64," +
                        // vm.asset.assetType.image,
                        // url : vm.asset.assetType.imagePath,
                        anchor: new google.maps.Point(16, 16),
                    },
                });
            }
        }
        $scope.mapEvents = {
            click: function () {},
        };

        function distanceCal(lat1, lon1, lat2, lon2) {
            var theta = lon1 - lon2;
            var dist =
                Math.sin(degrees_to_radians(lat1)) *
                    Math.sin(degrees_to_radians(lat2)) +
                Math.cos(degrees_to_radians(lat1)) *
                    Math.cos(degrees_to_radians(lat2)) *
                    Math.cos(degrees_to_radians(theta));
            dist = Math.acos(dist);
            dist = radians_to_degrees(dist);
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344;
            if (isNaN(dist)) {
                return 0.0;
            } else {
                return dist;
            }
        }

        function degrees_to_radians(degrees) {
            var pi = Math.PI;
            return degrees * (pi / 180);
        }

        function radians_to_degrees(radians) {
            var pi = Math.PI;
            return radians * (180 / pi);
        }

        function setGoefenceCoordinate(event) {
            vm.assetCoordinateValues = [];
            vm.asset.assetCoordinates = [];

            var radius = event.getPath();
            for (var i = 0; i < radius.getLength(); i++) {
                var coordinates = {};
                coordinates.latitude = radius.getAt(i).lat();
                coordinates.longitude = radius.getAt(i).lng();
                vm.asset.assetCoordinates.push(coordinates);
            }
        }

        function setValues() {
            vm.coOrdinates = [];
            for (var i = 0; i < vm.asset.assetCoordinates.length; i++) {
                vm.coOrdinates.push({
                    lat: vm.asset.assetCoordinates[i].latitude,
                    lng: vm.asset.assetCoordinates[i].longitude,
                });
                if (i == vm.asset.assetCoordinates.length - 1) {
                    vm.coOrdinate = JSON.stringify(vm.coOrdinates);
                }
            }
        }

        function drawMarker(map, response) {
            $.each(JSON.parse(response), function (index, value) {
                centerlatlng = {
                    lat: value.lat,
                    lng: value.lng,
                };

                //edit asset marker
                var startMarker = new google.maps.Marker({
                    map: map,
                    position: new google.maps.LatLng(value.lat, value.lng),

                    icon: {
                        size: new google.maps.Size(220, 220),
                        scaledSize: new google.maps.Size(32, 32),
                        origin: new google.maps.Point(0, 0),
                        // url : "data:image/png;base64," +
                        // vm.asset.assetType.image,
                        // url : vm.asset.assetType.imagePath,
                        anchor: new google.maps.Point(16, 16),
                    },
                });
            });
            map.setCenter(centerlatlng);
        }

        function filterAssetsOnMapView() {
            $("#mapFilterModal").show();
            noDataText = document.getElementById("noData");
            noDataText.style.display = "none";
            vm.prevSelectedGeofenceId = vm.selectedGeofenceId;
        }

        function closeMapFilterModal() {
            $("#mapFilterModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#mapFilterModal").hide();
            vm.selectedGeofenceId = vm.prevSelectedGeofenceId;
        }

        function saveFilter() {
            vm.mapAssets = [];

            if (locations.map == null) {
                /* var myOptions = {
                    zoom: 16,
                    center: locations.latlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                }; */

                var elem = document.getElementById("map_canvas");
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    /* Firefox */
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) {
                    /* Chrome, Safari & Opera */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) {
                    /* IE/Edge */
                    elem.msRequestFullscreen();
                }

                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas"),
                    myOption(16, locations.latlng)
                );
                geoLocation(locations.map);

                getGroupAssetsFromES(vm.selectedGeofenceId, null, vm.mapAssets);
                vm.prevSelectedGeofenceId = vm.selectedGeofenceId;

                if (vm.assetType.layout == "SPREAD") {
                    setGeofences();
                    drawingManagerSpread();
                    drawingManagerValue("polylinecomplete", locations.map);
                } else if (vm.assetType.layout == "FIXED") {
                    setGeofences();
                    drawingManagerFixed();
                    drawingManagerValue("click", locations.map);
                }

                $("#mapFilterModal").removeClass("in");
                $(".modal-backdrop").remove();
                $("#mapFilterModal").hide();
            } else {
                /* var myOptions = {
                    zoom: 16,
                    center: locations.latlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                }; */

                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas"),
                    myOption(16, locations.latlng)
                );
                geoLocation(locations.map);

                getGroupAssetsFromES(vm.selectedGeofenceId, null, vm.mapAssets);
                vm.prevSelectedGeofenceId = vm.selectedGeofenceId;

                if (vm.assetType.layout == "SPREAD") {
                    setGeofences();
                    drawingManagerSpread();
                    drawingManagerValue("polylinecomplete", locations.map);
                } else if (vm.assetType.layout == "FIXED") {
                    setGeofences();
                    drawingManagerFixed();
                    drawingManagerValue("click", locations.map);
                }

                $("#mapFilterModal").removeClass("in");
                $(".modal-backdrop").remove();
                $("#mapFilterModal").hide();
            }
        }

        function getGroupAssetsFromES(
            geofenceIdVal,
            lastAssetId,
            mapAssetLists
        ) {
            AssetGroupES.get(geofenceIdVal, lastAssetId, function (data) {
                vm.mapAssets = mapAssetLists;

                data.assets.forEach(function (asset) {

                    if(vm.asset.id != asset.id){
                        var tmpAsset = {};

                    tmpAsset.assetCoordinates = asset.assetCoordinates;
                    tmpAsset.assetTypeName = asset.assetTypeName;
                    tmpAsset.assetTypeColor = asset.assetColor;
                    tmpAsset.assetTypeImagePath = asset.assetTypeImagePath;
                    tmpAsset.assetTypeLayout = asset.assetTypeLayout;
                    tmpAsset.id = asset.id;
                    tmpAsset.name = asset.name;

                    vm.mapAssets.push(tmpAsset);
                    }
                    
                    
                });
                drawLatestAssets(vm.mapAssets);
                if (data.lastId != null) {
                    getGroupAssetsFromES(
                        geofenceIdVal,
                        data.lastId,
                        vm.mapAssets
                    );
                } else {
                    if (vm.mapAssets.length > 0) {
                        var assetSelected = vm.mapAssets[0];
                        AssetByID.get(assetSelected.id, function (assetVal) {
                            var assetLatLng = {
                                lat:
                                    assetSelected.assetCoordinates[
                                        assetSelected.assetCoordinates.length -
                                            1
                                    ].latitude,
                                lng:
                                    assetSelected.assetCoordinates[
                                        assetSelected.assetCoordinates.length -
                                            1
                                    ].longitude,
                            };

                            if(!vm.asset.id){
                                locations.map.setCenter(assetLatLng);
                            }
                            
                        });
                    }
                }
            });
        }

        function drawLatestAssets(response) {
            if (response) {
                initializeAssetMap(response, function (latlang) {
                    locations.latlng = latlang;

                    setupAssetMap(function () {
                        $.each(response, function (index, value) {
                            if (value.assetTypeLayout == null) {
                                value.assetTypeName = value.assetType.name;
                                value.assetTypeLayout = value.assetType.layout;
                                value.assetTypeImagePath =
                                    value.assetType.imagePath;
                            }
                            if (
                                value.assetTypeLayout != null &&
                                value.assetTypeLayout == "FIXED"
                            ) {
                                if (value.assetCoordinates.length > 1) {
                                    drawPolyline(value);
                                    var value1 = value;
                                    value1.assetCoordinates = [
                                        value.assetCoordinates[
                                            value.assetCoordinates.length - 1
                                        ],
                                    ];

                                    drawFixedAsset(value1, false);
                                } else {
                                    drawFixedAsset(value, false);
                                }
                            }
                            if (
                                value.assetTypeLayout != null &&
                                value.assetTypeLayout == "SPREAD"
                            ) {
                                drawPolyline(value, locations.map);
                            }
                        });
                    });
                });
            }
        }

        function initializeAssetMap(latlangs, callback) {
            if (latlangs && latlangs.length > 0) {
                var latVal = null;
                var lngVal = null;
                latlangs.forEach(function (value) {
                    if (latVal == null && lngVal == null) {
                        if (value.assetCoordinates.length > 0) {
                            latVal =
                                value.assetCoordinates[
                                    value.assetCoordinates.length - 1
                                ].latitude;
                            lngVal =
                                value.assetCoordinates[
                                    value.assetCoordinates.length - 1
                                ].longitude;
                        }
                    }
                });
                if (latVal != null && lngVal != null) {
                    latlng = new google.maps.LatLng(latVal, lngVal);
                    callback(latlng);
                }
            } else {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            latlng = new google.maps.LatLng(
                                position.coords.latitude,
                                position.coords.longitude
                            );
                            callback(latlng);
                        },
                        function () {
                            // callback(new google.maps.LatLng("12.9716", "77.5946"));
                            callback(
                                new google.maps.LatLng(
                                    $rootScope.DEFAULT_LATITUDE,
                                    $rootScope.DEFAULT_LONGITUDE
                                )
                            );
                        }
                    );
                } else {
                    callback(
                        new google.maps.LatLng(
                            $rootScope.DEFAULT_LATITUDE,
                            $rootScope.DEFAULT_LONGITUDE
                        )
                    );
                }
            }
        }

        function setupAssetMap(callback) {
            if (locations.map == null) {
                /* var myOptions = {
                    zoom: 16,
                    center: locations.latlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                }; */

                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas"),
                    myOption(16, locations.latlng)
                );
                geoLocation(locations.map);

                google.maps.event.addListener(
                    locations.map,
                    "zoom_changed",
                    function () {
                        vm.zoomLevel = locations.map.getZoom();
                        if (
                            (vm.prevZoomLevel == 17 && vm.zoomLevel == 18) ||
                            (vm.prevZoomLevel == 18 && vm.zoomLevel == 17)
                        ) {
                            drawLatestAssets(vm.mapAssets);
                        }
                        vm.prevZoomLevel = vm.zoomLevel;
                    }
                );
            } else {
                // Loop through all the markers and polylines to remove

                for (var i = 0; i < markers.length; i++) {
                    markers[i].setMap(null);
                }

                for (i = 0; i < polylines.length; i++) {
                    polylines[i].setMap(null);
                }

                markers = [];
                polylines = [];
            }
            locations.overlay = new google.maps.OverlayView();
            locations.overlay.draw = function () {};
            locations.overlay.setMap($scope.map);
            locations.element = document.getElementById("map_canvas");
            callback();
        }

        function drawPolyline(spread, map) {
            var spreadAssetCoordinates = [];
            $.each(spread.assetCoordinates, function (index, value) {
                spreadAssetCoordinates.push({
                    lat: value.latitude,
                    lng: value.longitude,
                });
            });
            if (spreadAssetCoordinates.length > 1) {
                var midLatLng = {
                    lat:
                        spread.assetCoordinates[
                            spread.assetCoordinates.length - 1
                        ].latitude,
                    lng:
                        spread.assetCoordinates[
                            spread.assetCoordinates.length - 1
                        ].longitude,
                };

                var polylineColor = "";
                var distanceFromPOP = "";
                var fiberCoreVal = "";
                var infowindow = null;
                if (spread.assetTypeLayout == "SPREAD") {
                    polylineColor = spread.assetTypeColor;
                }
                var polylineWeight = 2.5;
                var strokeOpacityVal = 1.0;

                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: strokeOpacityVal,
                    strokeWeight: polylineWeight,
                    fillColor: polylineColor,
                    fillOpacity: 0.3,
                    editable: false,
                });

                google.maps.event.addListener(
                    polylinepath,
                    "click",
                    function (event) {
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        var disVal = 0;
                        var cLocations = [];

                        if (
                            spread.description == null ||
                            spread.description == undefined
                        ) {
                            spread.description = "";
                        }

                        if (spread.assetTypeLayout == "SPREAD") {
                            AssetByID.get(spread.id, function (assetVal) {
                                spread = assetVal;
                                var infoStr = "";
                                var assetTypeAttributeValuesList =
                                    spread.assetTypeAttributeValues;
                                assetTypeAttributeValuesList.sort(function (
                                    a,
                                    b
                                ) {
                                    var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                        nameB = b.assetTypeAttribute.name.toLowerCase();
                                    if (nameA < nameB)
                                        // sort string ascending
                                        return -1;
                                    if (nameA > nameB) return 1;
                                    return 0; // default return value (no sorting)
                                });
                                for (
                                    var i = 0;
                                    i < assetTypeAttributeValuesList.length;
                                    i++
                                ) {
                                    var home = assetTypeAttributeValuesList[i];

                                    var attrVal = "";
                                    if (home.attributeValue != null) {
                                        attrVal = home.attributeValue;
                                    }
                                    infoStr +=
                                        "<br><b>" +
                                        home.assetTypeAttribute.name +
                                        "</b> : " +
                                        attrVal;
                                }
                                var spreadCoordinates = spread.assetCoordinates;
                                if (spreadCoordinates.length > 0) {
                                    for (var index in spreadCoordinates) {
                                        if (index > 0) {
                                            disVal += distanceCal(
                                                spreadCoordinates[index]
                                                    .latitude,
                                                spreadCoordinates[index]
                                                    .longitude,
                                                spreadCoordinates[index - 1]
                                                    .latitude,
                                                spreadCoordinates[index - 1]
                                                    .longitude
                                            );
                                        }
                                    }
                                }

                                var userContent = document.createElement("div"),
                                    button;

                                userContent.innerHTML =
                                    '<div class="infoWindowhead">' +
                                    "<b>Name : </b> " +
                                    spread.name +
                                    "</br>" +
                                    "<b>Distance : </b> " +
                                    parseFloat(disVal).toFixed(2) +
                                    " Km</br>";

                                infowindow = new google.maps.InfoWindow({
                                    content: userContent,
                                });

                                google.maps.event.addDomListener(
                                    button,
                                    "click",
                                    function () {
                                        ChildAssets.query(
                                            {
                                                parentId: spread.id,
                                            },
                                            onSuccess
                                        );

                                        function onSuccess(data, headers) {
                                            if (
                                                spread.assetType.name == "432FR"
                                            ) {
                                                $("#ribbonPathModal").show();
                                                try {
                                                    var assetCoordinateValues =
                                                        spread.assetCoordinates;
                                                    latlngs.push({
                                                        lat:
                                                            assetCoordinateValues[
                                                                assetCoordinateValues.length -
                                                                    1
                                                            ].latitude,
                                                        lng:
                                                            assetCoordinateValues[
                                                                assetCoordinateValues.length -
                                                                    1
                                                            ].longitude,
                                                    });
                                                } catch (err) {
                                                    var event232 = err;
                                                }
                                                showFiberDetails(
                                                    spread,
                                                    data,
                                                    spread.assetCoordinates[
                                                        spread.assetCoordinates
                                                            .length - 1
                                                    ].latitude,
                                                    spread.assetCoordinates[
                                                        spread.assetCoordinates
                                                            .length - 1
                                                    ].longitude,
                                                    latlngs
                                                );
                                            } else {
                                                $("#cablePathModal").show();
                                                try {
                                                    var assetCoordinateValues =
                                                        spread.assetCoordinates;
                                                    latlngs.push({
                                                        lat:
                                                            assetCoordinateValues[
                                                                assetCoordinateValues.length -
                                                                    1
                                                            ].latitude,
                                                        lng:
                                                            assetCoordinateValues[
                                                                assetCoordinateValues.length -
                                                                    1
                                                            ].longitude,
                                                    });
                                                } catch (err) {
                                                    var event232 = err;
                                                }
                                                showFiberDetails(
                                                    spread,
                                                    data,
                                                    spread.assetCoordinates[
                                                        spread.assetCoordinates
                                                            .length - 1
                                                    ].latitude,
                                                    spread.assetCoordinates[
                                                        spread.assetCoordinates
                                                            .length - 1
                                                    ].longitude,
                                                    latlngs
                                                );
                                            }
                                        }
                                    }
                                );
                            });
                        }
                        if (infowindow != null) {
                            infowindow.setPosition(event.latLng);
                            infowindow.open(locations.map);
                            openedInfoWindow = infowindow;
                        }
                    }
                );

                var polylinepathDuct = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: polylineColor,
                    fillOpacity: 0.3,
                    editable: false,
                });

                polylinepath.setMap(locations.map);

                polylines.push(polylinepath);
            }

            if(vm.asset.id && vm.asset.assetCoordinates.length){
                locations.map.setZoom(16)
                locations.map.setCenter({lat:vm.asset.assetCoordinates[0].latitude,lng:vm.asset.assetCoordinates[0].longitude})
            }
        }

        function drawFixedAsset(fixed, hasParent) {
            $.each(fixed.assetCoordinates, function (index, value1) {
                if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }

                var infoWindow = new google.maps.InfoWindow();

                var iconUrl = "";
                var isFixedAsset = false;
                iconUrl = fixed.assetTypeImagePath;
                isFixedAsset = true;

                var iconSize = 8;
                if (vm.zoomLevel == 10) {
                    iconSize = 8;
                } else if (vm.zoomLevel == 11) {
                    iconSize = 16;
                }

                if (isFixedAsset) {
                    iconSize = 24;
                }

                var startMarker = new google.maps.Marker({
                    map: locations.map,
                    position: new google.maps.LatLng(
                        value1.latitude,
                        value1.longitude
                    ),
                    title: fixed.name,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: iconUrl,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                    },
                });

                // if (vm.zoomLevel == 10) {
                //     startMarker.setLabel('');
                // } else if (vm.zoomLevel == 11) {
                //     var label = { color: '#000000', fontWeight: 'bold', fontSize: '12px', text: fixed.name };
                //     startMarker.setLabel(label);
                // }

                // if (fixed.status != null &&
                //     fixed.status === false) {
                //     startMarker.setLabel({
                //         color: 'red',
                //         fontSize: '30px',
                //         fontWeight: '20px',
                //         text: '\u2297'
                //     });
                // }
                // Add marker to the array.
                markers.push(startMarker);

                var infoWindow2 = new google.maps.InfoWindow();
                var infoStr = "";
                var otdr = "";

                var userContent = document.createElement("div"),
                    button;

                userContent.innerHTML =
                    '<div class="infoWindowhead">' +
                    startMarker.title +
                    '</div><div class="infoWindowContent">' +
                    "<b>Lat</b> :" +
                    fixed.assetCoordinates[fixed.assetCoordinates.length - 1]
                        .latitude +
                    "<br><b>Long</b> :" +
                    fixed.assetCoordinates[fixed.assetCoordinates.length - 1]
                        .longitude +
                    "<br>" +
                    infoStr +
                    "</div>";

                google.maps.event.addListener(
                    startMarker,
                    "dblclick",
                    function () {
                        infoWindow2.close();
                    }
                );

                var activeInfoWindow;
                var infoWindow2 = new google.maps.InfoWindow({
                    content: userContent,
                });
                // add listener on InfoWindow for click
                // event
                google.maps.event.addListener(
                    startMarker,
                    "click",
                    function () {
                        AssetByID.get(fixed.id, function (response) {
                            var infoWindow2 = new google.maps.InfoWindow();
                            var infoStr = "";
                            var otdr = "";
                            var assetTypeAttributeValuesList =
                                response.assetTypeAttributeValues;
                            assetTypeAttributeValuesList.sort(function (a, b) {
                                var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                    nameB = a.assetTypeAttribute.name.toLowerCase();
                                if (nameA < nameB)
                                    // sort string ascending
                                    return -1;
                                if (nameA > nameB) return 1;
                                return 0; // default return value (no sorting)
                            });
                            for (
                                var i = 0;
                                i < assetTypeAttributeValuesList.length;
                                i++
                            ) {
                                var home = assetTypeAttributeValuesList[i];
                                infoStr +=
                                    "<br><b>" +
                                    home.assetTypeAttribute.name +
                                    "</b> : " +
                                    home.attributeValue;
                                otdr = home;
                            }

                            var userContent = document.createElement("div"),
                                buttonA,
                                buttonB,
                                buttonC,
                                buttonD;
                            userContent.innerHTML =
                                '<div class="infoWindowhead">' +
                                startMarker.title +
                                '</div><div class="infoWindowContent">' +
                                "<b>Lat</b> :" +
                                fixed.assetCoordinates[
                                    fixed.assetCoordinates.length - 1
                                ].latitude +
                                "<br><b>Long</b> :" +
                                fixed.assetCoordinates[
                                    fixed.assetCoordinates.length - 1
                                ].longitude +
                                "<br>" +
                                infoStr +
                                "</div>";

                            var infoWindow2 = new google.maps.InfoWindow({
                                content: userContent,
                            });
                            if (prevInfoWindow != null) prevInfoWindow.close();
                            infoWindow2.open(locations.map, startMarker);
                            setTimeout(function () {
                                infoWindow2.close();
                            }, 5000);

                            activeInfoWindow = infoWindow2;
                            prevInfoWindow = infoWindow2;
                        });
                    }
                );
                openedInfoWindow = infoWindow2;
            });

            if(vm.asset.id && vm.asset.assetCoordinates.length){
                locations.map.setZoom(16)
                locations.map.setCenter({lat:vm.asset.assetCoordinates[0].latitude,lng:vm.asset.assetCoordinates[0].longitude})
            }
        }

    }
})();
