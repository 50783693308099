(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrSectionDetailController', TrSectionDetailController);

    TrSectionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TrSection', 'User', 'SectionType', 'TrSectionImages', 'TrSectionRecordings', 'AlertService', '$window', '$localStorage', 'getSectionImage', 'Configs', 'Inventory', 'SummaryPost', 'imageRoutePoint', 'TaskRouteById', 'TaskSectionById', 'TaskLogSectionReportData', 'Summary', 'MaterialByDates', 'MaterialDates', 'ParseLinks', 'DailyLogByTaskIdWithType'];

    function TrSectionDetailController($scope, $rootScope, $stateParams, previousState, entity, TrSection, User, SectionType, TrSectionImages, TrSectionRecordings, AlertService, $window, $localStorage, getSectionImage, Configs, Inventory, SummaryPost, imageRoutePoint, TaskRouteById, TaskSectionById, TaskLogSectionReportData, Summary, MaterialByDates, MaterialDates, ParseLinks, DailyLogByTaskIdWithType) {
        var vm = this;

        vm.trSection = entity;
        vm.previousState = previousState.name;
        vm.decodeImage = decodeImage;
        var unsubscribe = $rootScope.$on('trakeyeApp:trSectionUpdate', function (event, result) {
            vm.trSection = result;
        });
        vm.showSectionAttachment = false
        vm.sectionResource = []
        vm.sectionMaterial = []
        var labelFrom, labelTo
        labelFrom = 'From Section'
        labelTo = 'To Section'
        vm.dailyLogsTxSummary = []
        vm.mtrlTxSummary = []
        vm.resTxSummary = []
        var routePath = {};
        vm.showTaskLogReport = showTaskLogReport;
        var sectionPath = {};
        var infowindow = new google.maps.InfoWindow();
        var openedInfoWindow = null;
		vm.gotoLatitute = gotoLatitute;
		vm.gotoLatituteCity = gotoLatituteCity;
		vm.closeGotoLatitute = closeGotoLatitute;
		vm.closeGotoLatituteCity = closeGotoLatituteCity;
		var measureTool = document.createElement("button");
		var measureToolIcon = document.createElement("i");
		var measureEnabled = false
		var polyMeasureTool, segmentDistance, midpoint
		     var totalDistance = 0
		     var distanceMarkers = []
		     var totalDistanceMarkers = []
		     var pointerMarkers = []
		     var arrpolyMeasureTool = []
		
        Configs.get("SectionImage", function (response) {
            if (response.length > 0) {
                if (response[0].configValue == "True") {
                    vm.showSectionAttachment = true
                }
            }
        })

        vm.imageInNewTab = function (img) {
            var imgWindow = window.open();
            imgWindow.document.write(
                '<img width="25%"   src="data:image/jpeg;base64,' +
                img +
                '">'
            );
            imgWindow.document.close();
        };

        $scope.newTabDisplay = function (id) {
            var request = new XMLHttpRequest();
            request.open('GET', "api/trakeyesectionimages/" + vm.trSection.id + "/" + id, true);
            request.setRequestHeader('Authorization', 'Bearer ' + $localStorage.authenticationToken);
            request.responseType = 'blob';
            request.onload = response;

            function response(e) {
                if (this.status === 200) {
                    var urlCreator = window.URL || window.webkitURL;
                    var imageUrl = urlCreator.createObjectURL(this.response);
                    window.open(imageUrl, "_blank")
                }
            }
            request.send();
        }

        $scope.$on('$destroy', unsubscribe);

        $scope.go_back = function () {
            $window.history.back();
        };

        $scope.allimages = false;
        //		$scope

        $scope.toggle = function (id) {
            switch (id) {
                case "image":
                    $scope.allimages = !$scope.allimages;
                    vm.showImages = !vm.showImages;
                    if ($scope.allimages) {
                        TrSectionImages.query(
                            {
                                sectionId: vm.trSection.id,
                            },
                            onSuccess,
                            onError
                        );

                        function onSuccess(data, headers) {
                            // console.log('Recived data-----------');

                            vm.sectionImages = data.filter(function (sectionImages) {
                                return sectionImages.image
                            });

                        }

                        function onError(error) {
                            AlertService.error(error.data.message);
                        }
                    }
                    break;

                case "termination":
                    $scope.terminationDetails = !$scope.terminationDetails;
                    vm.showTermination = !vm.showTermination;
                    break;

                case "fms":
                    $scope.fmsDetails = !$scope.fmsDetails;
                    vm.showFMS = !vm.showFMS;
                    break;

                case "splicing":
                    $scope.splicingDetails = !$scope.splicingDetails;
                    vm.showSplicing = !vm.showSplicing;
                    break;

                case "cable":
                    $scope.cableDetails = !$scope.cableDetails;
                    vm.showCable = !vm.showCable;
                    break;

                case "pdf":
                    $scope.allpdf = !$scope.allpdf;
                    vm.showPdf = !vm.showPdf;
                    if ($scope.allpdf) {
                        getSectionImage.get(vm.trSection.id, function (res) {
                            vm.attachments = res;
                        });
                    }

                    break;

                case "resource":
                    vm.showResource = !vm.showResource;
                    break;

                case "material":
                    vm.showMaterial = !vm.showMaterial;
                    break;

                case "task":
                    vm.showTask = !vm.showTask;
                    break;

                default:
                    console.log("");
            }



			/*
			
			$scope.allimages = !$scope.allimages;
			
			var audioTag = document.createElement("AUDIO");
			audioTag.setAttribute("src","http://us-east-1.linodeobjects.com/trakeye-media-files/section_"+vm.trSection.id+".mp3");
			audioTag.setAttribute("controls", "controls");
			audioTag.setAttribute("controlsList", "nodownload");

			var mydiv = document.getElementById("audioLayout");
			
			if ($scope.allimages) {
				TrSectionImages.query({
					sectionId : vm.trSection.id

				}, onSuccess, onError);

				function onSuccess(data, headers) {
					// console.log('Recived data-----------');
					vm.sectionImages = data;
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}
				var mydivChild = document.createElement('div');
				mydivChild.setAttribute("id", "audioChild");
				mydiv.appendChild(mydivChild);
				mydivChild.appendChild(audioTag);
			}else{
				var mydivChild = document.getElementById("audioChild");
				mydivChild.remove();
			}
		*/};

        //		$scope.playAudio = function() {
        ////			var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/section_"+vm.trSection.id+".mp3";
        //            var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/section_15570572.mp3";
        //	        var audio = new Audio(urlStr);
        //	        audio.play();
        //	    };


        function decodeImage(img) {
            return window.atob(img);
        }


        /* //Get Tasks by Section Id
        getTask.bySectionId(entity.id,onSuccess,onError)

        function onSuccess(response){
            
        }

        function onError(error){
            AlertService.error(error.data.message);
        }  */


        Inventory.materialTxn(entity.id, function (response) {
            vm.sectionMaterial = response
        })

        Inventory.resourceTxn(entity.id, function (response) {
            vm.sectionResource = response
        })

        Inventory.taskBySection(entity.id, function (response) {
            vm.taskResponse = response
        })


        $scope.tab = 1;

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };


        $scope.setTab = function (newTab) {
            if (newTab == 2) {
                //Comment Box
                vm.objectComments = {}
                vm.objectComments.entityId = entity.id
                vm.objectComments.entityName = "SECTION"
            }
            if (newTab == 3) {
                showTaskLogReport(vm.trSection.id);
            }
            $scope.tab = newTab;

        }


        var locations = {};
        var markers = [];
        var coordinateList = []
        var routeCoordinateList = []
        var chainageList = []
        var markerList = []
        var iconSize = 20
        var center = {
            lat: parseFloat($rootScope.DEFAULT_LATITUDE),
            lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
        };

        locations.map = new google.maps.Map(
            document.getElementById("map_canvasStart"),
            myOption($rootScope.rolloutMapInitZoom, center)
        );
		geoLocation(locations.map);
        if (vm.trSection.route != null) {
            TaskRouteById.get(vm.trSection.route.id, function (routeVal) {
                routePath = routeVal;
                if (routePath.COORDINATE != null) {
                    routePath.COORDINATE.forEach(function (coor) {
                        routeCoordinateList.push({ lat: coor.latitude, lng: coor.longitude });
                    });
                }

                if (routeCoordinateList.length) {
                    locations.map.setCenter(routeCoordinateList[0]);
                    var strokeColor = "#0066CC";

                    strokeColor = "#8a0101";

                    var polyline = new google.maps.Polyline({
                        path: routeCoordinateList,
                        geodesic: true,
                        strokeColor: strokeColor,
                        strokeOpacity: 0.3,
                        strokeWeight: 6,
                        zIndex: 1
                    });

                    // Set the map to display the polyline
                    polyline.setMap(locations.map);
                }
            });
        }
        if (vm.trSection != null) {
            TaskSectionById.get(vm.trSection.id, function (sectionVal) {
                sectionPath = sectionVal;
                if (sectionPath.COORDINATE != null) {
                    sectionPath.COORDINATE.sort(function (a, b) { return a.routePointNumber - b.routePointNumber });

                    sectionPath.COORDINATE.forEach(function (coor) {
                        if (coor.routePointNumber) {
                            coordinateList.push({ lat: coor.latitude, lng: coor.longitude });
                        }
                    });
                }

                if (sectionPath.CHAINAGE != null) {
                    sectionPath.CHAINAGE.forEach(function (chainageVal) {
                        chainageList.push({ latitude: chainageVal.latitude, longitude: chainageVal.longitude, type: chainageVal.routePointType });
                    });
                }

                if (sectionPath.MARKER != null) {
                    sectionPath.MARKER.forEach(function (markerVal) {
                        markerList.push({ lat: markerVal.latitude, lng: markerVal.longitude });
                    });
                }

                if (coordinateList.length) {
                    locations.map.setCenter(coordinateList[0]);

                    var polyline = new google.maps.Polyline({
                        path: coordinateList,
                        geodesic: true,
                        strokeColor: '#FD7A24',
                        strokeOpacity: 1.0,
                        strokeWeight: 4,
                        zIndex: 2
                    });

                    // Set the map to display the polyline
                    polyline.setMap(locations.map);
                }

                /* if(markerList.length){
                    locations.map.setCenter(markerList[0]);
                    createMarkers(markerList,'content/images/markersPoint.png',locations.map)
                } */

                if (chainageList.length) {

                    //locations.map.setCenter(chainageList[0]);
                    createMarkers(chainageList, 'content/images/chainagePoints.png', locations.map)
                }
            });
        }


        function createMetaTag(attrName, attrValue, content) {
            var meta = document.createElement('meta');
            meta.setAttribute(attrName, attrValue);
            meta.setAttribute('content', content);
            return meta;
        }

        function createTable(classNames) {
            var table = document.createElement('table');
            table.setAttribute('border', '1');
            table.setAttribute('cellpadding', '5');
            table.setAttribute('cellspacing', '0');
            table.setAttribute('id', 'sheet0');
            table.setAttribute('class', classNames);
            return table;
        }

        function createTableCell(tagName, textContent) {
            var cell = document.createElement(tagName);
            cell.textContent = textContent;
            return cell;
        }

		function gotoLatitute() {
		         $("#modalLatLongs").show();
		         /* noDataText = document.getElementById("noData");
		         noDataText.style.display = "none"; */
		     }

		     function gotoLatituteCity() {
		         $("#modalLatLongs").show();
		         /* noDataText = document.getElementById("noData");
		         noDataText.style.display = "none"; */
		     }

		     function closeGotoLatitute(id) {
		         $("#" + id).removeClass("in");
		         $(".modal-backdrop").remove();
		         $("#" + id).hide();
		     }
		     function closeGotoLatituteCity() {
		         $("#modalLatLongs").removeClass("in");
		         $(".modal-backdrop").remove();
		         $("#modalLatLongs").hide();
		     }
			 
        function showTaskLogReport(sectionId) {
            vm.isReportGenerated = false;
            TaskLogSectionReportData.query(
                {
                    reportType: "daily",
                    sectionId: sectionId
                },
                onSuccess,
                onError
            );

            function onSuccess(data) {
                var htmlDoc = document.createElement('html');
                var head = document.createElement('head');
                var meta1 = createMetaTag('http-equiv', 'Content-Type', 'text/html; charset=utf-8');
                var meta2 = createMetaTag('name', 'generator', 'PhpSpreadsheet, https://github.com/PHPOffice/PhpSpreadsheet');
                var meta3 = createMetaTag('name', 'author', 'Apache POI');
                var meta4 = createMetaTag('name', 'company', 'Microsoft Corporation');
                head.appendChild(meta1);
                head.appendChild(meta2);
                head.appendChild(meta3);
                head.appendChild(meta4);
                var body = document.createElement('body');
                var table = createTable('sheet0 gridlines');
                var thead = document.createElement('thead');
                var tbody = document.createElement('tbody');

                function createTable(classNames) {
                    var table = document.createElement('table');
                    table.setAttribute('border', '1');
                    table.setAttribute('cellpadding', '5');
                    table.setAttribute('cellspacing', '0');
                    table.setAttribute('id', 'sheet0');
                    table.setAttribute('class', classNames);
                    return table;
                }

                function createMetaTag(attrName, attrValue, content) {
                    var meta = document.createElement('meta');
                    meta.setAttribute(attrName, attrValue);
                    meta.setAttribute('content', content);
                    return meta;
                }

                function createTableCell(tag, content) {
                    var cell = document.createElement(tag);
                    cell.textContent = content;
                    cell.style.fontSize = "13px";
                    return cell;
                }

                thead.className = 'thReport';
                table.appendChild(thead);
                tbody.className = 'tdReport';
                table.appendChild(tbody);
                var styleElement = document.createElement('style');
                styleElement.textContent = "\
                .subheader {\
                    margin: auto;\
                }\
                .thReport > tr > th{\
                    text-align: center;\
                    padding: 10px;\
                }\
                .tdReport > tr > td {\
                    text-align: center; \
                    padding: 10px;\
                }\
                ";
                document.head.appendChild(styleElement);

                var colors = ['#d6d5c9', '#ff9b85', '#ffcccc', '#ccccff', '#fae588', '#b0f2b4', '#abd3ff', '#d0b17a', '#eacdc2',];
                function getRandomColor() {
                    if (colors.length === 0) {
                        colors = ['#d6d5c9', '#ff9b85', '#ffcccc', '#ccccff', '#fae588', '#b0f2b4', '#abd3ff', '#d0b17a', '#eacdc2'];
                    }
                    var index = Math.floor(Math.random() * colors.length);
                    var color = colors[index];
                    colors.splice(index, 1);
                    return color;
                }


                var groupedData = {};
                data.forEach(function (item) {
                    if (!groupedData[item.date]) {
                        groupedData[item.date] = [];
                    }
                    groupedData[item.date].push(item);
                });
                var names = getUniqueNames(data);
                var nameHeaderRow = document.createElement('tr');
                var serialNumberHeader = createTableCell('th', 'S.No');
                serialNumberHeader.style.fontWeight = 'bold';
                serialNumberHeader.style.backgroundColor = '#7dcfb6';
                serialNumberHeader.rowSpan = 2;
                nameHeaderRow.appendChild(serialNumberHeader);
                var dateHeader = createTableCell('th', 'Date');
                dateHeader.style.backgroundColor = '#7dcfb6';
                dateHeader.style.fontWeight = 'bold';
                dateHeader.rowSpan = 2;
                nameHeaderRow.appendChild(dateHeader);
                var typeHeaderRow = document.createElement('tr');

                names.forEach(function (name, index) {
                    var nameData = data.filter(function (item) { return item.name === name; });
                    var availableTypes = getUniqueTypes(nameData);
                    var nameHeaderCell = createTableCell('th', name);
                    nameHeaderCell.style.backgroundColor = getRandomColor();
                    nameHeaderCell.style.fontWeight = 'bold';
                    nameHeaderCell.colSpan = availableTypes.length;
                    nameHeaderRow.appendChild(nameHeaderCell);
                    availableTypes.forEach(function (type) {
                        var typeHeaderCell = createTableCell('th', type);
                        typeHeaderCell.style.backgroundColor = nameHeaderCell.style.backgroundColor;
                        typeHeaderCell.style.fontWeight = 'bold';
                        typeHeaderRow.appendChild(typeHeaderCell);
                    });
                });
                var totalProgressHeader = createTableCell('th', 'Total Progress');
                totalProgressHeader.style.backgroundColor = '#cfe0c3';
                totalProgressHeader.style.fontWeight = 'bold';
                totalProgressHeader.colSpan = 3;
                nameHeaderRow.appendChild(totalProgressHeader);
                var subHeaders = [
                    {
                        type: 'Total',
                    },
                    {
                        type: "Ducting",
                    }, {
                        type: "Validation",

                    }
                ];
                subHeaders.forEach(function (subHeader) {
                    var subHeaderCell = createTableCell('th', subHeader.type);
                    subHeaderCell.style.backgroundColor = '#cfe0c3';
                    subHeaderCell.style.fontWeight = 'bold';
                    typeHeaderRow.appendChild(subHeaderCell);
                });
                thead.appendChild(nameHeaderRow);
                thead.appendChild(typeHeaderRow);
                var totals = {};

                Object.keys(groupedData)
                    .sort(function (a, b) {
                        var dateA = a.split('-').reverse().join('-');
                        var dateB = b.split('-').reverse().join('-');
                        return new Date(dateA) - new Date(dateB);
                    })
                    .forEach(function (date, index) {
                        var totalLength = 0;
                        var totalValidation = 0;
                        names.forEach(function (name) {
                            var nameData = data.filter(function (item) { return item.name === name && item.date === date; });
                            var ductingLength = 0;
                            var hddLength = 0;
                            var horizontalDirectionalDrilling = 0
                            var validationLength = 0;
                            nameData.forEach(function (item) {
                                if (item.type === 'Ducting') {
                                    ductingLength += item.length;
                                }
                                else if (item.type === 'HDD') {
                                    hddLength += item.length;

                                }
                                else if (item.type === 'HDD ( Horizontal Directional Drilling )') {
                                    horizontalDirectionalDrilling += item.length;
                                    console.log(horizontalDirectionalDrilling)
                                }
                                else if (item.type === 'Validation' || item.type === 'Validation (T&D)') {
                                    validationLength += item.length;
                                }
                            });
                            console.log(totalLength)
                            totalLength += (ductingLength + hddLength + horizontalDirectionalDrilling);
                            totalValidation += validationLength;

                        });
                        var tr = document.createElement('tr');
                        var serialNumberCell = createTableCell('td', index + 1);
                        serialNumberCell.style.fontSize = "13px";
                        tr.appendChild(serialNumberCell);
                        var dateCell = createTableCell('td', date);
                        dateCell.style.width = '77px'
                        dateCell.style.whiteSpace = 'nowrap';
                        dateCell.style.fontSize = "13px";
                        tr.appendChild(dateCell);
                        names.forEach(function (name) {
                            var nameData = data.filter(function (item) { return item.name === name; });
                            var availableTypes = getUniqueTypes(nameData);

                            availableTypes.forEach(function (type) {
                                var item = groupedData[date].find(function (item) {
                                    return item.name === name && item.type === type;
                                });
                                var lengthValue = item ? item.length : 0;
                                var td = createTableCell('td', lengthValue);

                                tr.appendChild(td);
                                if (!totals[type]) {
                                    totals[type] = 0;
                                }
                                totals[type] += lengthValue;
                            });
                        });
                        var total = 0;
                        for (var i = 2; i < tr.cells.length; i++) {
                            total += parseInt(tr.cells[i].textContent);
                        }
                        var columnValueCell = createTableCell('td', total);
                        columnValueCell.colSpan = 1;
                        tr.appendChild(columnValueCell);
                        var remainingDuctingCell = createTableCell('td', totalLength);
                        tr.appendChild(remainingDuctingCell);
                        var remainingValidationCell = createTableCell('td', totalValidation);
                        tr.appendChild(remainingValidationCell);
                        if (index === 0) {
                            totals['Total Progress Ducting'] = 0;
                            totals['Total Progress Validation'] = 0;
                        }
                        totals['Total Progress Ducting'] += totalLength;
                        totals['Total Progress Validation'] += totalValidation;
                        tbody.appendChild(tr);
                    });

                var staticColumns = ['Total', 'Balance Validation', 'Balance Ducting'];
                var allAvailableTypes = [];
                names.forEach(function (name) {
                    var nameData = data.filter(function (item) { return item.name === name; });
                    allAvailableTypes.push.apply(allAvailableTypes, getUniqueTypes(nameData));
                });
                var availableTypes = allAvailableTypes;
                var maxUniqueTypesCount = availableTypes.length;
                var totalLength = maxUniqueTypesCount + subHeaders.length;

                var columnTotals = {};
                for (var i = 2; i < totalLength; i++) {
                    var total = 0;
                    for (var j = 0; j < tbody.rows.length; j++) {
                        var cellValue = parseInt(tbody.rows[j].cells[i].textContent) || 0;
                        total += cellValue;
                    }
                    columnTotals[i] = total;
                }
                staticColumns.forEach(function (column) {
                    var staticRow = document.createElement('tr');
                    var columnNameCell = createTableCell('td', column);
                    columnNameCell.colSpan = 2;
                    columnNameCell.style.backgroundColor = '#cfe0c3';
                    columnNameCell.style.fontSize = "13px";
                    columnNameCell.style.fontWeight = 'bold';
                    staticRow.appendChild(columnNameCell);
                    if (column == 'Total') {
                        for (var i = 2; i < totalLength; i++) {
                            var columnValueCell = createTableCell('td', columnTotals[i].toString());
                            columnValueCell.colSpan = 1;
                            staticRow.appendChild(columnValueCell);
                        }
                        var columnValueCell = createTableCell('td', totals['Total Progress Ducting']);
                        columnValueCell.colSpan = 1;
                        staticRow.appendChild(columnValueCell);
                        var columnValueCell = createTableCell('td', totals['Total Progress Validation']);
                        columnValueCell.colSpan = 1;
                        staticRow.appendChild(columnValueCell);
                    } else {
                        for (var i = 0; i < totalLength; i++) {
                            var columnValueCell = createTableCell('td', '0');
                            columnValueCell.colSpan = 1;
                            staticRow.appendChild(columnValueCell);
                        }
                    }
                    tbody.appendChild(staticRow);
                });
                table.appendChild(thead);
                table.appendChild(tbody);
                body.appendChild(table);
                htmlDoc.appendChild(body);
                htmlDoc.appendChild(head);
                var htmlString = htmlDoc.outerHTML;
                var containerDiv = document.getElementById("generatedHtmlContainer");
                containerDiv.innerHTML = htmlString;

                function getUniqueNames(data) {
                    var uniqueNames = [];
                    data.forEach(function (item) {
                        if (uniqueNames.indexOf(item.name) === -1) {
                            uniqueNames.push(item.name);
                        }
                    });
                    return uniqueNames;
                }
                function getUniqueTypes(data) {
                    var uniqueTypes = [];
                    data.forEach(function (item) {
                        if (uniqueTypes.indexOf(item.type) === -1) {
                            uniqueTypes.push(item.type);
                        }
                    });
                    return uniqueTypes;
                }

                // Set background color for specified cells
                var jcbCells = document.querySelectorAll('.row1 .column0'); // Select cells in row 1, column 0 (JCB)
                var hddCells = document.querySelectorAll('.row1 .column1'); // Select cells in row 1, column 1 (HDD)

                // Loop through JCB cells and set background color to light green
                jcbCells.forEach(function (cell) {
                    cell.style.backgroundColor = 'lightgreen';
                });

                // Loop through HDD cells and set background color to light green
                hddCells.forEach(function (cell) {
                    cell.style.backgroundColor = 'lightgreen';
                });

                // Get the Total row
                // Select all relevant rows
                var relevantRows = ['.row2', '.row3', '.row5', '.row6'];

                // Loop through each relevant row
                relevantRows.forEach(function (rowClass) {
                    var cells = document.querySelectorAll(rowClass + ' td');

                    // Loop through the cells in the row
                    cells.forEach(function (cell) {
                        // Check if the content of the cell is '0' or empty
                        if (cell.textContent.trim() === '0' || cell.textContent.trim() === '') {
                            // Set background color to white
                            cell.style.backgroundColor = 'white';
                        }
                    });
                });

                vm.isReportGenerated = true;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function createMarkers(arr, img, map) {
            arr.forEach(function (each) {
                var marker = new google.maps.Marker({
                    position: { lat: each.latitude, lng: each.longitude },
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: img,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                });

                google.maps.event.addListener(
                    marker,
                    "click",
                    (function () {
                        //var name = (each.name == (undefined || null)) ? " " : each.name
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        infowindow.setContent(
                            '<div class="infoWindowhead"></br></br>' +
                            /* "<b>Name : "+name+"</b></br>"+ */
                            "<b>Latitude : " + each.latitude + "</b></br>" +
                            "<b>Longitude : " + each.longitude + "</b></br>" +
                            "<b>Type : " + each.type + "</b></br>" +
                            "</br></div>"
                        );
                        infowindow.open(map);
                        infowindow.setPosition({
                            lat: each.latitude,
                            lng: each.longitude,
                        });
                        openedInfoWindow = infowindow;
                    }))

                arr.push(marker);
            })
        }


        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
            {
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#EFEFEF",
                    },
                ],
            }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles(),
            });
        }

        function addMarker(location) {
            var markerText
            if (markers.length == 0) {
                markerText = labelFrom
            } else if (markers.length == 1) {
                markerText = labelTo
            }
            // Create a marker
            var marker = new google.maps.Marker({
                position: location,
                map: locations.map,
                icon: {
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                    url: "content/images/sections.png",
                    anchor: new google.maps.Point(
                        iconSize / 2,
                        -4
                    ),
                    labelOrigin: new google.maps.Point(25, 22),
                },
                label: {
                    text: markerText,
                }
            });

            // Add the marker to the markers array
            markers.push(marker);
        }

        /*  Summary.resTx(entity.id, function (response) {
             vm.resTxSummary = response
         })
 
         Summary.mtrlTx(entity.id, function (response) {
             vm.mtrlTxSummary = response
         })
 
         SummaryPost.dailyLogsTx({
             "projectIds": [vm.trSection.projectId],
             "routeIds": [vm.trSection.routeId],
             "sectionIds": [vm.trSection.id]
         }, function (response) {
             vm.dailyLogsTxSummary = response
         })
 
          */

        Summary.dailyLogsTx(entity.id, function (response) {
            vm.dailyLogsTxSummary = response
        })

        SummaryPost.mtrlTx({
            "projectIds": [vm.trSection.projectId],
            "routeIds": [vm.trSection.routeId],
            "sectionIds": [vm.trSection.id]
        }, function (response) {
            vm.mtrlTxSummary = response
        })

        SummaryPost.resTx({
            "projectIds": [vm.trSection.projectId],
            "routeIds": [vm.trSection.routeId],
            "sectionIds": [vm.trSection.id]
        }, function (response) {
            vm.resTxSummary = response
        })

        vm.objectRouteImg = {}
        vm.objectRouteImg.routeId = vm.trSection.routeId
        vm.objectRouteImg.map = locations.map

        vm.autoGenerateTask = function () {
            TaskSectionById.postAutoGenerateTasks(entity, function () {
                Inventory.taskBySection(entity.id, function (response) {
                    vm.taskResponse = response
                })
            })
        }

        if ((vm.trSection.fromRoutePoint != null && vm.trSection.toRoutePoint != null)) {
            locations.map.setCenter({ lat: vm.trSection.fromRoutePoint.latitude, lng: vm.trSection.fromRoutePoint.longitude })
            addMarker({ lat: vm.trSection.fromRoutePoint.latitude, lng: vm.trSection.fromRoutePoint.longitude })
            addMarker({ lat: vm.trSection.toRoutePoint.latitude, lng: vm.trSection.toRoutePoint.longitude })
        }

        vm.page = 1;
        vm.predicate = 'modified_date';
        vm.itemsPerPage = 10;
        vm.mtrpage = 1;
        vm.mtrPerPage = 10;
        vm.materialTxnByDates = [];
        vm.materialByDate = [];
        vm.loadMaterialTxnDate = function () {
            MaterialDates.query({
                type: "section",
                id: entity.id,
                page: vm.mtrpage - 1,
                size: vm.mtrPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }, function (data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.TotalItems = headers('X-Total-Count');
                vm.QueryCount = vm.TotalItems;
                vm.materialByDate = data.map(function (item) {
                    return {
                        timestamp: item[0],
                        total: item[1],
                        isExpand: false
                    };
                });

            });
        };

        vm.loadMaterialTxnDate();

        vm.loadMaterialTxnByDates = function (date) {
            MaterialByDates.query({
                type: 'section',
                id: entity.id,
                date: date,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }, function (data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.materialTxnByDates = data.map(function (item) {
                    return {
                        materialId: item[0] || "NA",
                        timestamp: item[4] || "NA",
                        materialName: item[1] || "NA",
                        transactionType: item[2] || "NA",
                        mtrlTxNumber: item[6] || "NA",
                        count: item[3] || "NA",
                        status: item[5] || "NA",
                    };
                });
            });
        };
        vm.materialTxnByDates = [];
        var today = new Date();
        today.setHours(23, 59, 59);
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.options = {
            maxDate: today
        };
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }



        function epoch(date) {
            return Date.parse(date)
        }
        vm.isModalOpen = false;
        vm.selectedTransaction = {};
        vm.openTransactionModal = function (transaction) {
            vm.selectedTransaction = angular.copy(transaction);
            vm.isModalOpen = true;
        };

        vm.saveTransaction = function () {
            var transactionData = {
                materialId: vm.selectedTransaction.materialId,
                unitOfMeasurement: vm.selectedTransaction.unitOfMeasurement,
                noOfUnits: vm.selectedTransaction.noOfUnits,
                transactionDate: epoch(vm.selectedTransaction.transactionDate),
                transactionType: vm.selectedTransaction.transactionType,
                mtrlTxNumber: vm.selectedTransaction.mtrlTxNumber,
                invoiceNumber: vm.selectedTransaction.invoiceNumber || null,
                remarks: vm.selectedTransaction.remarks || null,
                sectionId: vm.trSection.id
            };
            Inventory.postBulkMaterialTxn([transactionData], function () {
                $window.history.back();
            }, function (error) {
                console.error('Error saving transaction:', error)
            });
            vm.isModalOpen = false;
        };

        vm.cancelTransaction = function () {
            vm.selectedTransaction = {};
            vm.isModalOpen = false;
        };

        vm.transactionType = ["RECEIVED", "UTILIZED", "RECONCILED"]
        vm.toggleExpand = function (selectedTask) {
            angular.forEach(vm.materialByDate, function (task) {
                if (task === selectedTask) {
                    task.isExpand = !task.isExpand;
                    if (task.isExpand) {
                        vm.loadMaterialTxnByDates(task.timestamp);
                    } else {
                        vm.materialTxnByDates = [];
                    }
                } else {
                    task.isExpand = false;
                }
            });
        };
        vm.transitionMainMateril = function () {
            vm.loadMaterialTxnDate()
        }
        vm.getSectionPath = function () {
            vm.dailyLog_map = new google.maps.Map(
                document.getElementById("map_canvasStart1"),
                myOption(16, center)
            )
            if (vm.trSection != null) {
                TaskSectionById.get(vm.trSection.id, function (sectionVal) {
                    var sectionPathResponse = sectionVal;
                    var polylineCoordinates = []
                    var bounds = new google.maps.LatLngBounds();
                    sectionPathResponse.COORDINATE.forEach(function (coordinate) {
                        var latLng = {
                            lat: coordinate.latitude,
                            lng: coordinate.longitude
                        };
                        polylineCoordinates.push(latLng);
                        bounds.extend(latLng);
                        var polyline = new google.maps.Polyline({
                            path: polylineCoordinates,
                            geodesic: true,
                            strokeColor: '#FD7A24',
                            strokeOpacity: 0.5,
                            strokeWeight: 5
                        });
                        polyline.setMap(vm.dailyLog_map);
                    });
                    vm.dailyLog_map.fitBounds(bounds);
                })
            }
            vm.dailyLogPolyline = {}
            vm.taskPolyline = {}
            Inventory.taskBySection(entity.id, function (response) {
                vm.taskLogResponse = response;
                vm.taskLogResponse.forEach(function (task) {
                    Inventory.taskPathByID(task.id, function (response) {
                        if (response.COORDINATE !== undefined) {
                            var polylineCoordinates = [];
                            response.COORDINATE.forEach(function (coordinate) {
                                if (coordinate.routePointType === 'COORDINATE') {
                                    var latLng = {
                                        lat: coordinate.latitude,
                                        lng: coordinate.longitude
                                    };
                                    polylineCoordinates.push(latLng);
                                }
                            });
                            var polyline = new google.maps.Polyline({
                                path: polylineCoordinates,
                                geodesic: true,
                                strokeColor: '#ff9a8a',
                                strokeOpacity: 0.8,
                                strokeWeight: 4
                            });
                            polyline.setMap(vm.dailyLog_map);
                            vm.taskPolyline[task.id] = polyline
                        }
                    });
                });
            });
        }

        vm.getSectionPath()
        vm.expand = function (task) {
            vm.dailyLogs = [];
            vm.taskLogResponse.forEach(function (t) {
                if (t !== task) {
                    t.isExpanded = false;
                }
            });
            task.isExpanded = !task.isExpanded;
            if (task.isExpanded) {
                DailyLogByTaskIdWithType.query(
                    {
                        id: task.id,
                        type: 'PROGRESS'
                    },
                    function onSuccess(response) {
                        vm.dailyLogs = response;
                        Object.values(vm.dailyLogPolyline).forEach(function (polyline) {
                            polyline.setMap(null);
                        });
                        vm.dailyLogPolyline = {};
                        // var colors = ['#fe9ebc', '#8338ec', '#fe6259', '#fe2400', '#00658d', '#ff66d8', '#4cc9f0'];
                        vm.dailyLogs.forEach(function (coor, index) {
                            // var pathColor = colors[index % colors.length];
                            var fromLatLng = new google.maps.LatLng(coor.fromRoutePointLat, coor.fromRoutePointLng);
                            var toLatLng = new google.maps.LatLng(coor.toRoutePointLat, coor.toRoutePointLng);

                            var polyline = new google.maps.Polyline({
                                path: [fromLatLng, toLatLng],
                                geodesic: true,
                                strokeColor: '#06d6a0',
                                strokeOpacity: 1.0,
                                strokeWeight: 5
                            });
                            polyline.setMap(vm.dailyLog_map);
                            vm.dailyLogPolyline[coor.id] = polyline;
                        });
                    }
                );
            }
        };
        vm.startPoint = null;
        vm.endPoint = null;
        vm.highlightLog = function (id) {
            var polyline = vm.dailyLogPolyline[id];
            if (polyline) {
                polyline.setOptions({
                    strokeWeight: 16
                });
                var path = polyline.getPath().getArray();
                var fromLatLng = path[0];
                var toLatLng = path[path.length - 1];
                if (vm.startPoint) {
                    vm.startPoint.setMap(null);
                }
                if (vm.endPoint) {
                    vm.endPoint.setMap(null);
                }
                vm.startPoint = new google.maps.Marker({
                    position: fromLatLng,
                    map: vm.dailyLog_map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: "content/images/sections.png",
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            -4
                        ),
                        labelOrigin: new google.maps.Point(25, 22)
                    },
                    label: {
                        text: 'Start',
                        color: '#000000',
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                });
                vm.endPoint = new google.maps.Marker({
                    position: toLatLng,
                    map: vm.dailyLog_map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: "content/images/sections.png",
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            -4
                        ),
                        labelOrigin: new google.maps.Point(25, 22)
                    },
                    label: {
                        text: 'End',
                        color: '#000000',
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                });
            }
        };
        vm.resetLog = function () {
            Object.keys(vm.dailyLogPolyline).forEach(function (key) {
                var polyline = vm.dailyLogPolyline[key];
                if (polyline) {
                    polyline.setOptions({
                        strokeWeight: 5
                    });
                }
            });
            if (vm.startPoint) {
                vm.startPoint.setMap(null);
                vm.startPoint = null;
            }
            if (vm.endPoint) {
                vm.endPoint.setMap(null);
                vm.endPoint = null;
            }
        };
        vm.highlightTask = function (taskId) {
            for (var id in vm.taskPolyline) {
                if (id == taskId) {
                    vm.taskPolyline[id].setOptions({ strokeWeight: 12 });
                } else {
                    vm.taskPolyline[id].setOptions({ strokeWeight: 5 });
                }
            }
        }
        vm.resetTask = function () {
            for (var id in vm.taskPolyline) {
                if (vm.taskPolyline.hasOwnProperty(id)) {
                    vm.taskPolyline[id].setOptions({ strokeWeight: 5 });
                }
            }
        }
		 function addPointer(location, map) {
		        var pointerIcon = {
		            path: google.maps.SymbolPath.CIRCLE,
		            scale: 5,
		            strokeColor: 'black',
		            fillColor: 'white',
		            fillOpacity: 1,
		            strokeWeight: 2
		        };

		        var pointer = new google.maps.Marker({
		            position: location,
		            map: map,
		            icon: pointerIcon
		        });
		        pointerMarkers.push(pointer);
		    }
		    function formatDistance(distance) {
		        // Convert meters to kilometers
		        var km = distance / 1000;

		        // Show in meters if less than 1 km, otherwise show in kilometers
		        if (km < 1) {
		            return distance.toFixed(0) + ' m';
		        } else {
		            return km.toFixed(2) + ' km';
		        }
		    }

		    function clearMeasureTool(arr) {
		        for (var i = 0; i < arr.length; i++) {
		            arr[i].setMap(null)
		        }
		    }
		
		
		
		
		function addLatLng(event, map) {
		            var path = polyMeasureTool.getPath();
		            path.push(event.latLng);


		            addPointer(event.latLng, map);

		            var point1, point2
		            for (var i = 0; i < path.getLength() - 1; i++) {
		                point1 = path.getAt(i);
		                point2 = path.getAt(i + 1);
		                segmentDistance = google.maps.geometry.spherical.computeDistanceBetween(point1, point2);


		                // Add distance marker
		                midpoint = google.maps.geometry.spherical.interpolate(point1, point2, 0.5);

		            }


		            if (path.getLength() > 1) {
		                totalDistance += segmentDistance;
		                var marker = new google.maps.Marker({
		                    position: midpoint,
		                    map: map,
		                    icon: {
		                        url: 'content/images/iconTransparent.png',
		                    },
		                    label: {
		                        color: "black",
		                        fontWeight: "bold",
		                        fontSize: "14px",
		                        text: formatDistance(segmentDistance),
		                    },
		                });
		                distanceMarkers.push(marker);

		                var totalDistanceMarker = new google.maps.Marker({
		                    position: point2,
		                    map: map,
		                    icon: {
		                        url: 'content/images/iconTransparent.png',
		                    },
		                    label: {
		                        color: "black",
		                        fontWeight: "bold",
		                        fontSize: "14px",
		                        text: "Total : " + formatDistance(totalDistance),
		                    },
		                });
		                totalDistanceMarkers.push(totalDistanceMarker);
		            }

		        }
		
		function geoLocation(map) {
		           var infoWindow = new google.maps.InfoWindow();
		           var locationButton = document.createElement("button");
		           locationButton.setAttribute("title", "Locate Me");
		           locationButton.classList.add("custom-map-control-button");
		           var geoIcon = document.createElement("i");
		           geoIcon.classList.add("fa");
		           geoIcon.classList.add("fa-crosshairs");
		           geoIcon.classList.add("geo");
		           locationButton.appendChild(geoIcon);
		           map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
		               locationButton
		           );

		           var goTo = document.createElement("button");
		           goTo.setAttribute("title", "Location Coordinates");
		           goTo.classList.add("custom-map-control-button");
		           var gotoIcon = document.createElement("i");
		           gotoIcon.classList.add("fa");
		           gotoIcon.classList.add("fa-location-arrow");
		           gotoIcon.classList.add("geo");
		           goTo.appendChild(gotoIcon);
		           map.controls[google.maps.ControlPosition.RIGHT_TOP].push(goTo);

		           /* var zoomLabel = document.createElement("span");
		           zoomLabel.setAttribute("title", "Zoom");
		           zoomLabel.classList.add("custom-map-control-button");
		           var textnode = document.createTextNode("Zoom :"+vm.zoomLevel);
		           zoomLabel.appendChild(textnode);
		           map.controls[google.maps.ControlPosition.LEFT_TOP].push(
		               zoomLabel
		           );  */

		           goTo.addEventListener("click", function () {
		               gotoLatitute();
		           });


		           measureTool = document.createElement("button");
		           measureTool.setAttribute("title", "Measure Distance");
		           measureTool.classList.add("custom-map-control-button");
		           measureTool.id = "measureDistance";
		           measureToolIcon.classList.add("fa");
		           measureToolIcon.classList.add("fa-arrows-h");
		           measureToolIcon.classList.add("ruler");
		           measureTool.appendChild(measureToolIcon);
		           map.controls[google.maps.ControlPosition.RIGHT_TOP].push(measureTool);
		           measureTool.addEventListener("click", function () {

		               if (measureEnabled) {
		                   google.maps.event.removeListener(vm.clickListener);

		                   totalDistance = 0
		                   clearMeasureTool(distanceMarkers)
		                   clearMeasureTool(pointerMarkers)
		                   clearMeasureTool(arrpolyMeasureTool)
		                   clearMeasureTool(totalDistanceMarkers)
		                   distanceMarkers = []
		                   totalDistanceMarkers = []
		                   pointerMarkers = []
		                   arrpolyMeasureTool = []


		                   measureEnabled = false

		                   measureTool.setAttribute("title", "Measure Distance");
		                   measureToolIcon.classList.remove("fa-times");
		                   measureToolIcon.classList.add("fa-arrows-h");


		               } else {
		                   measureEnabled = true
		                   polyMeasureTool = new google.maps.Polyline({
		                       strokeColor: 'black',
		                       strokeOpacity: 1.0,
		                       strokeWeight: 3
		                   });
		                   polyMeasureTool.setMap(map);
		                   arrpolyMeasureTool.push(polyMeasureTool)

		                   vm.clickListener = map.addListener('click', function (event) {
		                       addLatLng(event, map)
		                   });


		                   measureTool.setAttribute("title", "Clear Distance");
		                   measureToolIcon.classList.remove("fa-arrows-h");
		                   measureToolIcon.classList.add("fa-times");
		               }
		           });

		           locationButton.addEventListener("click", function () {
		               // Try HTML5 geolocation.
		               if (navigator.geolocation) {
		                   navigator.geolocation.getCurrentPosition(
		                       function (position) {
		                           var pos = {
		                               lat: position.coords.latitude,
		                               lng: position.coords.longitude,
		                           };

		                           infoWindow.setPosition(pos);
		                           infoWindow.setContent("Location found.");
		                           infoWindow.open(map);
		                           // var image = {
		                           //     url: "content/images/geoLocation.png", // url
		                           //     scaledSize: new google.maps.Size(25, 25), // scaled size
		                           //     origin: new google.maps.Point(0, 0), // origin
		                           //     anchor: new google.maps.Point(0, 0), // anchor
		                           // };
		                           // var markerGeo = new google.maps.Marker({
		                           //     position: pos,
		                           //     icon: image,
		                           // });
		                           // map.setZoom(20);
		                           // markerGeo.setMap(map);
		                           map.setZoom(18);
		                           map.setCenter(pos);
		                       },
		                       function () {
		                           handleLocationError(
		                               true,
		                               infoWindow,
		                               map.getCenter()
		                           );
		                       },
		                       {
		                           enableHighAccuracy: true,
		                           timeout: 5000,
		                           maximumAge: 0,
		                       }
		                   );
		               } else {
		                   // Browser doesn't support Geolocation
		                   handleLocationError(false, infoWindow, map.getCenter());
		               }
		           });

		           function handleLocationError(
		               browserHasGeolocation,
		               infoWindow,
		               pos
		           ) {
		               infoWindow.setPosition(pos);
		               infoWindow.setContent(
		                   browserHasGeolocation
		                       ? "Error: The Geolocation service failed."
		                       : "Error: Your browser doesn't support geolocation."
		               );
		               infoWindow.open(map);
		           }
		       }
    }
})();
