(function () {
    "use strict";

    angular
        .module("trakeyeApp")
        .controller("TrRoutePlanController", TrRoutePlanController);

    TrRoutePlanController.$inject = [
        "$scope",
        "$rootScope",
        "$state",
        "$compile",
        "BaseUrl",
        "$localStorage",
        "TrRouteBundle",
        "ParseLinks",
        "AlertService",
        "pagingParams",
        "paginationConstants",
        "TrRouteSearch",
        "assetsLinking"
    ];

    function TrRoutePlanController(
        $scope,
        $rootScope,
        $state,
        $compile,
        BaseUrl,
        $localStorage,
        TrRouteBundle,
        ParseLinks,
        AlertService,
        pagingParams,
        paginationConstants,
        TrRouteSearch,
        assetsLinking
    ) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        $scope.search = pagingParams.search;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filterSearch = filterSearch;
        vm.downloadSurveyReport = downloadSurveyReport;
        $scope.tab = 1;

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };

        $scope.setTab = function (newTab) {
            $scope.tab = newTab;
            if ($scope.tab == 1) {
                filterSearch();
            }
        };

        if ($scope.tab == 1) {
            filterSearch();
        }

        function filterSearch() {
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ) {
                var searchVal = $scope.search.replace(/[^\w\s]/g, '_');
                TrRouteSearch.query(
                    {
                        search: searchVal,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                    },
                    onSuccess,
                    onError
                );
                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers("link"));
                    vm.totalItems = headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.routes = [];
                    data.forEach(function (value) {
                        if (value.zone) {
                            value.type = "MASTER";
                        } else {
                            value.type = "MINI";
                        }
                        vm.routes.push(value);
                    });
                    vm.page = pagingParams.page;
                }
    
                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }
        }

        function loadAll() {
            TrRouteBundle.query(
                {
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                },
                onSuccess,
                onError
            );

            function sort() {
                var result = [
                    vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                ];
                if (vm.predicate !== "id") {
                    //  result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers("link"));
                vm.totalItems = headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.routes = [];
                data.forEach(function (value) {
                    if (value.zone) {
                        value.type = "MASTER";
                    } else {
                        value.type = "MINI";
                    }
                    vm.routes.push(value);
                });
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function sort() {
            var result = [vm.predicate + "," + (vm.reverse ? "asc" : "desc")];
            if (vm.predicate !== "id") {
                // result.push('id');
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            var searchVal = "";
            if (
                $scope.search != null &&
                $scope.search != "" &&
                !angular.isUndefined($scope.search)
            ){
                searchVal = $scope.search.replace(/[^\w\s]/g, '_');
            }
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + "," + (vm.reverse ? "asc" : "desc"),
                search: searchVal,
            });
        }

        function downloadSurveyReport(routeId) {
			BaseUrl.getBaseUrl(function (baseurl) {
				var url = "";
				url = baseurl.stringValue + "api/route-survey-report-excel/"+routeId;
				if (vm.selectedSectionId > 0) {
					url = baseurl.stringValue + "api/route-survey-report-excel/"+routeId;
				}
				var request = new XMLHttpRequest();
				request.open('GET', url, true);
				request.setRequestHeader('Authorization', 'Bearer ' + $localStorage.authenticationToken);
				request.responseType = 'blob';

				request.onload = function (e) {
					if (this.status === 200) {
						var blob = this.response;
						if (blob.size > 0) {
							if (window.navigator.msSaveOrOpenBlob) {
								window.navigator.msSaveBlob(blob, 'test.xlsx');
							}
							else {
								var downloadLink = window.document.createElement('a');
								var contentTypeHeader = request.getResponseHeader("Content-Type");
								downloadLink.href = window.URL.createObjectURL(new Blob([blob], {
									type: contentTypeHeader
								}));
								downloadLink.download = 'SurveyReport.xlsx';

								document.body.appendChild(downloadLink);
								downloadLink.click();
								document.body.removeChild(downloadLink);
							}
						}
					}
				};
				request.send();
			});
		}

        vm.closeModal = function (id) {
            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
            clearInterval(vm.assetLinkInterval);
        };

        vm.showLinkAsset = function (id){
            vm.labelStatus = "OPEN"
            clearInterval(vm.assetLinkInterval);
            vm.updateProgress = 1
            vm.routeId = id
            callAssetLink()
            vm.assetLinkInterval = setInterval(callAssetLink, 10000);
            $("#linkasset").show();
        }

        vm.getProgressBarColor= function(){
            var attribute
            var borderColor;

            if(vm.labelStatus){
                
                switch (vm.labelStatus) {
                    case 'OPEN':
                      borderColor = '#3498db';
                      attribute = 'border-top'
                       // Red for open status
                      break;
                    case 'INPROGRESS':
                      borderColor = '#FFC300';
                      attribute = 'border-top' // Orange for progress status
                      break;
                    case 'COMPLETE':
                      borderColor = '#0BDA51';
                      attribute = 'border' // Green for complete status
                      break;
                    default:
                      borderColor = '#3498db'; // Default color
                      break;
                  }

                  if(vm.labelStatus == 'COMPLETE'){
                    return {
                        'border' : '16px solid ' + borderColor
                      };
                  }else{
                    return {
                        'border-top' : '16px solid ' + borderColor
                      };
                  }
                  
            }
        }

        function callAssetLink() {
            assetsLinking.linkAsset(function (responseLink) {

                /* vm.updateProgress += 1

                if (vm.updateProgress >= 98) {
                    vm.updateProgress = 90
                } */

                var temp = responseLink.find(function (item) {
                    return item.entityTypeId == vm.routeId
                })

                if(temp === undefined){
                    assetsLinking.postLinkAsset({
                        "entityName": "ROUTE",
                        "entityLayout": "SPREAD",
                        "entityTypeId": vm.routeId,
                        "reqByUserId": null,
                        "messageType": "ASSET_LINK",
                    }, function (responseLink) {
                        vm.updateProgress = 10
                        vm.isUpdateProgress = true
                        //vm.assetLinkInterval = setInterval(callAssetLink, 5000);
                    }, function (err) {
                        clearInterval(vm.assetLinkInterval);
                        //vm.closeModal('processing')
                    })
                }else if(temp){
                    vm.labelStatus = temp.messageStatus
                }

                

                if (temp && temp.messageStatus == "COMPLETE") {
                    //vm.updateProgress = 100

                    clearInterval(vm.assetLinkInterval);
                }


            }, function (err) {
                clearInterval(vm.assetLinkInterval);
            })
           
        }
       
    }
})();
