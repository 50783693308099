(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrRoutePlanDetailController', TrRoutePlanDetailController);

    TrRoutePlanDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TrRouteBundle', 'User', 'RouteType', 'TrRouteImages', 'TrRouteRecordings', 'AlertService', '$window', '$localStorage', 'getRouteImage', 'Configs', 'routeDetail', 'Inventory', 'SummaryPost', 'imageRoutePoint', 'TrRouteForMap', 'TaskLogRouteReportData', 'assetsByRouteBundleId', 'AllAssetsTypes', 'Report','AssetByID','$state'];

    function TrRoutePlanDetailController($scope, $rootScope, $stateParams, previousState, entity, TrRouteBundle, User, RouteType, TrRouteImages, TrRouteRecordings, AlertService, $window, $localStorage, getRouteImage, Configs, routeDetail, Inventory, SummaryPost, imageRoutePoint, TrRouteForMap, TaskLogRouteReportData, assetsByRouteBundleId, AllAssetsTypes, Report,AssetByID,$state) {
        var vm = this;

        vm.trRoute = entity;
        vm.previousState = previousState.name;
        vm.downloadRouteBundleKml = downloadRouteBundleKml;
        vm.decodeImage = decodeImage;
        vm.showTaskLogReport = showTaskLogReport;
        var unsubscribe = $rootScope.$on('trakeyeApp:trRouteUpdate', function (event, result) {
            vm.trRoute = result;
        });
        var latlng = null;
        vm.mapAssets = [];
        vm.showRouteAttachment = false
        vm.expandResponse = []
        var existingHighlight;
        var existingHighlightFixed;
        var activeInfo;
        var colors = [];
        vm.showAssetAttributesModal = showAssetAttributesModal;
        vm.closeAssetAttributesModal = closeAssetAttributesModal;
        vm.closeThreePointReference = closeThreePointReference;
        var infowindow = new google.maps.InfoWindow();
        var openedInfoWindow = null;
        var lastOpenedInfoWindow = null;
        var polylineTimer, spreadPoly;

        var uniqueColors = [
            "#FF0000", "#0000FF", "#008000", "#FFFF00", "#FFA500", "#800080", "#FFC0CB", "#00FFFF", "#FF00FF",
            "#008080", "#E6E6FA", "#A52A2A", "#800000", "#000080", "#808000", "#00FF00", "#FFDAB9", "#000000",
            "#808080", "#C0C0C0", "#FFD700", "#4B0082", "#EE82EE", "#40E0D0", "#D2B48C", "#FA8072", "#FF7F50",
            "#F0E68C", "#DC143C", "#7CFC00"
        ];
        var phasePolylines = []
        var phaseMarkers = []
        var coordinateListMaintenance = []
        var coordinateListRollout = []
        var coordinateListPlanSurvey = []
        var coordinateListPlanning = []
        var assetSpreadListPlanning = []
        var assetSpreadListMaintenance = []
        var assetSpreadListRollout = []
        var assetSpreadListPlanSurvey = []

        var colors = uniqueColors.map(function (color) {
            return { color: color, used: false };
        });

        AllAssetsTypes.getAll(function (response) {
            vm.assetTypeValues = response.length ? response : null
        });

        Configs.get("RouteImage", function (response) {
            if (response.length > 0) {
                if (response[0].configValue == "True") {
                    vm.showRouteAttachment = true
                }
            }
        })

        vm.imageInNewTab = function (img) {
            var imgWindow = window.open();
            imgWindow.document.write(
                '<img width="25%"   src="data:image/jpeg;base64,' +
                img +
                '">'
            );
            imgWindow.document.close();
        };


        /* $scope.newTabDisplay = function(id,imgid){
            var request = new XMLHttpRequest()
                
                request.open(
                        "GET",
                        'api/routepoint-images/'+id+'/'+imgid,
                        true
                );
                
                request.setRequestHeader(
                    "Authorization",
                    "Bearer " + $localStorage.authenticationToken
                );
                request.responseType = "blob";
                request.onload = response;
    
                function response(e) {
                    if (this.status === 200) {
                        var urlCreator = window.URL || window.webkitURL;
                        var imageUrl = urlCreator.createObjectURL(this.response);
                        window.open(imageUrl, "_blank");
                    }
                }
                request.send();
        }  */

        $scope.$on('$destroy', unsubscribe);

        $scope.go_back = function () {
            $window.history.back();
        };

        $scope.allimages = false;
        //		$scope

        $scope.toggle = function (id) {
            switch (id) {
                case "image":
                    $scope.allimages = !$scope.allimages;
                    vm.showImages = !vm.showImages;
                    if ($scope.allimages) {
                        TrRouteImages.query(
                            {
                                routeId: vm.trRoute.id,
                            },
                            onSuccess,
                            onError
                        );

                        function onSuccess(data, headers) {
                            // console.log('Recived data-----------');

                            vm.routeImages = data.filter(function (routeImages) {
                                return routeImages.image
                            });

                        }

                        function onError(error) {
                            AlertService.error(error.data.message);
                        }
                    }
                    break;

                case "termination":
                    $scope.terminationDetails = !$scope.terminationDetails;
                    vm.showTermination = !vm.showTermination;
                    break;

                case "fms":
                    $scope.fmsDetails = !$scope.fmsDetails;
                    vm.showFMS = !vm.showFMS;
                    break;

                case "splicing":
                    $scope.splicingDetails = !$scope.splicingDetails;
                    vm.showSplicing = !vm.showSplicing;
                    break;

                case "cable":
                    $scope.cableDetails = !$scope.cableDetails;
                    vm.showCable = !vm.showCable;
                    break;

                case "pdf":
                    $scope.allpdf = !$scope.allpdf;
                    vm.showPdf = !vm.showPdf;
                    if ($scope.allpdf) {
                        getRouteImage.get(vm.trRoute.id, function (res) {
                            vm.attachments = res;
                        });
                    }

                    break;

                default:
                    console.log("");
            }



			/*
			
			$scope.allimages = !$scope.allimages;
			
			var audioTag = document.createElement("AUDIO");
			audioTag.setAttribute("src","http://us-east-1.linodeobjects.com/trakeye-media-files/route_"+vm.trRoute.id+".mp3");
			audioTag.setAttribute("controls", "controls");
			audioTag.setAttribute("controlsList", "nodownload");

			var mydiv = document.getElementById("audioLayout");
			
			if ($scope.allimages) {
				TrRouteImages.query({
					routeId : vm.trRoute.id

				}, onSuccess, onError);

				function onSuccess(data, headers) {
					// console.log('Recived data-----------');
					vm.routeImages = data;
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}
				var mydivChild = document.createElement('div');
				mydivChild.setAttribute("id", "audioChild");
				mydiv.appendChild(mydivChild);
				mydivChild.appendChild(audioTag);
			}else{
				var mydivChild = document.getElementById("audioChild");
				mydivChild.remove();
			}
		*/};

        //		$scope.playAudio = function() {
        ////			var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/route_"+vm.trRoute.id+".mp3";
        //            var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/route_15570572.mp3";
        //	        var audio = new Audio(urlStr);
        //	        audio.play();
        //	    };


        function decodeImage(img) {
            return window.atob(img);
        }

        $scope.tab = 1;

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };


        $scope.setTab = function (newTab) {

            if (newTab == 8) {
                //Comment Box
                vm.objectComments = {}
                vm.objectComments.entityId = entity.id
                vm.objectComments.entityName = "ROUTE"
            }

            if (newTab == 3) {
                if (vm.dailyLogsTxSummary.routeTasks.length) {
                    $scope.tab = 4;
                    showTaskLogReport(vm.trRoute.id);
                } else if (vm.dailyLogsTxSummary.sectionTasks.length) {
                    $scope.tab = 7;
                    showTaskLogReport(vm.trRoute.id);
                } else {
                    $scope.tab = 11;
                }
            }

            $scope.tab = newTab;

        }


        /*  routeDetail.getSection(entity.id,function(response){
             vm.sectionResponse = response
         })
          */
        routeDetail.getTask(entity.id, function (response) {
            vm.taskResponse = response
        })

        routeDetail.getRoutesDetails(entity.id, function (response) {
            vm.sectionResponse = response
        })

        var locations = {};
        var markers = [];
        var coordinateList = []
        var chainageList = []
        var markerList = []
        var iconSize = 24
        var center = {
            lat: parseFloat($rootScope.DEFAULT_LATITUDE),
            lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
        };

        locations.map = new google.maps.Map(
            document.getElementById("map_canvasStart"),
            myOption(15, center)
        );

        function templateBg(color) {
            return '<img class="square" style="background-color:' +
                color +
                ';">'
        }

        vm.phaseFilter = []
        vm.blockFilter = []
        var tempColor = {
            "PLANNING": "#FD7A24",
            "PROPOSED": "#0096FF",
            "ROLLOUT": "#7FFF00",
            "MAINTENANCE": "#DE3163"
        }

        var isObjBool = {
            "PLANNING": false,
            "PROPOSED": false,
            "ROLLOUT": false,
            "MAINTENANCE": false
        }


        if (vm.trRoute && vm.trRoute.routePoints != null && vm.trRoute.routePoints.length) {


            vm.trRoute.routePoints.forEach(function (each) {

                if (each.routePointType == "COORDINATE" && each.phase != null) {

                    callSwitchPhaseCoordinate(each, each.phase)
                    isObjBool[each.phase] = true

                }/* else if(each.routePointType == "MARKER"){
                   markerList.push({lat:each.latitude,lng:each.longitude})
               } */else if (each.routePointType == "CHAINAGE" && each.phase != null) {
                    isObjBool[each.phase] = true
                    chainageList.push({ latitude: each.latitude, longitude: each.longitude, type: each.routePointType, number: each.routePointNumber, phase: each.phase })
                }
            })

            callPhaseCoordinate()

            /* if(markerList.length){
                locations.map.setCenter(markerList[0]);
                createMarkers(markerList,'content/images/markersPoint.png',locations.map,null)
            }  */

            if (chainageList.length) {

                locations.map.setCenter(chainageList[0]);
                createMarkers(chainageList, 'content/images/chainagePoints.png', locations.map, null)

            }

            callObjPhases()

        }

        function callObjPhases() {
            if (isObjBool["PLANNING"]) {
                vm.phaseFilter.push({ name: "PLANNING", selected: true, strokeColor: templateBg("#FD7A24") })
            }

            if (isObjBool["PROPOSED"]) {
                vm.phaseFilter.push({ name: "PROPOSED", selected: true, strokeColor: templateBg("#0096FF") })

            }

            if (isObjBool["ROLLOUT"]) {
                vm.phaseFilter.push({ name: "ROLLOUT", selected: true, strokeColor: templateBg("#7FFF00"), color: "#7FFF00" })

            }

            if (isObjBool["MAINTENANCE"]) {
                vm.phaseFilter.push({ name: "MAINTENANCE", selected: true, strokeColor: templateBg("#DE3163"), color: "#DE3163" })

            }
        }

        function callPhaseCoordinate() {
            if (coordinateListPlanning.length) {

                locations.map.setCenter(coordinateListPlanning[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateListPlanning,
                    geodesic: true,
                    strokeColor: tempColor["PLANNING"],
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                    name: "PLANNING"
                });

                coordinateListPlanning.forEach(function (each) {
                    createInfoWindow(polyline, each)
                })

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }

            if (coordinateListPlanSurvey.length) {
                //vm.phaseFilter.push({name:"PROPOSED",selected:true,strokeColor:templateBg("#0096FF")})

                locations.map.setCenter(coordinateListPlanSurvey[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateListPlanSurvey,
                    geodesic: true,
                    strokeColor: tempColor["PROPOSED"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "PROPOSED"
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }

            if (coordinateListRollout.length) {
                //vm.phaseFilter.push({name:"ROLLOUT",selected:true,strokeColor:templateBg("#7FFF00"), color:"#7FFF00"})

                locations.map.setCenter(coordinateListRollout[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateListRollout,
                    geodesic: true,
                    strokeColor: tempColor["ROLLOUT"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "ROLLOUT"
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }

            if (coordinateListMaintenance.length) {
                //vm.phaseFilter.push({name:"MAINTENANCE",selected:true,strokeColor:templateBg("#DE3163"), color :"#DE3163"})

                locations.map.setCenter(coordinateListMaintenance[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateListMaintenance,
                    geodesic: true,
                    strokeColor: tempColor["MAINTENANCE"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "MAINTENANCE"
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }
        }

        function callPhaseSpread() {
            if (assetSpreadListPlanning.length) {
                //vm.phaseFilter.push({name:"PLANNING",selected:true,strokeColor:templateBg("#FD7A24")})
                locations.map.setCenter(assetSpreadListPlanning[0]);

                var polyline = new google.maps.Polyline({
                    path: assetSpreadListPlanning,
                    geodesic: true,
                    strokeColor: tempColor["PLANNING"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "PLANNING"
                });

                // Set the map to display the polyline
                assetSpreadListPlanning.forEach(function (each) {
                    createInfoWindow(polyline, each)
                    polyline.set('block', each.block);
                })
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }


            if (assetSpreadListPlanSurvey.length) {
                //vm.phaseFilter.push({name:"PROPOSED",selected:true,strokeColor:templateBg("#0096FF")})

                locations.map.setCenter(assetSpreadListPlanSurvey[0]);
                var polyline = new google.maps.Polyline({
                    path: assetSpreadListPlanSurvey,
                    geodesic: true,
                    strokeColor: tempColor["PROPOSED"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "PROPOSED"
                });

                assetSpreadListPlanSurvey.forEach(function (each) {
                    createInfoWindow(polyline, each)
                    polyline.set('block', each.block);
                })
                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }

            if (assetSpreadListRollout.length) {
                //vm.phaseFilter.push({name:"ROLLOUT",selected:true,strokeColor:templateBg("#7FFF00"), color:"#7FFF00"})

                locations.map.setCenter(assetSpreadListRollout[0]);
                var polyline = new google.maps.Polyline({
                    path: assetSpreadListRollout,
                    geodesic: true,
                    strokeColor: tempColor["ROLLOUT"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "ROLLOUT"
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }

            if (assetSpreadListMaintenance.length) {
                //vm.phaseFilter.push({name:"MAINTENANCE",selected:true,strokeColor:templateBg("#DE3163"), color :"#DE3163"})

                locations.map.setCenter(assetSpreadListMaintenance[0]);
                var polyline = new google.maps.Polyline({
                    path: assetSpreadListMaintenance,
                    geodesic: true,
                    strokeColor: tempColor["MAINTENANCE"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "MAINTENANCE"
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }
        }

        function callSwitchPhaseCoordinate(each, ph) {
            switch (ph) {
                case "PLANNING":
                    coordinateListPlanning.push({ lat: each.latitude, lng: each.longitude, phase: each.phase })
                    break;

                case "PROPOSED":
                    coordinateListPlanSurvey.push({ lat: each.latitude, lng: each.longitude, phase: each.phase })
                    break;

                case "ROLLOUT":
                    coordinateListRollout.push({ lat: each.latitude, lng: each.longitude, phase: each.phase })
                    break;

                case "MAINTENANCE":
                    coordinateListMaintenance.push({ lat: each.latitude, lng: each.longitude, phase: each.phase })
                    break;
            }
        }

        function callSwitchPhaseSpread(each, ph, name, block) {
            switch (ph) {
                case "PLANNING":
                    assetSpreadListPlanning.push({ lat: each.latitude, lng: each.longitude, phase: each.phase, name: name, block: block })
                    break;

                case "PROPOSED":
                    assetSpreadListPlanSurvey.push({ lat: each.latitude, lng: each.longitude, phase: each.phase, name: name, block: block })
                    break;

                case "ROLLOUT":
                    assetSpreadListRollout.push({ lat: each.latitude, lng: each.longitude, phase: each.phase, block: block })
                    break;

                case "MAINTENANCE":
                    assetSpreadListMaintenance.push({ lat: each.latitude, lng: each.longitude, phase: each.phase, block: block })
                    break;
            }
        }

        $scope.togglePhase = function () {
            if (vm.selectedPhase.length) {
                clearPhase()
                vm.selectedPhase.forEach(function (each) {
                    for (var i = 0; i < phasePolylines.length; i++) {
                        if (phasePolylines[i].name === each.name) {
                            if (each.selected) {
                                phasePolylines[i].setMap(locations.map);
                            }
                        }
                    }
                    for (var i = 0; i < phaseMarkers.length; i++) {
                        if (phaseMarkers[i].name === each.name) {
                            if (each.selected) {
                                phaseMarkers[i].setMap(locations.map);
                            }
                        }
                    }
                })
            } else {
                clearPhase()
            }
        };

        $scope.toggleBlock = function () {
            if (vm.selectedBlock.length) {
                clearPhase()
                vm.selectedBlock.forEach(function (each) {
                    for (var i = 0; i < phasePolylines.length; i++) {
                        if (phasePolylines[i].block === each.name /* || phasePolylines[i].block == null */) {
                            if (each.selected) {
                                phasePolylines[i].setMap(locations.map);
                            }
                        }
                    }
                    for (var i = 0; i < phaseMarkers.length; i++) {
                        if (phaseMarkers[i].block === each.name /* || phasePolylines[i].block == null */) {
                            if (each.selected) {
                                phaseMarkers[i].setMap(locations.map);
                            }
                        }
                    }
                })
            } else {
                clearPhase()
                vm.phaseFilter.forEach(function (item) {
                    item.selected = false
                })
            }
        };

        function createMarkers(arr, img, map, str) {
            var marker, markerSymbol
            arr.forEach(function (each) {
                marker = new google.maps.Marker({
                    position: { lat: each.latitude, lng: each.longitude },
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: img,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                    name: each.phase
                });


                google.maps.event.addListener(
                    marker,
                    "click",
                    (function () {
                        //var name = (each.name == (undefined || null)) ? " " : each.name
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }


                        infowindow.setContent(
                            '<div class="infoWindowhead"></br></br>' +
                            /* "<b>Name : "+name+"</b></br>"+ */
                            "<b>Latitude : " + each.latitude + "</b></br>" +
                            "<b>Longitude : " + each.longitude + "</b></br>" +
                            "<b>Type : " + each.type + "</b></br>" +
                            "<b>Number : " + each.number + "</b></br>" +
                            "</br></div>"
                        );


                        infowindow.open(map);
                        infowindow.setPosition({
                            lat: each.latitude,
                            lng: each.longitude,
                        });
                        openedInfoWindow = infowindow;
                    }))



                /*   markerSymbol = new google.maps.Marker({
                     icon:  {
                         path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                         strokeColor: tempColor[each.phase],
                         fillColor: tempColor[each.phase],
                         fillOpacity:1,
                         scale: 1,
                         labelOrigin: new google.maps.Point(-1, 7)
                     },
                     map: locations.map,
                     position:  { lat: each.latitude, lng: each.longitude },
                     name:each.phase
                 });  */
                phaseMarkers.push(marker);
                //phaseMarkers.push(markerSymbol);


            })
        }

        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
            {
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#EFEFEF",
                    },
                ],
            }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles(),
            });
        }

        /* vm.expand = function(section,str) {
            vm.sectionResponse.forEach(function (item) {
                item.material = false;
                item.tasks = false;
                item.resource = false;
            });
            
            if (vm.toggle == section.id) {
                vm.toggle = undefined;
            } else {
                switch(str){
                    case "material":
                        vm.expandResponse = null
                        vm.headers = null
                        Inventory.materialTxn(section.id,function(response){
                            vm.headers = [ 
                            { key: "materialName", display: "Material Name" },
                            { key: "noOfUnits", display: "No of Units" },
                            { key: "transactionType", display: "Transaction Type" },
                            { key: "transactionDate", display: "Transaction Date" },
                        ]
                            vm.expandResponse = response
                        }) 
                        section.material = true;
                        vm.toggle = section.id;
                        break;
        
                    case "resource":
                        vm.expandResponse = null
                        vm.headers = null
                        Inventory.resourceTxn(section.id,function(response){
                            vm.headers = [ 
                                { key: "resourceName", display: "Resource Name" },
                                { key: "noOfResources", display: "No of Resource" },
                                { key: "transactionType", display: "Transaction Type" },
                                { key: "transactionDate", display: "Transaction Date" },
                            ]
                            vm.expandResponse = response
                        })
                        section.resource = true;
                        vm.toggle = section.id;
                        break;
        
                    case "tasks":
                        vm.expandResponse = null
                        vm.headers = null
                        Inventory.taskBySection(section.id,function(response){
                            vm.headers = [ 
                                { key: "name", display: "Name" },
                                { key: "taskType", display: "Task Type" }
                            ]
                            vm.expandResponse = response
                        })
                        section.tasks = true;
                        vm.toggle = section.id;
                         break; 
                    
                    default : console.log("default")
                   }
             }
        } */


        vm.expand = function (section, str) {
            vm.sectionResponse.forEach(function (item) {
                item.tasks = false;
            });

            if (vm.toggle == section.sectionId) {
                vm.toggle = undefined;
            } else {
                switch (str) {
                    case "tasks":
                        vm.expandResponse = null
                        vm.headers = null
                        routeDetail.postSectionsDetails({ "sectionIds": [section.sectionId] }, function (response) {
                            vm.headers = [
                                { key: "taskName", display: "Task Name" },
                                { key: "userLogin", display: "Assigned To" }
                            ]
                            vm.expandResponse = response
                        })
                        section.tasks = true;
                        vm.toggle = section.sectionId;
                        break;

                    default: console.log("default")
                }
            }
        }

        SummaryPost.dailyLogsTx({
            "projectIds": [vm.trRoute.projectId],
            "routeIds": [vm.trRoute.id]
        }, function (response) {
            vm.dailyLogsTxSummary = response
        })

        SummaryPost.mtrlTx({
            "projectIds": [vm.trRoute.projectId],
            "routeIds": [vm.trRoute.id]
        }, function (response) {
            vm.mtrlTxSummary = response
        })

        SummaryPost.resTx({
            "projectIds": [vm.trRoute.projectId],
            "routeIds": [vm.trRoute.id]
        }, function (response) {
            vm.resTxSummary = response
        })

        vm.objectRouteImg = {}
        vm.objectRouteImg.routeId = vm.trRoute.id
        vm.objectRouteImg.map = locations.map

        vm.mapFilterList = [];
        var polylines = []
        var tempPath = []


        TrRouteForMap.getTerrainPathByRoute(
            vm.trRoute.id,
            function (response) {
                vm.terrainPathResponse = response

                callTerrainPath()

            }, function (err) {
                /* vm.mapFilterList.push({
                    name:
                        '<strong>' + vm.trRoute.name + '</strong>',
                    path: coordinateList,
                    visible: true,
                    strokeColor: '<img class="square" style="background-color:#' +
                        'FD7A24' +
                        ';">',
                    colorCode: 'FD7A24',
                    routeDisable: true
                })

                for (var i = 0; i < vm.mapFilterList.length; i++) {
                    var line = new google.maps.Polyline({
                        path: vm.mapFilterList[i].path,
                        visible: vm.mapFilterList[i].visible,
                        strokeColor: '#' + vm.mapFilterList[i].colorCode,
                        strokeOpacity: 1.0,
                        strokeWeight: 4,
                        name: vm.mapFilterList[i].name
                    });
                    line.setMap(locations.map);
                    polylines.push(line);
                } */

                TrRouteForMap.getRoWPathByRoute(
                    vm.trRoute.id,
                    function (response) {
                        vm.rowPathResponse = response
                        if (response.length) {
                            vm.mapFilterList.push({ name: '<strong>RoW</strong>', msGroup: true })
                            callRowPath()
                        }

                    }
                );
            }
        );

        function callTerrainPath() {

            TrRouteForMap.getTerrainByRoute(
                vm.trRoute.id,
                function (nameResponse) {
                    vm.terrainListResponse = nameResponse
                    if (nameResponse.length) {
                        vm.isShowFilter = true
                        /* vm.mapFilterList.push({
                            name:
                                '<strong>' + vm.trRoute.name + '</strong>',
                            path: coordinateList,
                            visible: true,
                            strokeColor: '<img class="square" style="background-color:#' +
                                'FD7A24' +
                                ';">',
                            colorCode: 'FD7A24',
                            routeDisable: true,

                        }) */
                        vm.mapFilterList.push({ name: '<strong>Terrain</strong>', msGroup: true })
                        nameResponse.forEach(function (nameEach, indexName) {
                            if (vm.terrainPathResponse.length) {
                                vm.terrainPathResponse.forEach(function (item, indexPath) {
                                    if (nameEach.id == item.id) {
                                        if (
                                            item.value["COORDINATE"]
                                        ) {
                                            item.value["COORDINATE"].forEach(function (cord) {
                                                tempPath.push({ lat: cord.latitude, lng: cord.longitude })
                                            })

                                            var colorCode = randomColor()
                                            vm.mapFilterList.push({
                                                name:
                                                    nameEach.name,
                                                path: tempPath,
                                                visible: true,
                                                strokeColor: '<img class="square" style="background-color:' +
                                                    colorCode +
                                                    ';">',
                                                colorCode: colorCode
                                            });
                                            tempPath = []
                                        }
                                    }
                                });
                            }

                            for (var i = 0; i < vm.mapFilterList.length; i++) {
                                var line = new google.maps.Polyline({
                                    path: vm.mapFilterList[i].path,
                                    visible: vm.mapFilterList[i].visible,
                                    strokeColor: vm.mapFilterList[i].colorCode,
                                    strokeOpacity: 1.0,
                                    strokeWeight: 2.5,
                                    name: vm.mapFilterList[i].name
                                });
                                line.setMap(locations.map);
                                polylines.push(line);
                            }
                        });
                        vm.mapFilterList.push({ msGroup: false })

                        TrRouteForMap.getRoWPathByRoute(
                            vm.trRoute.id,
                            function (response) {
                                vm.rowPathResponse = response
                                if (response.length) {
                                    vm.mapFilterList.push({ name: '<strong>RoW</strong>', msGroup: true })
                                    callRowPath()
                                }

                            }
                        );
                    } else {
                        /* vm.mapFilterList.push({
                            name:
                                '<strong>' + vm.trRoute.name + '</strong>',
                            path: coordinateList,
                            visible: true,
                            strokeColor: '<img class="square" style="background-color:#' +
                                'FD7A24' +
                                ';">',
                            colorCode: 'FD7A24',
                            routeDisable: true
                        })

                        for (var i = 0; i < vm.mapFilterList.length; i++) {
                            var line = new google.maps.Polyline({
                                path: vm.mapFilterList[i].path,
                                visible: vm.mapFilterList[i].visible,
                                strokeColor: '#' + vm.mapFilterList[i].colorCode,
                                strokeOpacity: 1.0,
                                strokeWeight: 4,
                                name: vm.mapFilterList[i].name
                            });
                            line.setMap(locations.map);
                            polylines.push(line);
                        } */

                        TrRouteForMap.getRoWPathByRoute(
                            vm.trRoute.id,
                            function (response) {
                                vm.rowPathResponse = response
                                if (response.length) {
                                    vm.mapFilterList.push({ name: '<strong>RoW</strong>', msGroup: true })
                                    callRowPath()
                                }

                            }
                        );
                    }
                },
                function (error) {
                    /* vm.mapFilterList.push({
                        name:
                            '<strong>' + vm.trRoute.name + '</strong>',
                        path: coordinateList,
                        visible: true,
                        strokeColor: '<img class="square" style="background-color:#' +
                            'FD7A24' +
                            ';">',
                        colorCode: 'FD7A24',
                        routeDisable: true
                    })

                    for (var i = 0; i < vm.mapFilterList.length; i++) {
                        var line = new google.maps.Polyline({
                            path: vm.mapFilterList[i].path,
                            visible: vm.mapFilterList[i].visible,
                            strokeColor: '#' + vm.mapFilterList[i].colorCode,
                            strokeOpacity: 1.0,
                            strokeWeight: 4,
                            name: vm.mapFilterList[i].name
                        });
                        line.setMap(locations.map);
                        polylines.push(line);
                    } */

                    TrRouteForMap.getRoWPathByRoute(
                        vm.trRoute.id,
                        function (response) {
                            vm.rowPathResponse = response
                            if (response.length) {
                                vm.mapFilterList.push({ name: '<strong>RoW</strong>', msGroup: true })
                                callRowPath()
                            }

                        }
                    );
                }
            );
        }

        function callRowPath() {

            TrRouteForMap.getRoWByRoute(
                vm.trRoute.id,
                function (nameResponse) {
                    vm.rowListResponse = nameResponse
                    if (nameResponse.length) {
                        nameResponse.forEach(function (nameEach, indexName) {
                            if (vm.rowPathResponse.length) {
                                vm.rowPathResponse.forEach(function (item, indexPath) {
                                    if (nameEach.id == item.id) {
                                        if (
                                            item.value["COORDINATE"]
                                        ) {
                                            item.value["COORDINATE"].forEach(function (cord) {
                                                tempPath.push({ lat: cord.latitude, lng: cord.longitude })
                                            })

                                            var colorCode = randomColor()
                                            vm.mapFilterList.push({
                                                name:
                                                    nameEach.name,
                                                path: tempPath,
                                                visible: true,
                                                strokeColor: '<img class="square" style="background-color:' +
                                                    colorCode +
                                                    ';">',
                                                colorCode: colorCode
                                            });
                                            tempPath = []
                                        }
                                    }
                                });
                            }

                            for (var i = 0; i < vm.mapFilterList.length; i++) {
                                var line = new google.maps.Polyline({
                                    path: vm.mapFilterList[i].path,
                                    visible: vm.mapFilterList[i].visible,
                                    strokeColor: vm.mapFilterList[i].colorCode,
                                    strokeOpacity: 1.0,
                                    strokeWeight: 2.5,
                                    name: vm.mapFilterList[i].name
                                });
                                line.setMap(locations.map);
                                polylines.push(line);
                            }
                        });
                        vm.mapFilterList.push({ msGroup: false })
                    }
                }
            );
        }
        $scope.togglePolyline = function () {
            if (vm.selectedTerrainName.length) {
                clearPolyLines()
                vm.selectedTerrainName.forEach(function (each) {
                    for (var i = 0; i < polylines.length; i++) {
                        if (polylines[i].name === each.name) {
                            if (each.visible) {
                                polylines[i].setMap(locations.map);
                            }
                        }
                    }
                })
            } else {
                clearPolyLines()
            }
        };


        function clearPolyLines() {
            for (var i = 0; i < polylines.length; i++) {

                polylines[i].setMap(null);

            }
        }


        /*  vm.closeModal = function(id) {
          vm.nameImages = null
          $("#" + id).removeClass("in");
          $(".modal-backdrop").remove();
          $("#" + id).hide();
      } */

        $scope.type = {
            terrain: {
                search: "Search",
                nothingSelected: "Map Filter",
                selectAll: "All",
                selectNone: "None",
                Done: "Done"
            },
            phase: {
                search: "Search",
                nothingSelected: "Phase Filter",
                selectAll: "All",
                selectNone: "None",
                Done: "Done"
            },
            block: {
                search: "Search",
                nothingSelected: "Block Filter",
                selectAll: "All",
                selectNone: "None",
                Done: "Done"
            }
        };

        function randomColor() {
            var unusedColors = colors.filter(function (color) {
                return !color.used;
            });
            if (unusedColors.length === 0) return null;
            var randomIndex = Math.floor(Math.random() * unusedColors.length);
            var randomColor = unusedColors[randomIndex];
            randomColor.used = true;
            return randomColor.color;
        }

        function createMetaTag(attrName, attrValue, content) {
            var meta = document.createElement('meta');
            meta.setAttribute(attrName, attrValue);
            meta.setAttribute('content', content);
            return meta;
        }

        function createTable(classNames) {
            var table = document.createElement('table');
            table.setAttribute('border', '1');
            table.setAttribute('cellpadding', '5');
            table.setAttribute('cellspacing', '0');
            table.setAttribute('id', 'sheet0');
            table.setAttribute('class', classNames);
            return table;
        }

        function createTableCell(tagName, textContent) {
            var cell = document.createElement(tagName);
            cell.textContent = textContent;
            return cell;
        }

        function showTaskLogReport(routeId) {
            vm.isReportGenerated = false;
            TaskLogRouteReportData.query(
                {
                    reportType: "daily",
                    routeId: routeId
                },
                onSuccess,
                onError
            );

            function onSuccess(data) {
                var htmlDoc = document.createElement('html');
                var head = document.createElement('head');
                var meta1 = createMetaTag('http-equiv', 'Content-Type', 'text/html; charset=utf-8');
                var meta2 = createMetaTag('name', 'generator', 'PhpSpreadsheet, https://github.com/PHPOffice/PhpSpreadsheet');
                var meta3 = createMetaTag('name', 'author', 'Apache POI');
                var meta4 = createMetaTag('name', 'company', 'Microsoft Corporation');
                head.appendChild(meta1);
                head.appendChild(meta2);
                head.appendChild(meta3);
                head.appendChild(meta4);
                var body = document.createElement('body');
                var table = createTable('sheet0 gridlines');
                var thead = document.createElement('thead');
                var tbody = document.createElement('tbody');

                function createTable(classNames) {
                    var table = document.createElement('table');
                    table.setAttribute('border', '1');
                    table.setAttribute('cellpadding', '5');
                    table.setAttribute('cellspacing', '0');
                    table.setAttribute('id', 'sheet0');
                    table.setAttribute('class', classNames);
                    return table;
                }

                function createMetaTag(attrName, attrValue, content) {
                    var meta = document.createElement('meta');
                    meta.setAttribute(attrName, attrValue);
                    meta.setAttribute('content', content);
                    return meta;
                }

                function createTableCell(tag, content) {
                    var cell = document.createElement(tag);
                    cell.textContent = content;
                    return cell;
                }

                thead.className = 'thReport';
                table.appendChild(thead);
                tbody.className = 'tdReport';
                table.appendChild(tbody);
                var styleElement = document.createElement('style');
                styleElement.textContent = "\
        .subheader {\
            margin: auto;\
        }\
        .thReport > tr > th{\
            text-align: center;\
            padding: 10px;\
        }\
        .tdReport > tr > td {\
            text-align: center; \
            padding: 10px; \
        }\
    ";
                document.head.appendChild(styleElement);

                var groupedData = {};
                data.forEach(function (item) {
                    if (!groupedData[item.date]) {
                        groupedData[item.date] = [];
                    }
                    groupedData[item.date].push(item);
                });
                var names = data.map(function (item) { return item.name; }).filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                });
                var nameHeaderRow = document.createElement('tr');
                var serialNumberHeader = createTableCell('th', 'S.No');
                serialNumberHeader.style.backgroundColor = 'orange';
                serialNumberHeader.rowSpan = 2;
                nameHeaderRow.appendChild(serialNumberHeader);
                var dateHeader = createTableCell('th', 'Date');
                dateHeader.style.backgroundColor = 'orange';
                dateHeader.rowSpan = 2;
                nameHeaderRow.appendChild(dateHeader);
                var typeHeaderRow = document.createElement('tr');

                names.forEach(function (name, index) {
                    var nameData = data.filter(function (item) { return item.name === name; });
                    var availableTypes = nameData.map(function (item) { return item.type; }).filter(function (value, index, self) {
                        return self.indexOf(value) === index;
                    });
                    var nameHeaderCell = createTableCell('th', name);
                    nameHeaderCell.style.backgroundColor = 'lightgreen';
                    nameHeaderCell.colSpan = availableTypes.length;
                    nameHeaderRow.appendChild(nameHeaderCell);
                    availableTypes.forEach(function (type) {
                        var typeHeaderCell = createTableCell('th', type);
                        typeHeaderCell.style.backgroundColor = 'lightgreen';
                        typeHeaderRow.appendChild(typeHeaderCell);
                    });
                });
                var totalProgressHeader = createTableCell('th', 'Total Progress');
                totalProgressHeader.style.backgroundColor = 'lightgray';
                totalProgressHeader.colSpan = 3;
                nameHeaderRow.appendChild(totalProgressHeader);
                var subHeaders = [
                    {
                        type: 'Total',
                    },
                    {
                        type: "Ducting",
                    }, {
                        type: "Validation",

                    }
                ];
                subHeaders.forEach(function (subHeader) {
                    var subHeaderCell = createTableCell('th', subHeader.type);
                    subHeaderCell.style.backgroundColor = 'lightgray';
                    typeHeaderRow.appendChild(subHeaderCell);
                });
                thead.appendChild(nameHeaderRow);
                thead.appendChild(typeHeaderRow);
                var totals = {};
                Object.keys(groupedData)
                    .sort(function (a, b) { return new Date(a) - new Date(b); })
                    .forEach(function (date, index) {
                        var tr = document.createElement('tr');
                        var serialNumberCell = createTableCell('td', index + 1);
                        serialNumberCell.style.backgroundColor = 'orange';
                        tr.appendChild(serialNumberCell);
                        var dateCell = createTableCell('td', date);
                        dateCell.style.backgroundColor = 'orange';
                        tr.appendChild(dateCell);
                        names.forEach(function (name) {
                            var nameData = data.filter(function (item) { return item.name === name; });
                            var availableTypes = nameData.map(function (item) { return item.type; }).filter(function (value, index, self) {
                                return self.indexOf(value) === index;
                            });

                            availableTypes.forEach(function (type) {
                                var item = groupedData[date].find(function (item) {
                                    return item.name === name && item.type === type;
                                });
                                var lengthValue = item ? item.length : 0;
                                var td = createTableCell('td', lengthValue);
                                tr.appendChild(td);
                                if (!totals[type]) {
                                    totals[type] = 0;
                                }
                                totals[type] += lengthValue;
                            })
                        });
                        var total = 0;
                        for (var i = 2; i < tr.cells.length; i++) {
                            total += parseInt(tr.cells[i].textContent);
                        }
                        var columnValueCell = createTableCell('td', total);
                        columnValueCell.colSpan = 1;
                        tr.appendChild(columnValueCell);

                        var remainingDuctingCell = createTableCell('td', 0);
                        tr.appendChild(remainingDuctingCell);

                        var remainingValidationCell = createTableCell('td', 0);
                        tr.appendChild(remainingValidationCell);
                        tbody.appendChild(tr);
                    });

                var staticColumns = ['Total', 'Balance Validation', 'Balance Ducting', 'Material Issued', 'Wastage', 'Stock at Site'];
                var allAvailableTypes = [];
                names.forEach(function (name) {
                    var nameData = data.filter(function (item) { return item.name === name; });
                    allAvailableTypes.push.apply(allAvailableTypes, nameData.map(function (item) { return item.type; }).filter(function (value, index, self) {
                        return self.indexOf(value) === index;
                    }));
                });
                var availableTypes = allAvailableTypes.filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                });
                var maxUniqueTypesCount = availableTypes.length;
                var totalLength = maxUniqueTypesCount + subHeaders.length + 2;

                var columnTotals = {};
                for (var i = 2; i < totalLength; i++) {
                    var total = 0;
                    for (var j = 0; j < tbody.rows.length; j++) {
                        var cellValue = parseInt(tbody.rows[j].cells[i].textContent) || 0;
                        total += cellValue;
                    }
                    columnTotals[i] = total;
                }

                staticColumns.forEach(function (column) {
                    var staticRow = document.createElement('tr');
                    var columnNameCell = createTableCell('td', column);
                    columnNameCell.colSpan = 2;
                    columnNameCell.style.backgroundColor = 'lightblue';
                    columnNameCell.style.fontSize = "14px";
                    columnNameCell.style.fontWeight = 'bold';
                    staticRow.appendChild(columnNameCell);

                    if (column == 'Total') {
                        for (var i = 2; i < totalLength; i++) {
                            var columnValueCell = createTableCell('td', columnTotals[i].toString());
                            columnValueCell.colSpan = 1;
                            staticRow.appendChild(columnValueCell);
                        }
                    } else {
                        for (var i = 0; i < totalLength - 2; i++) {
                            var columnValueCell = createTableCell('td', '0');
                            columnValueCell.colSpan = 1;
                            staticRow.appendChild(columnValueCell);
                        }
                    }
                    tbody.appendChild(staticRow);
                });

                table.appendChild(thead);
                table.appendChild(tbody);
                body.appendChild(table);
                htmlDoc.appendChild(body);
                htmlDoc.appendChild(head);
                var htmlString = htmlDoc.outerHTML;
                var containerDiv = document.getElementById("generatedHtmlContainer");
                containerDiv.innerHTML = htmlString;

                // Set background color for specified cells
                var jcbCells = document.querySelectorAll('.row1 .column0'); // Select cells in row 1, column 0 (JCB)
                var hddCells = document.querySelectorAll('.row1 .column1'); // Select cells in row 1, column 1 (HDD)

                // Loop through JCB cells and set background color to light green
                jcbCells.forEach(function (cell) {
                    cell.style.backgroundColor = 'lightgreen';
                });

                // Loop through HDD cells and set background color to light green
                hddCells.forEach(function (cell) {
                    cell.style.backgroundColor = 'lightgreen';
                });

                // Get the Total row
                // Select all relevant rows
                var relevantRows = ['.row2', '.row3', '.row5', '.row6'];

                // Loop through each relevant row
                relevantRows.forEach(function (rowClass) {
                    var cells = document.querySelectorAll(rowClass + ' td');

                    // Loop through the cells in the row
                    cells.forEach(function (cell) {
                        // Check if the content of the cell is '0' or empty
                        if (cell.textContent.trim() === '0' || cell.textContent.trim() === '') {
                            // Set background color to white
                            cell.style.backgroundColor = 'white';
                        }
                    });
                });

                vm.isReportGenerated = true;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }


        function clearPhase() {
            for (var i = 0; i < phasePolylines.length; i++) {

                phasePolylines[i].setMap(null);

            }

            for (var i = 0; i < phaseMarkers.length; i++) {

                phaseMarkers[i].setMap(null);

            }
        }


        function createInfoWindow(polyline, each) {
            google.maps.event.addListener(
                polyline,
                "click",
                (function () {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                        /* if (spreadPoly != null) {
                            spreadPoly.setMap(null);
                        } */
                    }

                    /*   clearTimeout(polylineTimer);
  
                      spreadPoly = new google.maps.Polyline({
                          path: {
                              lat: each.lat,
                              lng: each.lng,
                          },
                          strokeColor: "#FFFF00",
                          strokeOpacity: 0.6,
                          strokeWeight: 19,
                      });
  
                      spreadPoly.setMap(locations.map);
  
                      polylineTimer = setTimeout(function () {
                          if (spreadPoly != null) {
                              spreadPoly.setMap(null);
                              spreadPoly = null;
                          }
                      }, 5000); */

                    var name = each.name ? "<b>Name : " + each.name + "</b></br>" : ""
                    var block = each.block ? "<b>Block : " + each.block + "</b></br>" : ""
                    /*  if (openedInfoWindow != null) {
                         openedInfoWindow.close();
                     } */


                    infowindow.setContent(
                        '<div class="infoWindowhead"></br></br>' +
                        name +
                        "<b>Latitude : " + each.lat + "</b></br>" +
                        "<b>Longitude : " + each.lng + "</b></br>" +
                        block +
                        "</br></div>"
                    );


                    infowindow.open(locations.map);
                    infowindow.setPosition({
                        lat: each.lat,
                        lng: each.lng,
                    });
                    openedInfoWindow = infowindow;
                }))
        }
        vm.mapAssets = [];
        callAssetsRouteByID(vm.trRoute.id, null);

        function callAssetsRouteByID(routeId, lastId) {
            assetsByRouteBundleId.get(routeId, lastId, function (data) {
                data.assets.forEach(function (asset) {
                    var tmpAsset = {};

                    tmpAsset.assetCoordinates = asset.assetCoordinates;
                    tmpAsset.assetTypeName = asset.assetTypeName;
                    tmpAsset.assetTypeColor = asset.assetColor;
                    tmpAsset.assetTypeImagePath = asset.assetTypeImagePath;
                    tmpAsset.assetTypeLayout = asset.assetTypeLayout;
                    tmpAsset.id = asset.id;
                    tmpAsset.name = asset.name;
                    tmpAsset.burried = asset.burried;
                    vm.mapAssets.push(tmpAsset);
                });

                if (data.assets.length >= 50) {
                    callAssetsRouteByID(
                        vm.trRoute.id,
                        data.assets[data.assets.length - 1].id
                    );
                } else {
                    drawLatestAssets(vm.mapAssets);
                    locations.map.setCenter({lat:vm.mapAssets[0].assetCoordinates[0].latitude,lng:vm.mapAssets[0].assetCoordinates[0].longitude});
                    locations.map.setZoom(11);
                }
            });
        }

        function setupAssetMap(callback) {
            if (locations.map == null) {
                /* var myOptions = {
                    zoom: 12,
                    center: locations.latlng,
                    scaleControl: true,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                }; */

                locations.map = new google.maps.Map(
                    document.getElementById("map_canvas"),
                    myOption(12, locations.latlng)
                );
                geoLocation(locations.map);
                document.getElementById("myspan").textContent = "";
                /* var measureTool = new MeasureTool(locations.map, {
                    contextMenu: true,
                    unit: MeasureTool.UnitTypeId.METRIC,
                }); */
            } else {
                // Loop through all the markers and polylines to remove

                for (var i = 0; i < markers.length; i++) {
                    markers[i].setMap(null);
                }

                for (i = 0; i < polylines.length; i++) {
                    polylines[i].setMap(null);
                }

                markers = [];
                polylines = [];
            }
            locations.overlay = new google.maps.OverlayView();
            locations.overlay.draw = function () { };
            locations.overlay.setMap($scope.map);
            locations.element = document.getElementById("map_canvas");

            callback();
        }

        function drawLatestAssets(response) {
            if (response) {
                setupAssetMap(function () {
                    $.each(response, function (index, value) {
                        if (value.assetTypeLayout == null) {
                            value.assetTypeName = value.assetType.name;
                            value.assetTypeLayout = value.assetType.layout;
                            value.assetTypeImagePath =
                                value.assetType.imagePath;
                        }
                        if (
                            value.assetTypeLayout != null &&
                            value.assetTypeLayout == "FIXED"
                        ) {
                            if (value.assetCoordinates.length > 1) {
                                // drawPolyline(value);
                                var value1 = value;
                                value1.assetCoordinates = [
                                    value.assetCoordinates[
                                    value.assetCoordinates.length - 1
                                    ],
                                ];

                                if (
                                    $scope.selectedFilterAssetTypes.length >
                                    0
                                ) {
                                    $scope.selectedFilterAssetTypes.forEach(
                                        function (assetTypeVal) {
                                            if (
                                                assetTypeVal.name ==
                                                value1.assetTypeName
                                            ) {
                                                drawFixedAsset(
                                                    value1
                                                );
                                            }
                                        }
                                    );
                                } else {
                                    drawFixedAsset(value1);
                                }
                            } else {
                                drawFixedAsset(value);
                            }
                        }
                        if (
                            value.assetTypeLayout != null &&
                            value.assetTypeLayout == "SPREAD"
                        ) {
                            drawPolyline(
                                value
                            );
                        }
                    });
                });
            }
        }

        function drawFixedAsset(fixed) {
            $.each(fixed.assetCoordinates, function (index, value1) {
                if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }

                var iconUrl = fixed.assetTypeImagePath;
                iconSize = 24;

                var startMarker = new google.maps.Marker({
                    map: locations.map,
                    position: new google.maps.LatLng(
                        value1.latitude,
                        value1.longitude
                    ),
                    title: fixed.name,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: iconUrl,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                });
                startMarker.labelZoom = fixed.label;
                startMarker.assetId = fixed.id;
                startMarker.displayLabel = {
                    text: fixed.name,
                    color: "#000",
                    fontSize: "12px",
                    fontWeight: "bold",
                };

                markers.push(startMarker);

                var infoWindow2 = new google.maps.InfoWindow();
                var infoStr = "";
                var otdr = "";

                var userContent = document.createElement("div"),
                    button;

                userContent.innerHTML =
                    '<div class="infoWindowhead">' +
                    startMarker.title +
                    '</div><div class="infoWindowContent">' +
                    "<b>Lat</b> :" +
                    fixed.assetCoordinates[fixed.assetCoordinates.length - 1]
                        .latitude +
                    "<br><b>Long</b> :" +
                    fixed.assetCoordinates[fixed.assetCoordinates.length - 1]
                        .longitude +
                    "<br>" +
                    infoStr +
                    "</div>";

                google.maps.event.addListener(
                    startMarker,
                    "dblclick",
                    function () {
                        infoWindow2.close();
                    }
                );

                var activeInfoWindow;
                var prevInfoWindow;
                var infoWindow2 = new google.maps.InfoWindow({
                    content: userContent,
                });
                // add listener on InfoWindow for click
                // event
                google.maps.event.addListener(
                    startMarker,
                    "click",
                    function () {
                        if (existingHighlightFixed != null) {
                            existingHighlightFixed.setMap(null);
                            existingHighlightFixed = null;
                        }

                        var circle = new google.maps.Circle({
                            map: locations.map, // The map object
                            center: { lat: startMarker.map.center.lat(), lng: startMarker.map.center.lng() }, // Center of the circle (same as the marker)
                            radius: 2, // Radius in meters (1000 meters = 1 kilometer)
                            strokeColor: '#fd7a24', // Color of the circle's border
                            strokeOpacity: 0.8, // Opacity of the circle's border
                            strokeWeight: 2, // Width of the circle's border
                            fillColor: '#fd7a24', // Fill color of the circle
                            fillOpacity: 1, // Opacity of the circle's fill color
                        });

                        // Bind the circle to the marker so it moves with the marker if the marker is draggable
                        circle.bindTo('center', startMarker, 'position');
                        existingHighlightFixed = circle;

                        var fixedHighlightTimer = setTimeout(function () {
                            if (circle != null) {
                                circle.setMap(null);
                                circle = null;
                            }
                        }, 6000);

                        if (activeInfo != null) {
                            activeInfo.close();
                        }
                        closeLastOpenedInfoWindow();

                        AssetByID.get(fixed.id, function (response) {
                            vm.assetRefAttributes = [];
                            if (response.assetReferenceAttributes.length) {
                                response.assetReferenceAttributes.forEach(
                                    function (get) {
                                        get[
                                            "assetAttributeName"
                                        ].assetTypeAttribute.attributeValue =
                                            get[
                                                "assetAttributeName"
                                            ].attributeValue;
                                        vm.assetRefAttributes.push(
                                            get["assetAttributeName"]
                                                .assetTypeAttribute
                                        );
                                        get[
                                            "assetAttributeValue"
                                        ].assetTypeAttribute.attributeValue =
                                            get[
                                                "assetAttributeValue"
                                            ].attributeValue;
                                        vm.assetRefAttributes.push(
                                            get["assetAttributeValue"]
                                                .assetTypeAttribute
                                        );
                                        get[
                                            "assetAttributeImage"
                                        ].assetTypeAttribute.attributeValue =
                                            get["assetAttributeImage"].image;
                                        get[
                                            "assetAttributeImage"
                                        ].assetTypeAttribute.attributeName =
                                            get["assetAttributeImage"].imageName;
                                        get[
                                            "assetAttributeImage"
                                        ].assetTypeAttribute.imageId =
                                            get["assetAttributeImage"].imageId;
                                        get[
                                            "assetAttributeImage"
                                        ].assetTypeAttribute.attributeType =
                                            get["assetAttributeImage"].imageType;
                                        vm.assetRefAttributes.push(
                                            get["assetAttributeImage"]
                                                .assetTypeAttribute
                                        );
                                    }
                                );
                            }
                            vm.assetToEdit = {};
                            vm.assetToEdit = response;
                            vm.assetAttachments = response;
                            var infoWindow2 = new google.maps.InfoWindow();
                            var infoStr = "";
                            var otdr = "";
                            var assetTypeAttributeValuesList =
                                response.assetTypeAttributeValues;
                            assetTypeAttributeValuesList.sort(function (a, b) {
                                var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                    nameB = a.assetTypeAttribute.name.toLowerCase();
                                if (nameA < nameB)
                                    // sort string ascending
                                    return -1;
                                if (nameA > nameB) return 1;
                                return 0; // default return value (no sorting)
                            });
                            for (
                                var i = 0;
                                i < assetTypeAttributeValuesList.length;
                                i++
                            ) {
                                var home = assetTypeAttributeValuesList[i];
                                infoStr +=
                                    "<br><b>" +
                                    home.assetTypeAttribute.name +
                                    "</b> : " +
                                    home.attributeValue;
                                otdr = home;
                            }

                            var userContent = document.createElement("div"),
                                btnShowAttributes,
                                btnThreePointRef

                            var url = $state.href('asset-detail', { id: response.id });

                            userContent.innerHTML =
                                '<div class="infoWindowhead">' + /* 
                                "<b>Id</b> : <a target='_blank' href='" + url + "'>" + */ response.id +
                                "</a><br><b>Name</b>" +
                                response.name +
                                '</div><div class="infoWindowContent">' +
                                "<b>Asset Type</b> : " +
                                fixed.assetTypeName +
                                "<br><b>Latitude</b> :" +
                                fixed.assetCoordinates[
                                    fixed.assetCoordinates.length - 1
                                ].latitude +
                                "<br><b>Longitude</b> :" +
                                fixed.assetCoordinates[
                                    fixed.assetCoordinates.length - 1
                                ].longitude +
                                "</div>";

                            vm.assetTitle = response.name;

                            var attr = document.createAttribute("data-toggle");
                            attr.value = "modal";
                            var attr1 = document.createAttribute("data-target");
                            attr1.value = "#myModalMap";

                           
                            btnShowAttributes = userContent.appendChild(
                                document.createElement("input")
                            );

                            btnShowAttributes.type = "button";
                            btnShowAttributes.value = "Show Attributes";
                            btnShowAttributes.className = "button";

                            btnThreePointRef = userContent.appendChild(
                                document.createElement("input")
                            );
                            btnThreePointRef.type = "button";
                            btnThreePointRef.value = "3 Point Reference";
                            btnThreePointRef.className = "button";

                            google.maps.event.addDomListener(
                                btnShowAttributes,
                                "click",
                                function () {
                                    showAssetAttributesModal(response);
                                }
                            );

                            google.maps.event.addDomListener(
                                btnThreePointRef,
                                "click",
                                function () {
                                    showThreePontReferenceModal(response);
                                }
                            );

                            vm.closeInfoWindow = new google.maps.InfoWindow({
                                content: userContent,
                            });
                            if (prevInfoWindow != null) prevInfoWindow.close();
                            vm.closeInfoWindow.open(locations.map, startMarker);
                            setTimeout(function () {
                                vm.closeInfoWindow.close();
                            }, 10000);

                            activeInfoWindow = vm.closeInfoWindow;
                            prevInfoWindow = vm.closeInfoWindow;
                        });
                    }
                );
                openedInfoWindow = infoWindow2;
            });
        }

        function drawPolyline(spread) {
            var spreadAssetCoordinates = [];
            var spreadColor = 0;
            var spreadWidth = 0;
            var spreadOpacity = 0;

            vm.assetTypeValues.forEach(function (assetType) {
                if (assetType.name == spread.assetTypeName) {
                    spreadColor = assetType.colorcode;
                    spreadWidth = assetType.lineWidth * 0.05;
                    spreadOpacity = assetType.transparency * 0.01;
                }
            });
            spread.assetCoordinates.sort(function (a, b) {
                return a.id - b.id; // Ascending order
            });
            $.each(spread.assetCoordinates, function (index, value) {
                spreadAssetCoordinates.push({
                    lat: value.latitude,
                    lng: value.longitude,
                });
            });
            if (spreadAssetCoordinates.length > 1) {
                var midLatLng = {
                    lat:
                        spread.assetCoordinates[
                            spread.assetCoordinates.length - 1
                        ].latitude,
                    lng:
                        spread.assetCoordinates[
                            spread.assetCoordinates.length - 1
                        ].longitude,
                };

                var polylineColor = "";
                var infowindow = null;
                if (spread.assetTypeLayout == "SPREAD") {
                    polylineColor = spreadColor;
                }
                var polylineWeight = spreadWidth;
                var strokeOpacityVal = spreadOpacity;

                polylineColor = "#954535";

                if (spread.isCycle) {
                    polylineColor = "#FFD700";
                } else if (!spread.isConnected) {
                    polylineColor = "#08FF08"; //tmpColor = "blue";
                }
                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: strokeOpacityVal,
                    strokeWeight: polylineWeight,
                    fillColor: polylineColor,
                    fillOpacity: 0.3,
                    editable: false,
                });

                google.maps.event.addListener(
                    polylinepath,
                    "click",
                    function (event) {

                        if (existingHighlight != null) {
                            existingHighlight.setMap(null);
                            existingHighlight = null;
                        }

                        var polylinepathHighlight = new google.maps.Polyline({
                            path: spreadAssetCoordinates,
                            strokeColor: "#FFFF00",
                            strokeOpacity: 0.6,
                            strokeWeight: 19,
                        });


                        polylinepathHighlight.setMap(locations.map);
                        existingHighlight = polylinepathHighlight;

                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        closeLastOpenedInfoWindow();
                        var disVal = 0;

                        if (
                            spread.description == null ||
                            spread.description == undefined
                        ) {
                            spread.description = "";
                        }

                        if (spread.assetTypeLayout == "SPREAD" || spread.assetType.layout == "SPREAD") {
                            AssetByID.get(spread.id, function (assetVal) {
                                spread = assetVal;
                                vm.assetToEdit = {};
                                vm.assetToEdit = assetVal;
                                var infoStr = "";
                                var assetTypeAttributeValuesList =
                                    spread.assetTypeAttributeValues;
                                assetTypeAttributeValuesList.sort(function (
                                    a,
                                    b
                                ) {
                                    var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                        nameB = b.assetTypeAttribute.name.toLowerCase();
                                    if (nameA < nameB)
                                        // sort string ascending
                                        return -1;
                                    if (nameA > nameB) return 1;
                                    return 0; // default return value (no sorting)
                                });
                                for (
                                    var i = 0;
                                    i < assetTypeAttributeValuesList.length;
                                    i++
                                ) {
                                    var home = assetTypeAttributeValuesList[i];

                                    var attrVal = "";
                                    if (home.attributeValue != null) {
                                        attrVal = home.attributeValue;
                                    }
                                    infoStr +=
                                        "<br><b>" +
                                        home.assetTypeAttribute.name +
                                        "</b> : " +
                                        attrVal;
                                }
                                var spreadCoordinates = spread.assetCoordinates;
                                if (spreadCoordinates.length > 0) {
                                    for (var index in spreadCoordinates) {
                                        if (index > 0) {
                                            disVal += distanceCal(
                                                spreadCoordinates[index]
                                                    .latitude,
                                                spreadCoordinates[index]
                                                    .longitude,
                                                spreadCoordinates[index - 1]
                                                    .latitude,
                                                spreadCoordinates[index - 1]
                                                    .longitude
                                            );
                                        }
                                    }
                                }
                                var assetTypeAttrPopupNames = [];
                                assetVal.assetType.assetTypeAttributes.forEach(
                                    function (assetTypeAttrVal) {
                                        if (
                                            assetTypeAttrVal.assetTypePopupDisplay ==
                                            true
                                        ) {
                                            assetTypeAttrPopupNames.push(
                                                assetTypeAttrVal.name
                                            );
                                        }
                                    }
                                );
                                var getAttribute =
                                    assetVal.assetTypeAttributeValues;
                                var userContent = document.createElement("div"),
                                    btnShowAttributes,
                                    btnEditAssets;

                                var url = $state.href('asset-detail', { id: spread.id });

                                userContent.innerHTML =
                                    '<div class="infoWindowhead">' + "<b>Id</b> : <a target='_blank' href='" + url + "'>" + spread.id +
                                    "</a><br><b>Name : </b> " +
                                    spread.name +
                                    "<br><b>Asset Type : </b> " +
                                    spread.assetType.name +
                                    "</br>" +
                                    "<b>Distance : </b> " +
                                    parseFloat(disVal).toFixed(2) +
                                    " Km";

                                for (i = 0; i < getAttribute.length; i++) {
                                    if (
                                        assetTypeAttrPopupNames.includes(
                                            getAttribute[i].assetTypeAttribute
                                                .name
                                        )
                                    ) {
                                        userContent.innerHTML +=
                                            "<b>" +
                                            getAttribute[i].assetTypeAttribute
                                                .name +
                                            "</b>" +
                                            " : " +
                                            getAttribute[i].attributeValue +
                                            "</br>";
                                    }
                                }

                                btnShowAttributes = userContent.appendChild(
                                    document.createElement("input")
                                );

                                btnShowAttributes.type = "button";
                                btnShowAttributes.value = "Show Attributes";
                                btnShowAttributes.className = "button";

                                google.maps.event.addDomListener(
                                    btnShowAttributes,
                                    "click",
                                    function () {
                                        showAssetAttributesModal(assetVal);
                                    }
                                );
                                infowindow = new google.maps.InfoWindow({
                                    content: userContent,
                                });

                                if (infowindow != null) {
                                    infowindow.setPosition(event.latLng);
                                    infowindow.open(locations.map);
                                    openedInfoWindow = infowindow;
                                }
                            });
                        }

                    }
                );

                polylinepath.setMap(locations.map);
                polylines.push(polylinepath);
            }
        }

        function closeLastOpenedInfoWindow() {
            if (lastOpenedInfoWindow) {
                lastOpenedInfoWindow.close();
            }
            if (vm.closeInfoWindow) {
                vm.closeInfoWindow.close();
            }
            if (openedInfoWindow) {
                openedInfoWindow.close();
            }
        }
        
        function showAssetAttributesModal(assetVal) {
            $("#modalAssetAttributes").show();
            var assetTypeAttributeValuesList =
                assetVal.assetTypeAttributeValues;
            document.getElementById("assetAttributeTable").innerHTML = "";

            var newTable =
                "<table align='center' style='border: 1px solid black;width:50%;'><tr><th style='border: 1px solid black;text-align: center;'>Name</th><th style='border: 1px solid black;text-align: center;'>Value</th></tr>";

            assetTypeAttributeValuesList.forEach(function (value) {
                if (
                    value.assetTypeAttribute.assetAttributeTypes ==
                    "NON_REFERENCE"
                ) {
                    newTable +=
                        "<tr><td style='border: 1px solid black;text-align: center;'>" +
                        value.assetTypeAttribute.name +
                        "</td><td style='border: 1px solid black;text-align: center;'>" +
                        value.attributeValue +
                        "</td></tr>";
                }
            });
            newTable += "</table>";
            document.getElementById("assetNameAssetAttributes").textContent =
                assetVal.name + " - " + assetVal.assetType.name;
            document.getElementById("assetAttributeTable").innerHTML = newTable;
        }

        function showThreePontReferenceModal(assetVal) {
            $("#modalThreePointReference").show();
            var assetTypeAttributeValuesList =
                assetVal.assetTypeAttributeValues,
                assetReferenceAttributesList =
                    assetVal.assetReferenceAttributes,
                referenceAttribute = [];
            var newTable =
                "<table align='center' style='border: 1px solid black;width:50%;'><tr><th style='border: 1px solid black;text-align: center;'>Object</th><th style='border: 1px solid black;text-align: center;'>Distance (mtr)</th></tr>";
            var ref = [];
            var dist = [];
            if (assetVal.assetReferenceAttributes.length) {
                assetReferenceAttributesList.forEach(function (val) {
                    referenceAttribute.push(val["assetAttributeName"]);
                    referenceAttribute.push(val["assetAttributeValue"]);
                });
                referenceAttribute.forEach(function (val) {
                    if (
                        val.assetTypeAttribute.assetAttributeTypes ==
                        "THREE_PNT_REFERENCE"
                    ) {
                        if (
                            val.assetTypeAttribute.name ==
                            "3 Point Reference1" &&
                            val.attributeValue
                        ) {
                            ref.push(val.attributeValue);
                        } else if (
                            val.assetTypeAttribute.name ==
                            "3 Point Reference2" &&
                            val.attributeValue
                        ) {
                            ref.push(val.attributeValue);
                        } else if (
                            val.assetTypeAttribute.name ==
                            "3 Point Reference3" &&
                            val.attributeValue
                        ) {
                            ref.push(val.attributeValue);
                        } else if (
                            val.assetTypeAttribute.name == "Distance1" &&
                            val.attributeValue
                        ) {
                            dist.push(val.attributeValue);
                        } else if (
                            val.assetTypeAttribute.name == "Distance2" &&
                            val.attributeValue
                        ) {
                            dist.push(val.attributeValue);
                        } else if (
                            val.assetTypeAttribute.name == "Distance3" &&
                            val.attributeValue
                        ) {
                            dist.push(val.attributeValue);
                        }
                    }
                });
            } else {
                assetTypeAttributeValuesList.forEach(function (value) {
                    if (
                        value.assetTypeAttribute.assetAttributeTypes ==
                        "THREE_PNT_REFERENCE"
                    ) {
                        if (
                            value.assetTypeAttribute.name ==
                            "3 Point Reference1"
                        ) {
                            ref.push(value.attributeValue);
                        } else if (
                            value.assetTypeAttribute.name ==
                            "3 Point Reference2"
                        ) {
                            ref.push(value.attributeValue);
                        } else if (
                            value.assetTypeAttribute.name ==
                            "3 Point Reference3"
                        ) {
                            ref.push(value.attributeValue);
                        } else if (
                            value.assetTypeAttribute.name == "Distance1"
                        ) {
                            dist.push(value.attributeValue);
                        } else if (
                            value.assetTypeAttribute.name == "Distance2"
                        ) {
                            dist.push(value.attributeValue);
                        } else if (
                            value.assetTypeAttribute.name == "Distance3"
                        ) {
                            dist.push(value.attributeValue);
                        }
                    }
                });
            }

            for (var i = 0; i < 3; i++) {
                if (ref[i] || dist[i]) {
                    newTable +=
                        "<tr><td style='border: 1px solid black;text-align: center;'>" +
                        ref[i] +
                        "</td><td style='border: 1px solid black;text-align: center;'>" +
                        dist[i] +
                        "</td></tr>";
                }
            }
            newTable += "</table>";
            document.getElementById(
                "assetNameThreePointReference"
            ).textContent = assetVal.name + " - " + assetVal.assetType.name;
            document.getElementById(
                "threePointReferenceTable"
            ).innerHTML = newTable;
        }

        function closeAssetAttributesModal() {
            $("#modalAssetAttributes").removeClass("in");
            $(".modal-backdrop").remove();
            $("#modalAssetAttributes").hide();
        }

        function closeThreePointReference() {
            $("#modalThreePointReference").removeClass("in");
            $(".modal-backdrop").remove();
            $("#modalThreePointReference").hide();
        }
        function distanceCal(lat1, lon1, lat2, lon2) {
            var theta = lon1 - lon2;
            var dist =
                Math.sin(degrees_to_radians(lat1)) *
                Math.sin(degrees_to_radians(lat2)) +
                Math.cos(degrees_to_radians(lat1)) *
                Math.cos(degrees_to_radians(lat2)) *
                Math.cos(degrees_to_radians(theta));
            dist = Math.acos(dist);
            dist = radians_to_degrees(dist);
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344;
            if (isNaN(dist)) {
                return 0.0;
            } else {
                return dist;
            }
        }

        function degrees_to_radians(degrees) {
            var pi = Math.PI;
            return degrees * (pi / 180);
        }

        function radians_to_degrees(radians) {
            var pi = Math.PI;
            return radians * (180 / pi);
        }
        function downloadRouteBundleKml() {
            Report.downloadRouteBundleKml({
                id: vm.trRoute.id
            }, function (response) {
                var a = document.createElement("a");
                a.style = "display: none";
                var file = new Blob([(response)], { type: 'application/text' });
                var fileURL = URL.createObjectURL(file);
                a.href = fileURL;
                a.download = vm.trRoute.name + ".kml";
                a.click();
                window.URL.revokeObjectURL(fileURL);
            });
        }
    }
})();