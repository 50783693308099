(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('tr-route-plan', {
                parent: 'app',
                url: '/tr-route-plan',
                data: {
                    authorities: ['ROUTE_SIDEBAR'],
                    pageTitle: 'trakeyeApp.route.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-route-plan/tr-routes-plan.html',
                        controller: 'TrRoutePlanController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'last_modified_date,desc',
                        squash: true
                    },
                    search: null,
                    listgeofenceid: null,
                    rangeOption: { value: 'day' },
                    fromTime: null,
                    toTime: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            listgeofenceid: $stateParams.listgeofenceid,
                            rangeOption: $stateParams.rangeOption,
                            fromTime: $stateParams.fromTime,
                            toTime: $stateParams.toTime
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trRoute');
                        $translatePartialLoader.addPart('routeStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('tr-route-plan-detail', {
                parent: 'tr-route-plan',
                url: '/tr-route-plan/{id}',
                data: {
                    authorities: ['ROUTE_DETAIL'],
                    pageTitle: 'trakeyeApp.route.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-route-plan/tr-route-plan-detail.html',
                        controller: 'TrRoutePlanDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trRoute');
                        $translatePartialLoader.addPart('routeStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'TrRouteBundle', function ($stateParams, TrRouteBundle) {
                        return TrRouteBundle.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'tr-route',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('tr-route-plan.edit', {
                parent: 'tr-route-plan',
                url: '/Edit/{id}',
                data: {
                    authorities: ['ROUTE_EDIT'],
                    pageTitle: 'trakeyeApp.route.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-route-plan/tr-route-plan-dialog.html',
                        controller: 'TrRoutePlanDialogController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {

                    entity: ['$stateParams', 'TrRouteBundle', function ($stateParams, TrRouteBundle) {
                        return TrRouteBundle.get({ id: $stateParams.id }).$promise;
                    }],


                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'tr-route',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })

            .state('tr-route-plan.new', {
                parent: 'tr-route-plan',
                url: '/create/{lat}/{lng}/{assetdetails}/{desc}',
                data: {
                    authorities: ['ROUTE_CREATE']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/tr-route-plan/tr-route-plan-dialog.html',
                        controller: 'TrRoutePlanDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            description: $stateParams.desc,
                            createDate: null,
                            updateDate: null,
                            pinLat: parseFloat($stateParams.lat),
                            pinLong: parseFloat($stateParams.lng),
                            address: null,
                            escalated: null,
                            status: null,
                            id: null,
                            routeImages: []
                            // ,
                            // asset: {name: $stateParams.assetdetails}
                        };
                    }]
                }
            })
           
            .state('tr-route-plan.delete', {
                parent: 'tr-route-plan',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER_ADMIN', 'ROLE_HOD', 'ROLE_CITY_MANAGER', 'ROLE_CITY_PLANNER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/tr-route-plan/tr-route-plan-delete-dialog.html',
                        controller: 'TrRoutePlanDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['TrRouteBundle', function (TrRouteBundle) {
                                return TrRouteBundle.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('tr-route-plan', null, { reload: 'tr-route-plan' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }
})();