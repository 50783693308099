(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('AssetDetailController', AssetDetailController);

    AssetDetailController.$inject = ['$scope', '$rootScope', '$stateParams','$state', 'pagingParams', 'ParseLinks', 'paginationConstants','previousState', 'entity', 'Asset', 'User', 'AssetType','$localStorage','Configs','MappedFixedAssets'];

    function AssetDetailController($scope, $rootScope, $stateParams,$state, pagingParams, ParseLinks, paginationConstants,previousState, entity, Asset, User, AssetType,$localStorage,Configs,MappedFixedAssets) {
        var vm = this;

        vm.asset = entity;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        var locations = {};
        vm.isMapVisible = false;

        if(vm.asset.id!=null && vm.asset.assetType.layout=='SPREAD'){
            vm.asset.assetCoordinates.sort(function (a, b) {
                // Sort by sortOrder, placing null or undefined at the end
                if (a.sortOrder == null && b.sortOrder != null) {
                    return 1;
                } else if (a.sortOrder != null && b.sortOrder == null) {
                    return -1;
                } else if (a.sortOrder != null && b.sortOrder != null) {
                    // Both have sortOrder, compare numerically
                    return a.sortOrder - b.sortOrder;
                } else {
                    // Both sortOrder are null, fallback to id comparison
                    return a.id - b.id;
                }
            });
        }
        

        var assetCoor = [];
        vm.asset.assetCoordinates.forEach(function (val) {
            assetCoor.push({
                lat: val.latitude,
                lng: val.longitude,
            });
        });
        locations.map = new google.maps.Map(
            document.getElementById("map_canvas"),
            myOption(
                12,assetCoor[0]
            )
        );

        if(vm.asset.assetType.layout=='FIXED'){
            var infowindow = new google.maps.InfoWindow();
            var fixed = vm.asset;
            var iconUrl = "";
            var isFixedAsset = false;
            iconUrl = fixed.assetType.imagePath;
            isFixedAsset = true;

            var iconSize = 8;
            if (vm.zoomLevel == 10) {
                iconSize = 8;
            } else if (vm.zoomLevel == 11) {
                iconSize = 16;
            }

            if (isFixedAsset) {
                iconSize = 24;
            }

            var startMarker = new google.maps.Marker({
                map: locations.map,
                position: assetCoor[0],
                title: fixed.name,
                icon: {
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                    url: iconUrl,
                    anchor: new google.maps.Point(
                        iconSize / 2,
                        iconSize / 2
                    ),
                },
            });
            google.maps.event.addListener(
                startMarker,
                "mouseout",
                function (event) {
                    infowindow.close();
                }
            );

            google.maps.event.addListener(
                startMarker,
                "click",
                function (event) {
                    infowindow.setContent(
                        '<div class="infoWindowhead">' +
                        "<b>Asset Name : </b><br><br>" +
                        fixed.name +
                        "</br></div>"
                    );

                    infowindow.mousemove;
                    infowindow.open(locations.map);
                    infowindow.setPosition(event.latLng);
                }
            );
        } else {
            var spreadAsset = new google.maps.Polyline({
                path: assetCoor,
                strokeColor: vm.asset.assetType.colorcode
            });
            spreadAsset.setMap(locations.map);
        }
        

        

        vm.toggleMap = function () {
            vm.isMapVisible = !vm.isMapVisible;
        };

        $scope.tab = 1;
        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };
        $scope.setTab = function (newTab) {
            $scope.tab = newTab; 
            if ($scope.tab == 2) { 
                MappedFixedAssets.get(vm.asset.id,pagingParams.page - 1,vm.itemsPerPage, function(response){
                    vm.links = ParseLinks.parse(response.headers("link"));
                    vm.totalItems = response.headers("X-Total-Count");
                    vm.queryCount = vm.totalItems;
                    vm.totalItems = Math.ceil(vm.queryCount / vm.itemsPerPage) * 10;
                    vm.listMappedAssets = response.data
                    vm.page = pagingParams.page;
                })
            }
        }
        vm.previousState = previousState.name;
        //vm.showAssetImage = false

        /* Configs.get("AssetImage",function(response){
            if(response.length > 0){
                if(response[0].configValue == "True"){
                    vm.showAssetImage = true
                }
            }
        }) */
        if(vm.asset.assetReferenceAttributes.length){
            vm.assetRefAttributes = []
            vm.asset.assetReferenceAttributes.forEach(function (get){
                if (get["assetAttributeName"] != null) {
                    get['assetAttributeName'].assetTypeAttribute.attributeValue = get['assetAttributeName'].attributeValue;
                    vm.assetRefAttributes.push(get['assetAttributeName'].assetTypeAttribute);
                }

                if (get["assetAttributeValue"] != null) {
                    get['assetAttributeValue'].assetTypeAttribute.attributeValue = get['assetAttributeValue'].attributeValue;
                    vm.assetRefAttributes.push(get['assetAttributeValue'].assetTypeAttribute);
                }

                if (get["assetAttributeImage"] != null) {
                    get['assetAttributeImage'].assetTypeAttribute.imageId = get['assetAttributeImage'].imageId;
                    get['assetAttributeImage'].assetTypeAttribute.attributeName = get['assetAttributeImage'].imageName;
                    vm.assetRefAttributes.push(get['assetAttributeImage'].assetTypeAttribute);
                }
            })
        }

       /*  vm.imageInNewTab = function (img) {
		    var imgWindow = window.open();
            imgWindow.document.write(
                '<img width="25%"   src="data:image/jpeg;base64,' +
                    img +
                    '">'
            );
            imgWindow.document.close(); 
		}; */
        vm.transition = function transition() {
          /*  if(vm.queryCount )
            MappedFixedAssets.get(vm.asset.id,vm.page,vm.itemsPerPage, function(response){
                vm.links = ParseLinks.parse(response.headers("link"));
                vm.totalItems = response.headers("X-Total-Count");
                vm.queryCount = vm.totalItems;
                vm.totalItems = Math.ceil(vm.queryCount / vm.itemsPerPage) * 10;
                vm.listMappedAssets = response.data
                vm.page = pagingParams.page;
            }) */
        }

        $scope.newTabDisplay = function(id,name,str){
            var request = new XMLHttpRequest(),apiImage = name == 'reference' ? "api/trakeyeassetrefimages/" : "api/trakeyeassetimages/";;
            request.open('GET',apiImage+vm.asset.id+"/"+id, true);
            request.setRequestHeader('Authorization', 'Bearer '+$localStorage.authenticationToken);
            request.responseType = 'blob';
            request.onload = response;
            
            function response(e) {
                if (this.status === 200) {
                    var urlCreator = window.URL || window.webkitURL;
                  
                    
                    if(str == "MP4"){
                        var videoUrl = urlCreator.createObjectURL(this.response);
                        
                        var video = document.createElement('video');
                        video.src = videoUrl;
                        video.controls = true;
                        video.autoplay = true;
                
                        var videoWindow = window.open("", "_blank");
                        videoWindow.document.body.appendChild(video);
                    }else{
                        var imageUrl = urlCreator.createObjectURL(this.response);
                        window.open(imageUrl, "_blank");
                    }
                   
                }
            } 
            request.send();
        } 

        var unsubscribe = $rootScope.$on('trakeyeApp:assetUpdate', function(event, result) {
            vm.asset = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
    
    function myOption(zoom, center) {
        var myOptions;
        return (myOptions = {
            zoom: zoom,
            center: center,
            scaleControl: true,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
                {
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#EFEFEF",
                        },
                    ],
                }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ],
        });
    }
})();
