(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrRouteBundle', TrRouteBundle);

	TrRouteBundle.$inject = ['$resource', 'DateUtils'];

	function TrRouteBundle($resource, DateUtils) {
		var resourceUrl = 'api/tr-routebundles/:id';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update': {
				method: 'PUT'
			},
			'save': {
				method: 'POST',
				interceptor: {
					responseError: function (response) {
						// Handle error here
						console.error('Error:', response);
					}
				}
			},
		});
	}
})();
(function () {
	'use strict';

	angular.module('trakeyeApp').factory('RouteBundleByID', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-routebundles/' + id).then(function (response) {
				callback(response.data);
			}, function (err) {
				callback(err)
			});
		};
		service.postBulkAssets = function (id, data, callback) {
			$http.post('api/v1/bulk-asset-save/' + id, data).then(function (response) {
				callback(response.data);
			}, function (err) {
				callback(err)
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('getConnectedNodes', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.postAdjacency = function (payload, callback) {

			$http.post('api/assets/min-cost-path', payload).then(function (response) {
				callback(response.data);
			});
		};

		service.postSuggestcycles = function (payload, callback) {

			$http.post('api/assets/sngl-edge-nodes', payload).then(function (response) {
				callback(response.data);
			});

		};
		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('assetsByRouteBundleId', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, lastId, callback) {
			$http.get('api/assets-route-bundle-db/' + id + '/' + lastId).then(function (response) {
				callback(response.data);
			}, function (err) {
				callback(err)
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('assetsByRouteGeo', ['$rootScope', '$http', function ($rootScope, $http) {
		var service = {};
		service.get = function (geofenceId, state, district, block, lastId, callback) {
			$http.get('api/assets-route-es-geo/' + geofenceId + '/' + state + '/' + district + '/' + block + '/' + lastId).then(function (response) {
				callback(response.data);
			}, function (err) {
				callback(err)
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('assetsLinking', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.linkAsset = function (callback, errback) {
			$http.get('api/link-assets').then(function (response) {
				callback(response.data);
			}, function (err) {
				callback(err)
			});
		};

		service.postLinkAsset = function (payload, callback, errback) {
			$http.post('api/link-assets', payload).then(function (response) {
				callback(response.data);
			}, function (err) {
				errback(err)
			});
		};

		service.relinkAsset = function (id, callback) {
			$http.get('api/tr-routes/relink-assets/' + id).then(function (response) {
				callback(response);
			}, function (err) {
				callback(err)
			});
		};

		service.assetRings = function (routeId, size, callback) {
			$http.get('api/asset-rings/' + routeId + '/' + size).then(function (response) {
				callback(response.data);
			}, function (err) {
				callback(err)
			});
		};

		return service;
	}]);
})();

(function () {
	"use strict";
	angular.module("trakeyeApp").factory("MappedAssets", MappedAssets);

	MappedAssets.$inject = ["$resource"];

	function MappedAssets($resource) {
		var resourceUrl = "api/mapped-assets/:routeId";

		return $resource(
			resourceUrl,
			{},
			{
				query: {
					method: "GET",
					isArray: true,
				},
			}
		);
	}
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('createSection', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, routeid, callback) {
			$http.get('api/transform-asset/' + id + '/route/' + routeid).then(function (response) {
				callback(response.data);
			}, function (err) {
				callback(err)
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('StateListByGeo', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (geofenceId, callback) {
			$http.get('api/tr-routes/state-list/' + geofenceId).then(function (response) {
				callback(response.data);
			}, function (err) {
				callback(err)
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('DistrictListByStateGeo', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (geofenceId, state, callback) {
			$http.get('api/tr-routes/district-list/' + geofenceId + '/' + state).then(function (response) {
				callback(response.data);
			}, function (err) {
				callback(err)
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('BlockListByStateGeo', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (geofenceId, state, district, callback) {
			$http.get('api/tr-routes/block-list/' + geofenceId + '/' + state + '/' + district).then(function (response) {
				callback(response.data);
			}, function (err) {
				callback(err)
			});
		};

		return service;
	}]);
})();