(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrRoute', TrRoute);

	TrRoute.$inject = ['$resource', 'DateUtils'];

	function TrRoute($resource, DateUtils) {
		var resourceUrl = 'api/tr-routes/:id';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update': {
				method: 'PUT'
			}
		});
	}
})();
(function () {
	'use strict';

	angular.module('trakeyeApp').factory('RouteByID', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-routes/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();
(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteListByProjectId', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-routes-list-by-projectid/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteGeofence', TrRouteGeofence);

	TrRouteGeofence.$inject = ['$resource'];

	function TrRouteGeofence($resource) {
		var service = $resource('/api/tr-routes/geofence/:geofenceId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteCustomTime', TrRouteCustomTime);

	TrRouteCustomTime.$inject = ['$resource'];

	function TrRouteCustomTime($resource) {
		var service = $resource('/api/tr-routes-custom/:fromDate/:toDate', {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteGeofenceCustomTime', TrRouteGeofenceCustomTime);

	TrRouteGeofenceCustomTime.$inject = ['$resource'];

	function TrRouteGeofenceCustomTime($resource) {
		var service = $resource('/api/tr-routes-custom/geofence/:geofenceId/:fromDate/:toDate', {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrRouteCheckAsset', TrRouteCheckAsset);

	TrRouteCheckAsset.$inject = ['$resource', 'DateUtils'];

	function TrRouteCheckAsset($resource, DateUtils) {
		var resourceUrl = 'api/assets/search/:name';

		return $resource(resourceUrl, {}, {
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
		});
	}
})();


(function () {
	'use strict';
	angular.module('trakeyeApp').factory('RoutesForAsset', RoutesForAsset);

	RoutesForAsset.$inject = ['$resource'];

	function RoutesForAsset($resource) {
		var resourceUrl = 'api/routes-for-asset/:assetId';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true
			}
		});
	}
})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-routes/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('getRouteImage', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/trakeyerouteimages/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('getNearbyFixed', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, data, callback) {
			$http.post('api/assets/nearby-fixed/' + id, data).then(function (response) {
				callback(response.data);
			});
		};
		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteAssetsById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-routes-assets/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('RouteAttributeMappingId', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/custom-route-type-mapper/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

/*
(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteById', TrRouteById);

	TrRouteById.$inject = [ '$resource' ];

	function TrRouteById($resource) {
		var service = $resource('/api/tr-routes/:id', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();*/

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteCountByGroup', ['$http', function ($http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/routecountbygroup').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('DownloadRouteReports', ['$http', function ($http) {

		var service = {};
		service.download = function (callback) {
			$http.get('api/download-routes').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteCountByStatus', ['$http', function ($http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/routecountbystatus').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRoutePriority', TrRoutePriority);

	TrRoutePriority.$inject = ['$resource'];

	function TrRoutePriority($resource) {
		var service = $resource('/api/tr-routes/priority/:priority/:geofenceId', {
			priority: '@priorirty'
		}, { geofenceId: '@geofenceId' }, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteAttendance', TrRouteAttendance);

	TrRouteAttendance.$inject = ['$resource'];

	function TrRouteAttendance($resource) {
		var service = $resource('/api/tr-routes/attendance/:login/:fromTime/:toTime/:routeStatus',
			{}, {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteSearch', TrRouteSearch);

	TrRouteSearch.$inject = ['$resource'];

	function TrRouteSearch($resource) {
		var service = $resource('/api/tr-routes/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('RoutePathById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-routes-path/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteListIdName', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (callback) {
			$http.get('api/tr-routes-list').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteSearchCustom', TrRouteSearchCustom);

	TrRouteSearchCustom.$inject = ['$resource'];

	function TrRouteSearchCustom($resource) {
		var service = $resource('/api/tr-routes-custom/searchvalue/:search/:fromDate/:toDate', {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteGeofenceSearch', TrRouteGeofenceSearch);

	TrRouteGeofenceSearch.$inject = ['$resource'];

	function TrRouteGeofenceSearch($resource) {
		var service = $resource('/api/tr-routes/geofence-searchvalue/:geofenceId/:search', {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteGeofenceSearchCustom', TrRouteGeofenceSearchCustom);

	TrRouteGeofenceSearchCustom.$inject = ['$resource'];

	function TrRouteGeofenceSearchCustom($resource) {
		var service = $resource('/api/tr-routes-custom/geofence-searchvalue/:geofenceId/:search/:fromDate/:toDate', {}, {}, {}, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrRouteGroup', TrRouteGroup);

	TrRouteGroup.$inject = ['$resource', 'DateUtils'];

	function TrRouteGroup($resource, DateUtils) {
		var resourceUrl = 'api/tr-routes-group';

		return $resource(resourceUrl, {
			'query': {
				method: 'GET',
				isArray: true
			}
		});
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteGroupSearch', TrRouteGroupSearch);

	TrRouteGroupSearch.$inject = ['$resource'];

	function TrRouteGroupSearch($resource) {
		var service = $resource('/api/tr-routes-group/searchvalue/:search', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteGroupPrioritySearch', TrRouteGroupPrioritySearch);

	TrRouteGroupPrioritySearch.$inject = ['$resource'];

	function TrRouteGroupPrioritySearch($resource) {
		var service = $resource('/api/tr-routes-group/prioritysearch/:priority/:searchtext', {
			priority: '@priorirty',
			searchtext: '@searchtext'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteGroupPriority', TrRouteGroupPriority);

	TrRouteGroupPriority.$inject = ['$resource'];

	function TrRouteGroupPriority($resource) {
		var service = $resource('/api/tr-routes-group/priority/:priority', {
			priority: '@priorirty'
		}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteImages', TrRouteImages);

	TrRouteImages.$inject = ['$resource'];

	function TrRouteImages($resource) {
		var service = $resource('/api/route-images/:routeId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();


(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRoutesForType', TrRoutesForType);

	TrRoutesForType.$inject = ['$resource'];

	function TrRoutesForType($resource) {
		var service = $resource('/api/trroutesbytype/:routeTypeId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: false
			},
		});

		return service;
	}

})();
(function () {
	'use strict';

	angular.module('trakeyeApp').factory('NearByBGOH', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAll = function (data, success, error) {
			$http.post('api/tr-routes/nearby-bgoh-assets', data).then(function (response) {
				success(response.data);
			}, function (response) {
				error(response.status);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteRecordings', TrRouteRecordings);

	TrRouteRecordings.$inject = ['$resource'];

	function TrRouteRecordings($resource) {
		var service = $resource('/api/route-recordings/:routeId', {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('AllRouteTypes', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAll = function (callback) {
			$http.get('api/route-alltypes').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRoutePrioritySearch', TrRoutePrioritySearch);

	TrRoutePrioritySearch.$inject = ['$resource'];

	function TrRoutePrioritySearch($resource) {
		var service = $resource('/api/tr-routes/prioritysearch/:priority/:searchtext', {
			priority: '@priorirty',
			searchtext: '@searchtext'
		}, {
			'query': {
				method: 'GET',
				isArray: true
			},
		});

		return service;
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrRouteUpdateGroup', TrRouteUpdateGroup);

	TrRouteUpdateGroup.$inject = ['$resource'];

	function TrRouteUpdateGroup($resource) {
		var resourceUrl = 'api/tr-routes/groups';

		return $resource(resourceUrl, {}, {
			'update': {
				method: 'PUT'
			}
		});
	}
})();
(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrRouteBulkDelete', TrRouteBulkDelete);

	TrRouteBulkDelete.$inject = ['$resource'];

	function TrRouteBulkDelete($resource) {
		var resourceUrl = 'api/tr-routes/bulk/delete';

		return $resource(resourceUrl, {}, {
			'save': {
				method: 'POST'
			}
		});
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('KmlToCoordinates', KmlToCoordinates);

	KmlToCoordinates.$inject = ['$http'];

	function KmlToCoordinates($http) {

		var service = {};
		service.getLatLng = function (data, callback) {

			$http.post('api/kmltocoordinates', data, {
				transformRequest: angular.identity,
				headers: {
					'Content-Type': undefined
				}
			}).then(function (response) {
				callback(response, response.headers);
			}, function (error) {
				callback(error, error.headers);
			});
		};

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteUser', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.gettrroutesuser = function (callback) {
			$http.get('api/tr-routes/live-logs').then(function (response) {
				callback(response.data);
			});
		};

		service.gettrroutesuserbyid = function (id, callback) {
			$http.get('api/tr-routes/live-logs/' + id).then(function (response) {
				callback(response.data);
			});
		};

		service.searchtrroutesuserbyvalue = function (id, callback) {
			$http.get('api/tr-routes/search/' + id).then(function (response) {
				callback(response.data);
			});
		};
		service.gettrroutesanduserbypriority = function (priority, callback) {
			$http.get('api/tr-routes/live-logs/priority/' + priority).then(function (response) {
				callback(response.data);
			});
		};

		service.getAgents = function (callback) {
			$http.get('api/dashboard/agentdashboard').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteEdit', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getalltrroutes = function (callback) {
			$http.get('api/tr-allroutes').then(function (response) {
				callback(response.data);
			});
		};

		service.edittrroute = function (data, callback) {
			$http.post('api/tr-routeedit/' + data.userId + '/' + data.routeId, {

			}).then(function (response) {

				callback(response, response.headers);
			}, function (error) {

				callback(error, error.headers);
			});

			/*
			 * $http.post('api/tr-routeedit/'+data.userId+'/'+data.routeId).then(function(response){
			 * callback(response.data); });
			 */
		};

		return service;
	}]);
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrRouteUpload', TrRouteUpload);

	TrRouteUpload.$inject = ['$http'];

	function TrRouteUpload($http) {

		var service = {};
		service.trrouteupload = function (data, callback) {

			$http.post('api/routeimport', data, {
				transformRequest: angular.identity,
				headers: {
					'Content-Type': undefined
				}
			}).then(function (response) {

				callback(response, response.headers);
			}, function (error) {
				callback(error, error.headers);
			});
		};

		return service;
	}

})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupRouteSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.searchtrrouteformap = function (search, callback) {
			$http.get('api/tr-grouproutesformap/searchvalue/' + search).then(function (response) {
				callback(response.data);
			});
		};
		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupRouteForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAllRoutes = function (callback) {
			$http.get('api/tr-grouproutesformap').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteSearchForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.searchtrrouteformap = function (search, callback) {
			$http.get('api/tr-routesformap/searchvalue/' + search).then(function (response) {
				callback(response.data);
			});
		};

		service.prioritysearchtrrouteformap = function (priority, callback) {
			$http.get('api/tr-routesformap/priority/' + priority).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();


(function () {
	'use strict';

	angular
		.module('trakeyeApp')
		.factory('TrRouteLastLocation', ['$rootScope', '$http', function ($rootScope, $http) {
			var service = {};

			service.getlastlogs = function (callback) {
				$http.get('api/location-logs/lastuserpath').then(function (response) {
					callback(response.data);
				});
			};
			return service;
		}]);



})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('RoutesForMapByAdmin', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.routesformapbyadmin = function (adminid, callback) {
			$http.get('api/tr-routesformap/' + adminid).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TrRouteForMap', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.getAllRoutes = function (data, callback) {
			// $http.get('api/tr-routesformap').then(function (response) {
			// 	callback(response.data);
			// });
			$http.post('api/tr-routesformap', data).then(function (response) {
				callback(response.data);
			});
		};

		service.getTerrainPathByRoute = function (routeId, callback) {
			$http.get('api/terrain-paths/route/' + routeId).then(function (response) {
				callback(response.data);
			}, function (error) {
				callback(error);
			});
		};

		service.getRoWPathByRoute = function (routeId, callback) {
			$http.get('api/row-paths/route/' + routeId).then(function (response) {
				callback(response.data);
			});
		};

		service.getTerrainByRoute = function (routeId, callback) {
			$http.get('api/tr-terrains-id-name-by-route-id/' + routeId).then(function (response) {
				callback(response.data);
			}, function (error) {
				callback(error);
			});
		};

		service.getRoWByRoute = function (routeId, callback) {
			$http.get('api/tr-rows-id-name-by-route-id/' + routeId).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('ShowRouteById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.update = function (routeid, callback) {
			$http.put('api/show-route/' + routeid)
				.success(function (data, status, headers, config) {
					callback(status);
				})
				.error(function (data, status, header, config) {
					callback(status);
				});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('HideRouteById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.update = function (routeid, callback) {
			$http.put('api/hide-route/' + routeid)
				.success(function (data, status, headers, config) {
					callback(status);
				})
				.error(function (data, status, header, config) {
					callback(status);
				});
		};

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('routeDetail', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};

		//Section
		service.getSection = function (routeId, callback) {
			$http.get('api/tr-sections/route/' + routeId)
				.success(function (response) {
					callback(response);
				})
				.error(function (err) {
					callback(err);
				});
		};


		//Tasks
		service.getTask = function (routeId, callback) {
			$http.get('api/tr-tasks/route/' + routeId)
				.success(function (response) {
					callback(response);
				})
				.error(function (err) {
					callback(err);
				});
		};
		service.taskBySection = function (id, callback) {
			$http.get('api/tr-tasks-by-section-list/' + id).then(function (response) {
				callback(response.data);
			})
		};

		//Section
		service.getRoutesDetails = function (routeId, callback) {
			$http.get('api/tr-routes/detail/' + routeId)
				.success(function (response) {
					callback(response);
				})
				.error(function (err) {
					callback(err);
				});
		};

		service.postSectionsDetails = function (payload, callback) {
			$http.post('api/tr-sections/detail', payload)
				.success(function (response) {
					callback(response);
				})
				.error(function (err) {
					callback(err);
				});
		};
		
		service.getSectionByRoute = function (routeId, callback) {
				            $http.get('api/sectionslength-route/' + routeId)
							.success(function (response) {
								callback(response);
				            })
				            .error(function (err) {
								callback(err);
				            });
				        };
						
		service.getSectionByRouteIdAssetId = function (routeId,assetId, callback) {
				$http.get('api/sectionslength-route/' + routeId+'/'+assetId)
				.success(function (response) {
				callback(response);
				})
				.error(function (err) {
				callback(err);
				});
				};
						
						service.getAssetsBySections = function (sectionId, callback) {
								$http.get('api/spread-details-section/' + sectionId)
									.success(function (response) {
										callback(response);
								     })
								     .error(function (err) {
										callback(err);
								     });
						 };
										
						service.getAssetsCount = function (sectionId, callback) {
								 $http.get('api/asset-count-section/' + sectionId)
										.success(function (response) {
											callback(response);
											})
											.error(function (err) {
											callback(err);
										 });
								};

								service.getAssetsById = function (routeId,lastId, callback) {
										$http.get('api/assets-route-db/' + routeId+'/'+lastId)
											.success(function (response) {
												callback(response);
											})
											.error(function (err) {
												callback(err);
											});
								};
								
								service.getRouteById = function (routeId, callback) {
								       $http.get('api/tr-routes/' + routeId)
								       .success(function (response) {
								              callback(response);
								        })
								       .error(function (err) {
								             callback(err);
								        });
								};
		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('imageRoutePoint', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};

		//Section
		/* service.getCenterLatLng = function (routeId,latitude,longitude,callback) {
			$http.get('api/routepoints/'+routeId+'/'+latitude+'/'+longitude)
			.success(function (response) {
				callback(response);
			})
			.error(function (err) {
				callback(err);
			});
		}; */

		service.postCenterLatLng = function (routeId, payload, callback) {
			$http.post('api/routepoints/' + routeId, payload)
				.success(function (response) {
					callback(response);
				})
				.error(function (err) {
					callback(err);
				});
		};

		service.getImagesList = function (routePointId, callback) {
			$http.get('api/routepoint-images/' + routePointId)
				.success(function (response) {
					callback(response);
				})
				.error(function (err) {
					callback(err);
				});
		};

		service.openImage = function (routePointId, imgid, callback) {
			$http.get('api/routepoint-images/' + routePointId + '/' + imgid)
				.success(function (response) {
					callback(response);
				})
				.error(function (err) {
					callback(err);
				});
		};

		service.getUserTaskLogImages = function (logId, callback) {
			$http.get('api/rollout/user-task-log-images/' + logId)
				.success(function (response) {
					callback(response);
				})
				.error(function (err) {
					callback(err);
				});
		};

		/* service.openLogImage = function (logId,imgId,callback) {
			$http.get('api/rollout/user-task-log-images/'+logId+'/'+imgId)
			.success(function (response) {
				callback(response);
			})
			.error(function (err) {
				callback(err);
			});
		}; */

		return service;
	}]);
})();

(function () {
	'use strict';

	angular.module('trakeyeApp').factory('TaskRouteById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/tr-routes-path/' + id).then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('dailyLogByTaskId', dailyLogByTaskId);

	dailyLogByTaskId.$inject = ['$resource'];

	function dailyLogByTaskId($resource) {
		var resourceUrl = 'api/rollout/get-daily-task-logs/:id/type/OBSTACLE'

		return $resource(resourceUrl, {}, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update': {
				method: 'PUT'
			}
		});
	}
})();
(function () {
	'use strict';

	angular.module('trakeyeApp').factory('RouteAssetsById', ['$rootScope', '$http', function ($rootScope, $http) {

		var service = {};
		service.get = function (id, callback) {
			$http.get('api/assets-route-es/' + id + '/null').then(function (response) {
				callback(response.data);
			});
		};

		return service;
	}]);
})();
(function () {
	'use strict';
	angular.module('trakeyeApp').factory('dailyLog', dailyLog);

	dailyLog.$inject = ['$resource', 'DateUtils'];

	function dailyLog($resource, DateUtils) {
		var resourceUrl = 'api/rollout/get-daily-task-logs/:id';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update': {
				method: 'PUT'
			}
		});
	}
})();
(function () {
	'use strict';

	angular.module('trakeyeApp').factory('taskComment', ['$http', function ($http) {

		var service = {};

		service.getTaskLogsPaths = function (taskId, callback) {
			$http.get('api/rollout/daily-task-log-path/task/' + taskId).then(function (response) {
				callback(response.data);
			}).catch(function (err) {
				callback(err)
			});

		};
		return service;
	}]);
})();