(function () {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrRouteDetailController', TrRouteDetailController);

    TrRouteDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TrRoute', 'User', 'RouteType', 'TrRouteImages', 'TrRouteRecordings', 'AlertService', '$window', '$localStorage', 'getRouteImage', 'Configs', 'routeDetail', 'Inventory', 'SummaryPost', 'imageRoutePoint', 'TrRouteForMap', 'TaskLogRouteReportData', 'MaterialByDates', 'MaterialDates', 'ParseLinks', 'RouteByID', 'Report', 'dailyLogByTaskId', 'RouteAssetsById', 'TaskSectionById', 'TaskStatusById', 'taskComment', 'dailyLog', 'BaseUrl', 'DailyLogByTaskIdWithType'];

    function TrRouteDetailController($scope, $rootScope, $stateParams, previousState, entity, TrRoute, User, RouteType, TrRouteImages, TrRouteRecordings, AlertService, $window, $localStorage, getRouteImage, Configs, routeDetail, Inventory, SummaryPost, imageRoutePoint, TrRouteForMap, TaskLogRouteReportData, MaterialByDates, MaterialDates, ParseLinks, RouteByID, Report, dailyLogByTaskId, RouteAssetsById, TaskSectionById, TaskStatusById, taskComment, dailyLog, BaseUrl, DailyLogByTaskIdWithType) {
        var vm = this;

        vm.trRoute = entity;
        vm.previousState = previousState.name;
        vm.decodeImage = decodeImage;
        vm.showTaskLogReport = showTaskLogReport;
        vm.downloadRouteKml = downloadRouteKml;
        vm.downloadSurveyReport1= downloadSurveyReport1;
        var unsubscribe = $rootScope.$on('trakeyeApp:trRouteUpdate', function (event, result) {
            vm.trRoute = result;
        });
        vm.showRouteAttachment = false
        vm.expandResponse = []
        vm.storeMaterialTransactionName = []
        vm.activeTab = 'material';
        var colors = [];
		loadAssets(vm.trRoute.id, null);
        var infowindow = new google.maps.InfoWindow();
        var openedInfoWindow = null;

        var uniqueColors = [
            "#FF0000", "#0000FF", "#008000", "#FFFF00", "#FFA500", "#800080", "#FFC0CB", "#00FFFF", "#FF00FF",
            "#008080", "#E6E6FA", "#A52A2A", "#800000", "#000080", "#808000", "#00FF00", "#FFDAB9", "#000000",
            "#808080", "#C0C0C0", "#FFD700", "#4B0082", "#EE82EE", "#40E0D0", "#D2B48C", "#FA8072", "#FF7F50",
            "#F0E68C", "#DC143C", "#7CFC00"
        ];
        var phasePolylines = []
        var phaseMarkers = []
        var coordinateListMaintenance = []
        var coordinateListRollout = []
        var coordinateListPlanSurvey = []
        var coordinateListPlanning = []
        var assetSpreadListPlanning = []
        var assetSpreadListMaintenance = []
        var assetSpreadListRollout = []
        var assetSpreadListPlanSurvey = []

        //measure tool
        var measureEnabled = false
        var polyMeasureTool, segmentDistance, midpoint
        var totalDistance = 0
        var distanceMarkers = []
        var totalDistanceMarkers = []
        var pointerMarkers = []
        var arrpolyMeasureTool = []
        vm.gotoLatitute = gotoLatitute;
        vm.gotoLatituteCity = gotoLatituteCity;
        vm.closeGotoLatitute = closeGotoLatitute;
        vm.closeGotoLatituteCity = closeGotoLatituteCity;
        vm.showZoomLocation = showZoomLocation;
        var gotoMarker = null;
        var measureTool = document.createElement("button");
        var measureToolIcon = document.createElement("i");

        var colors = uniqueColors.map(function (color) {
            return { color: color, used: false };
        });

        Configs.get("RouteImage", function (response) {
            if (response.length > 0) {
                if (response[0].configValue == "True") {
                    vm.showRouteAttachment = true
                }
            }
        })

        vm.imageInNewTab = function (img) {
            var imgWindow = window.open();
            imgWindow.document.write(
                '<img width="25%"   src="data:image/jpeg;base64,' +
                img +
                '">'
            );
            imgWindow.document.close();
        };

        function downloadRouteKml() {
            Report.downloadRouteKml({
                id: vm.trRoute.id
            }, function (response) {
                var a = document.createElement("a");
                a.style = "display: none";
                var file = new Blob([(response)], { type: 'application/text' });
                var fileURL = URL.createObjectURL(file);
                a.href = fileURL;
                a.download = vm.trRoute.name + ".kml";
                a.click();
                window.URL.revokeObjectURL(fileURL);
            });
        }

        function downloadSurveyReport1() {
            BaseUrl.getBaseUrl(function (baseurl) {
                var url = "";
                url = baseurl.stringValue + "api/route-survey-report1/" + vm.trRoute.id;
                var request = new XMLHttpRequest();
                request.open('GET', url, true);
                request.setRequestHeader('Authorization', 'Bearer ' + $localStorage.authenticationToken);
                request.responseType = 'blob';

                request.onload = function (e) {
                    if (this.status === 200) {
                        var blob = this.response;
                        if (blob.size > 0) {
                            if (window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveBlob(blob, 'test.xlsx');
                            }
                            else {
                                var downloadLink = window.document.createElement('a');
                                var contentTypeHeader = request.getResponseHeader("Content-Type");
                                downloadLink.href = window.URL.createObjectURL(new Blob([blob], {
                                    type: contentTypeHeader
                                }));
                                downloadLink.download = 'Report.xlsx';

                                downloadLink.download = 'SurveyReport.xlsx';

                                document.body.appendChild(downloadLink);
                                downloadLink.click();
                                document.body.removeChild(downloadLink);
                            }
                        }
                    }else if(this.status == 404){alert("No Data Available")}
                };
                request.send();
            });
        }

        /* $scope.newTabDisplay = function(id,imgid){
            var request = new XMLHttpRequest()
                
                request.open(
                        "GET",
                        'api/routepoint-images/'+id+'/'+imgid,
                        true
                );
                
                request.setRequestHeader(
                    "Authorization",
                    "Bearer " + $localStorage.authenticationToken
                );
                request.responseType = "blob";
                request.onload = response;
    
                function response(e) {
                    if (this.status === 200) {
                        var urlCreator = window.URL || window.webkitURL;
                        var imageUrl = urlCreator.createObjectURL(this.response);
                        window.open(imageUrl, "_blank");
                    }
                }
                request.send();
        }  */

        $scope.$on('$destroy', unsubscribe);

        $scope.go_back = function () {
            $window.history.back();
        };

        $scope.allimages = false;
        //		$scope

        $scope.toggle = function (id) {
            switch (id) {
                case "image":
                    $scope.allimages = !$scope.allimages;
                    vm.showImages = !vm.showImages;
                    if ($scope.allimages) {
                        TrRouteImages.query(
                            {
                                routeId: vm.trRoute.id,
                            },
                            onSuccess,
                            onError
                        );

                        function onSuccess(data, headers) {
                            // console.log('Recived data-----------');

                            vm.routeImages = data.filter(function (routeImages) {
                                return routeImages.image
                            });

                        }

                        function onError(error) {
                            AlertService.error(error.data.message);
                        }
                    }
                    break;

                case "termination":
                    $scope.terminationDetails = !$scope.terminationDetails;
                    vm.showTermination = !vm.showTermination;
                    break;

                case "fms":
                    $scope.fmsDetails = !$scope.fmsDetails;
                    vm.showFMS = !vm.showFMS;
                    break;

                case "splicing":
                    $scope.splicingDetails = !$scope.splicingDetails;
                    vm.showSplicing = !vm.showSplicing;
                    break;

                case "cable":
                    $scope.cableDetails = !$scope.cableDetails;
                    vm.showCable = !vm.showCable;
                    break;

                case "pdf":
                    $scope.allpdf = !$scope.allpdf;
                    vm.showPdf = !vm.showPdf;
                    if ($scope.allpdf) {
                        getRouteImage.get(vm.trRoute.id, function (res) {
                            vm.attachments = res;
                        });
                    }

                    break;

                default:
                    console.log("");
            }



			/*
			
			$scope.allimages = !$scope.allimages;
			
			var audioTag = document.createElement("AUDIO");
			audioTag.setAttribute("src","http://us-east-1.linodeobjects.com/trakeye-media-files/route_"+vm.trRoute.id+".mp3");
			audioTag.setAttribute("controls", "controls");
			audioTag.setAttribute("controlsList", "nodownload");

			var mydiv = document.getElementById("audioLayout");
			
			if ($scope.allimages) {
				TrRouteImages.query({
					routeId : vm.trRoute.id

				}, onSuccess, onError);

				function onSuccess(data, headers) {
					// console.log('Recived data-----------');
					vm.routeImages = data;
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}
				var mydivChild = document.createElement('div');
				mydivChild.setAttribute("id", "audioChild");
				mydiv.appendChild(mydivChild);
				mydivChild.appendChild(audioTag);
			}else{
				var mydivChild = document.getElementById("audioChild");
				mydivChild.remove();
			}
		*/};

        //		$scope.playAudio = function() {
        ////			var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/route_"+vm.trRoute.id+".mp3";
        //            var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/route_15570572.mp3";
        //	        var audio = new Audio(urlStr);
        //	        audio.play();
        //	    };


        function decodeImage(img) {
            return window.atob(img);
        }

        $scope.tab = 1;

        $scope.isSet = function (tabNum) {
            return $scope.tab === tabNum;
        };


        $scope.setTab = function (newTab) {

            if (newTab == 8) {
                //Comment Box
                vm.objectComments = {}
                vm.objectComments.entityId = entity.id
                vm.objectComments.entityName = "ROUTE"
            }

            if (newTab == 4 && vm.dailyLogsTxSummary.sectionTasks.length) {
                $scope.tab = 7
                showTaskLogReport(vm.trRoute.id);
            } else {
                $scope.tab = newTab;
            }
        }


        /*  routeDetail.getSection(entity.id,function(response){
             vm.sectionResponse = response
         })
          */
        routeDetail.getRoutesDetails(entity.id, function (response) {
            vm.sectionResponse = response;
        })
        var locations = {};
        var markers = [];
        var coordinateList = []
        var chainageList = []
        var markerList = []
        var iconSize = 24
        var center = {
            lat: parseFloat($rootScope.DEFAULT_LATITUDE),
            lng: parseFloat($rootScope.DEFAULT_LONGITUDE),
        };

        locations.map = new google.maps.Map(
            document.getElementById("map_canvasStart"),
            myOption($rootScope.rolloutMapInitZoom, center)
        );
        geoLocation(locations.map)
        function templateBg(color) {
            return '<img class="square" style="background-color:' +
                color +
                ';">'
        }

        vm.phaseFilter = []
        vm.blockFilter = []
        var tempColor = {
            "PLANNING": "#FD7A24",
            "SURVEY": "#0096FF",
            "ROLLOUT": "#7FFF00",
            "MAINTENANCE": "#DE3163"
        }

        var isObjBool = {
            "PLANNING": false,
            "SURVEY": false,
            "ROLLOUT": false,
            "MAINTENANCE": false
        }




        if (vm.trRoute && vm.trRoute.routePoints != null && vm.trRoute.routePoints.length) {
            vm.trRoute.routePoints.sort(function (a, b) { return a.routePointNumberDouble - b.routePointNumberDouble });
            var groupedRoutePoints = {};
            vm.trRoute.routePoints.forEach(function (each) {

                if (each.routePointType == "COORDINATE" && each.phase != null) {

                    if (each.sessionNumber) {
                        if (!groupedRoutePoints[each.sessionNumber]) {
                            groupedRoutePoints[each.sessionNumber] = [];
                        }
                        groupedRoutePoints[each.sessionNumber].push(each);
                    }
                } else if (each.routePointType == "CHAINAGE" && each.phase != null) {
                    isObjBool[each.phase] = true;
                    chainageList.push({ latitude: each.latitude, longitude: each.longitude, type: each.routePointType, number: each.routePointNumber, phase: each.phase });
                }
            });
            Object.keys(groupedRoutePoints).forEach(function (sessionNumber) {
                var sessionPoints = groupedRoutePoints[sessionNumber];
                sessionPoints.sort(function (a, b) { return a.routePointNumberDouble - b.routePointNumberDouble });
                if (sessionPoints.length > 1) {
                    sessionPoints.forEach(function (each) {
                        callSwitchPhaseCoordinate(each, each.phase);
                        isObjBool[each.phase] = true;
                        var coordinate = { lat: each.latitude, lng: each.longitude };
                        if (each.phase === "PLANNING") {
                            coordinateListPlanning.push(coordinate);
                        } else if (each.phase === "SURVEY") {
                            coordinateListPlanSurvey.push(coordinate);
                        } else if (each.phase === "ROLLOUT") {
                            coordinateListRollout.push(coordinate);
                        } else if (each.phase === "MAINTENANCE") {
                            coordinateListMaintenance.push(coordinate);
                        }
                    });
                    callPhaseCoordinate();
                    coordinateListPlanning = [];
                    coordinateListPlanSurvey = [];
                    coordinateListRollout = [];
                    coordinateListMaintenance = [];
                }
            });
            callPhaseCoordinate();
            if (chainageList.length) {

                locations.map.setCenter(chainageList[0]);
                createMarkers(chainageList, 'content/images/chainagePoints.png', locations.map, null);
            }
            callObjPhases();
        }

        function callObjPhases() {
            if (isObjBool["PLANNING"]) {
                vm.phaseFilter.push({ name: "PLANNING", selected: true, strokeColor: templateBg("#FD7A24") })
            }

            if (isObjBool["SURVEY"]) {
                vm.phaseFilter.push({ name: "SURVEY", selected: true, strokeColor: templateBg("#0096FF") })

            }

            // if (isObjBool["ROLLOUT"]) {
            //     vm.phaseFilter.push({ name: "ROLLOUT", selected: true, strokeColor: templateBg("#7FFF00"), color: "#7FFF00" })

            // }

            // if (isObjBool["MAINTENANCE"]) {
            //     vm.phaseFilter.push({ name: "MAINTENANCE", selected: true, strokeColor: templateBg("#DE3163"), color: "#DE3163" })

            // }
        }

        function callPhaseCoordinate() {
            if (coordinateListPlanning.length) {

                locations.map.setCenter(coordinateListPlanning[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateListPlanning,
                    geodesic: true,
                    strokeColor: tempColor["PLANNING"],
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                    name: "PLANNING"
                });

                coordinateListPlanning.forEach(function (each) {
                    createInfoWindow(polyline, each)
                })

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }

            if (coordinateListPlanSurvey.length) {
                //vm.phaseFilter.push({name:"PROPOSED",selected:true,strokeColor:templateBg("#0096FF")})

                locations.map.setCenter(coordinateListPlanSurvey[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateListPlanSurvey,
                    geodesic: true,
                    strokeColor: tempColor["SURVEY"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "SURVEY"
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }

            if (coordinateListRollout.length) {
                //vm.phaseFilter.push({name:"ROLLOUT",selected:true,strokeColor:templateBg("#7FFF00"), color:"#7FFF00"})

                locations.map.setCenter(coordinateListRollout[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateListRollout,
                    geodesic: true,
                    strokeColor: tempColor["ROLLOUT"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "ROLLOUT"
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }

            if (coordinateListMaintenance.length) {
                //vm.phaseFilter.push({name:"MAINTENANCE",selected:true,strokeColor:templateBg("#DE3163"), color :"#DE3163"})

                locations.map.setCenter(coordinateListMaintenance[0]);
                var polyline = new google.maps.Polyline({
                    path: coordinateListMaintenance,
                    geodesic: true,
                    strokeColor: tempColor["MAINTENANCE"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "MAINTENANCE"
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }
        }
        function callPhaseSpread() {
            if (assetSpreadListPlanning.length) {
                //vm.phaseFilter.push({name:"PLANNING",selected:true,strokeColor:templateBg("#FD7A24")})
                locations.map.setCenter(assetSpreadListPlanning[0]);

                var polyline = new google.maps.Polyline({
                    path: assetSpreadListPlanning,
                    geodesic: true,
                    strokeColor: tempColor["PLANNING"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "PLANNING"
                });

                // Set the map to display the polyline
                assetSpreadListPlanning.forEach(function (each) {
                    createInfoWindow(polyline, each)
                    polyline.set('block', each.block);
                })
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }


            if (assetSpreadListPlanSurvey.length) {
                //vm.phaseFilter.push({name:"PROPOSED",selected:true,strokeColor:templateBg("#0096FF")})

                locations.map.setCenter(assetSpreadListPlanSurvey[0]);
                var polyline = new google.maps.Polyline({
                    path: assetSpreadListPlanSurvey,
                    geodesic: true,
                    strokeColor: tempColor["SURVEY"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "SURVEY"
                });

                assetSpreadListPlanSurvey.forEach(function (each) {
                    createInfoWindow(polyline, each)
                    polyline.set('block', each.block);
                })
                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }

            if (assetSpreadListRollout.length) {
                //vm.phaseFilter.push({name:"ROLLOUT",selected:true,strokeColor:templateBg("#7FFF00"), color:"#7FFF00"})

                locations.map.setCenter(assetSpreadListRollout[0]);
                var polyline = new google.maps.Polyline({
                    path: assetSpreadListRollout,
                    geodesic: true,
                    strokeColor: tempColor["ROLLOUT"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "ROLLOUT"
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }

            if (assetSpreadListMaintenance.length) {
                //vm.phaseFilter.push({name:"MAINTENANCE",selected:true,strokeColor:templateBg("#DE3163"), color :"#DE3163"})

                locations.map.setCenter(assetSpreadListMaintenance[0]);
                var polyline = new google.maps.Polyline({
                    path: assetSpreadListMaintenance,
                    geodesic: true,
                    strokeColor: tempColor["MAINTENANCE"],
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    name: "MAINTENANCE"
                });

                // Set the map to display the polyline
                polyline.setMap(locations.map);
                phasePolylines.push(polyline)
            }
        }

        function callSwitchPhaseCoordinate(each, ph) {
            switch (ph) {
                case "PLANNING":
                    coordinateListPlanning.push({ lat: each.latitude, lng: each.longitude, phase: each.phase })
                    break;

                case "SURVEY":
                    coordinateListPlanSurvey.push({ lat: each.latitude, lng: each.longitude, phase: each.phase })
                    break;

                case "ROLLOUT":
                    coordinateListRollout.push({ lat: each.latitude, lng: each.longitude, phase: each.phase })
                    break;

                case "MAINTENANCE":
                    coordinateListMaintenance.push({ lat: each.latitude, lng: each.longitude, phase: each.phase })
                    break;
            }
        }

        function callSwitchPhaseSpread(each, ph, name, block) {
            switch (ph) {
                case "PLANNING":
                    assetSpreadListPlanning.push({ lat: each.latitude, lng: each.longitude, phase: each.phase, name: name, block: block })
                    break;

                case "SURVEY":
                    assetSpreadListPlanSurvey.push({ lat: each.latitude, lng: each.longitude, phase: each.phase, name: name, block: block })
                    break;

                case "ROLLOUT":
                    assetSpreadListRollout.push({ lat: each.latitude, lng: each.longitude, phase: each.phase, block: block })
                    break;

                case "MAINTENANCE":
                    assetSpreadListMaintenance.push({ lat: each.latitude, lng: each.longitude, phase: each.phase, block: block })
                    break;
            }
        }

        $scope.togglePhase = function () {
            if (vm.selectedPhase.length) {
                clearPhase()
                vm.selectedPhase.forEach(function (each) {
                    for (var i = 0; i < phasePolylines.length; i++) {
                        if (phasePolylines[i].name === each.name) {
                            if (each.selected) {
                                phasePolylines[i].setMap(locations.map);
                            }
                        }
                    }
                    for (var i = 0; i < phaseMarkers.length; i++) {
                        if (phaseMarkers[i].name === each.name) {
                            if (each.selected) {
                                phaseMarkers[i].setMap(locations.map);
                            }
                        }
                    }
                })
            } else {
                clearPhase()
            }
        };

        $scope.toggleBlock = function () {
            if (vm.selectedBlock.length) {
                clearPhase()
                vm.selectedBlock.forEach(function (each) {
                    for (var i = 0; i < phasePolylines.length; i++) {
                        if (phasePolylines[i].block === each.name /* || phasePolylines[i].block == null */) {
                            if (each.selected) {
                                phasePolylines[i].setMap(locations.map);
                            }
                        }
                    }
                    for (var i = 0; i < phaseMarkers.length; i++) {
                        if (phaseMarkers[i].block === each.name /* || phasePolylines[i].block == null */) {
                            if (each.selected) {
                                phaseMarkers[i].setMap(locations.map);
                            }
                        }
                    }
                })
            } else {
                clearPhase()
                vm.phaseFilter.forEach(function (item) {
                    item.selected = false
                })
            }
        };

        function createMarkers(arr, img, map, str) {
            var marker, markerSymbol
            arr.forEach(function (each) {

                marker = new google.maps.Marker({
                    position: { lat: each.latitude, lng: each.longitude },
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: img,
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            iconSize / 2
                        ),
                        labelOrigin: new google.maps.Point(25, 22),
                    },
                    name: each.phase
                });

                google.maps.event.addListener(
                    marker,
                    "click",
                    (function () {
                        //var name = (each.name == (undefined || null)) ? " " : each.name
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        console.log(each);
                        infowindow.setContent(
                            '<div class="infoWindowhead"></br></br>' +
                            /* "<b>Name : "+name+"</b></br>"+ */
                            "<b>Latitude : " + each.latitude + "</b></br>" +
                            "<b>Longitude : " + each.longitude + "</b></br>" +
                            "<b>Type : " + each.type + "</b></br>" +
                            "<b>Number : " + each.number + "</b></br>" +
                            "</br></div>"
                        );
                        infowindow.open(map);
                        infowindow.setPosition({
                            lat: each.latitude,
                            lng: each.longitude,
                        });
                        openedInfoWindow = infowindow;
                    }))

                /*   markerSymbol = new google.maps.Marker({
                     icon:  {
                         path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                         strokeColor: tempColor[each.phase],
                         fillColor: tempColor[each.phase],
                         fillOpacity:1,
                         scale: 1,
                         labelOrigin: new google.maps.Point(-1, 7)
                     },
                     map: locations.map,
                     position:  { lat: each.latitude, lng: each.longitude },
                     name:each.phase
                 });  */
                phaseMarkers.push(marker);
                //phaseMarkers.push(markerSymbol);


            })
        }

        function mapStyles() {
            var styles;
            return (styles = [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                } /* 
            {
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#EFEFEF",
                    },
                ],
            }, */,
                {
                    featureType: "transit",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            color: "#63666A",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "labels.icon",
                    stylers: [{ visibility: "off" }],
                },
                {
                    featureType: "landscape",
                    stylers: [
                        {
                            color: "#efefef",
                        },
                    ],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
            ]);
        }

        function myOption(zoom, center) {
            var myOptions;
            return (myOptions = {
                zoom: zoom,
                center: center,
                scaleControl: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: mapStyles(),
            });
        }

        /* vm.expand = function(section,str) {
            vm.sectionResponse.forEach(function (item) {
                item.material = false;
                item.tasks = false;
                item.resource = false;
            });
            
            if (vm.toggle == section.id) {
                vm.toggle = undefined;
            } else {
                switch(str){
                    case "material":
                        vm.expandResponse = null
                        vm.headers = null
                        Inventory.materialTxn(section.id,function(response){
                            vm.headers = [ 
                            { key: "materialName", display: "Material Name" },
                            { key: "noOfUnits", display: "No of Units" },
                            { key: "transactionType", display: "Transaction Type" },
                            { key: "transactionDate", display: "Transaction Date" },
                        ]
                            vm.expandResponse = response
                        }) 
                        section.material = true;
                        vm.toggle = section.id;
                        break;
        
                    case "resource":
                        vm.expandResponse = null
                        vm.headers = null
                        Inventory.resourceTxn(section.id,function(response){
                            vm.headers = [ 
                                { key: "resourceName", display: "Resource Name" },
                                { key: "noOfResources", display: "No of Resource" },
                                { key: "transactionType", display: "Transaction Type" },
                                { key: "transactionDate", display: "Transaction Date" },
                            ]
                            vm.expandResponse = response
                        })
                        section.resource = true;
                        vm.toggle = section.id;
                        break;
        
                    case "tasks":
                        vm.expandResponse = null
                        vm.headers = null
                        Inventory.taskBySection(section.id,function(response){
                            vm.headers = [ 
                                { key: "name", display: "Name" },
                                { key: "taskType", display: "Task Type" }
                            ]
                            vm.expandResponse = response
                        })
                        section.tasks = true;
                        vm.toggle = section.id;
                         break; 
                    
                    default : console.log("default")
                   }
             }
        } */


        vm.expand = function (section, str) {
            vm.sectionResponse.forEach(function (item) {
                item.tasks = true;
            });

            if (vm.toggle == section.sectionId) {
                vm.toggle = undefined;
            } else {
                switch (str) {
                    case "tasks":
                        vm.expandResponse = null
                        vm.headers = null
                        routeDetail.postSectionsDetails({ "sectionIds": [section.sectionId] }, function (response) {
                            vm.headers = [
                                { key: "logs", display: "Logs" },
                                { key: "taskName", display: "Task Name" },
                                { key: "userLogin", display: "Assigned To" }
                            ]
                            vm.expandResponse = response
                        })
                        section.tasks = true;
                        vm.toggle = section.sectionId;
                        break;

                    default: console.log("default")
                }
            }
        }


        SummaryPost.dailyLogsTx({
            "projectIds": [vm.trRoute.projectId],
            "routeIds": [vm.trRoute.id]
        }, function (response) {
            vm.dailyLogsTxSummary = response
        })

        SummaryPost.mtrlTx({
            "projectIds": [vm.trRoute.projectId],
            "routeIds": [vm.trRoute.id]
        }, function (response) {
            vm.mtrlTxSummary = response
        })

        SummaryPost.resTx({
            "projectIds": [vm.trRoute.projectId],
            "routeIds": [vm.trRoute.id]
        }, function (response) {
            vm.resTxSummary = response
        })

        vm.objectRouteImg = {}
        vm.objectRouteImg.hidemarker = false
        vm.objectRouteImg.routeId = vm.trRoute.id
        vm.objectRouteImg.map = locations.map

        vm.mapFilterList = [];
        var polylines = []
        var tempPath = []

        TrRouteForMap.getTerrainPathByRoute(
            vm.trRoute.id,
            function (response) {
                vm.terrainPathResponse = response

                callTerrainPath()

            }, function (err) {
                vm.mapFilterList.push({
                    name:
                        '<strong>' + vm.trRoute.name + '</strong>',
                    path: coordinateList,
                    visible: true,
                    strokeColor: '<img class="square" style="background-color:#' +
                        'FD7A24' +
                        ';">',
                    colorCode: 'FD7A24',
                    routeDisable: true
                })

                for (var i = 0; i < vm.mapFilterList.length; i++) {
                    var line = new google.maps.Polyline({
                        path: vm.mapFilterList[i].path,
                        visible: vm.mapFilterList[i].visible,
                        strokeColor: '#' + vm.mapFilterList[i].colorCode,
                        strokeOpacity: 1.0,
                        strokeWeight: 4,
                        name: vm.mapFilterList[i].name
                    });
                    line.setMap(locations.map);
                    polylines.push(line);
                }

                TrRouteForMap.getRoWPathByRoute(
                    vm.trRoute.id,
                    function (response) {
                        vm.rowPathResponse = response
                        if (response.length) {
                            vm.mapFilterList.push({ name: '<strong>RoW</strong>', msGroup: true })
                            callRowPath()
                        }

                    }
                );
            }
        );

        function callTerrainPath() {

            TrRouteForMap.getTerrainByRoute(
                vm.trRoute.id,
                function (nameResponse) {
                    polylines = []
                    vm.terrainListResponse = nameResponse
                    vm.totalTerrain = 0;
                    vm.terrainListResponse.forEach(function (terrain) {
                        vm.totalTerrain += terrain.length;
                    });
                    if (nameResponse.length) {
                        vm.isShowFilter = true
                        vm.mapFilterList.push({
                            name:
                                '<strong>' + vm.trRoute.name + '</strong>',
                            path: coordinateList,
                            visible: true,
                            strokeColor: '<img class="square" style="background-color:#' +
                                'FD7A24' +
                                ';">',
                            colorCode: 'FD7A24',
                            routeDisable: true,

                        })
                        vm.mapFilterList.push({ name: '<strong>Terrain</strong>', msGroup: true })
                        nameResponse.forEach(function (nameEach, indexName) {
                            if (vm.terrainPathResponse.length) {
                                vm.terrainPathResponse.forEach(function (item, indexPath) {
                                    if (nameEach.id == item.id) {
                                        if (
                                            item.value["COORDINATE"]
                                        ) {
                                            item.value["COORDINATE"].forEach(function (cord) {
                                                tempPath.push({ lat: cord.latitude, lng: cord.longitude })
                                            })

                                            var colorCode = randomColor()
                                            vm.mapFilterList.push({
                                                name:
                                                    nameEach.name,
                                                path: tempPath,
                                                visible: true,
                                                strokeColor: '<img class="square" style="background-color:' +
                                                    colorCode +
                                                    ';">',
                                                colorCode: colorCode
                                            });
                                            tempPath = []
                                        }
                                    }
                                });
                            }

                            // for (var i = 0; i < vm.mapFilterList.length; i++) {
                            //     var line = new google.maps.Polyline({
                            //         path: vm.mapFilterList[i].path,
                            //         visible: vm.mapFilterList[i].visible,
                            //         strokeColor: '#' + vm.mapFilterList[i].colorCode,
                            //         strokeOpacity: 1.0,
                            //         strokeWeight: 4,
                            //         name: vm.mapFilterList[i].name
                            //     });
                            //     line.setMap(locations.map);
                            //     polylines.push(line);
                            // }
                        });
                        vm.mapFilterList.push({ msGroup: false })
                        TrRouteForMap.getRoWPathByRoute(
                            vm.trRoute.id,
                            function (response) {
                                vm.rowPathResponse = response
                                // if (response.length) {
                                //     vm.mapFilterList.push({ name: '<strong>RoW</strong>', msGroup: true })
                                callRowPath()
                                // }

                            }
                        );
                    } else {
                        vm.mapFilterList.push({
                            name:
                                '<strong>' + vm.trRoute.name + '</strong>',
                            path: coordinateList,
                            visible: true,
                            strokeColor: '<img class="square" style="background-color:#' +
                                'FD7A24' +
                                ';">',
                            colorCode: 'FD7A24',
                            routeDisable: true
                        })

                        for (var i = 0; i < vm.mapFilterList.length; i++) {
                            var line = new google.maps.Polyline({
                                path: vm.mapFilterList[i].path,
                                visible: vm.mapFilterList[i].visible,
                                strokeColor: '#' + vm.mapFilterList[i].colorCode,
                                strokeOpacity: 1.0,
                                strokeWeight: 4,
                                name: vm.mapFilterList[i].name
                            });
                            line.setMap(locations.map);
                            polylines.push(line);
                        }

                        TrRouteForMap.getRoWPathByRoute(
                            vm.trRoute.id,
                            function (response) {
                                vm.rowPathResponse = response
                                if (response.length) {
                                    vm.mapFilterList.push({ name: '<strong>RoW</strong>', msGroup: true })
                                    callRowPath()
                                }

                            }
                        );
                    }
                },
                function (error) {
                    vm.mapFilterList.push({
                        name:
                            '<strong>' + vm.trRoute.name + '</strong>',
                        path: coordinateList,
                        visible: true,
                        strokeColor: '<img class="square" style="background-color:#' +
                            'FD7A24' +
                            ';">',
                        colorCode: 'FD7A24',
                        routeDisable: true
                    })

                    for (var i = 0; i < vm.mapFilterList.length; i++) {
                        var line = new google.maps.Polyline({
                            path: vm.mapFilterList[i].path,
                            visible: vm.mapFilterList[i].visible,
                            strokeColor: '#' + vm.mapFilterList[i].colorCode,
                            strokeOpacity: 1.0,
                            strokeWeight: 4,
                            name: vm.mapFilterList[i].name
                        });
                        line.setMap(locations.map);
                        // polylines.push(line);
                    }

                    TrRouteForMap.getRoWPathByRoute(
                        vm.trRoute.id,
                        function (response) {
                            vm.rowPathResponse = response
                            if (response.length) {
                                vm.mapFilterList.push({ name: '<strong>RoW</strong>', msGroup: true })
                                callRowPath()
                            }

                        }
                    );
                }
            );
        }
        function callRowPath() {

            TrRouteForMap.getRoWByRoute(
                vm.trRoute.id,
                function (nameResponse) {
                    vm.rowListResponse = nameResponse
                    vm.totalRow = 0;
                    vm.rowListResponse.forEach(function (row) {
                        vm.totalRow += row.length;
                    });
                    if (nameResponse.length) {
                        nameResponse.forEach(function (nameEach, indexName) {
                            if (vm.rowPathResponse.length) {
                                vm.rowPathResponse.forEach(function (item, indexPath) {
                                    if (nameEach.id == item.id) {
                                        if (
                                            item.value["COORDINATE"]
                                        ) {
                                            item.value["COORDINATE"].forEach(function (cord) {
                                                tempPath.push({ lat: cord.latitude, lng: cord.longitude })
                                            })

                                            var colorCode = randomColor()
                                            vm.mapFilterList.push({
                                                name:
                                                    nameEach.name,
                                                path: tempPath,
                                                visible: true,
                                                strokeColor: '<img class="square" style="background-color:' +
                                                    colorCode +
                                                    ';">',
                                                colorCode: colorCode
                                            });
                                            tempPath = []
                                        }
                                    }
                                });
                            }

                            // for (var i = 0; i < vm.mapFilterList.length; i++) {
                            //     var line = new google.maps.Polyline({
                            //         path: vm.mapFilterList[i].path,
                            //         visible: vm.mapFilterList[i].visible,
                            //         strokeColor: '#' + vm.mapFilterList[i].colorCode,
                            //         strokeOpacity: 1.0,
                            //         strokeWeight: 4,
                            //         name: vm.mapFilterList[i].name
                            //     });
                            //     line.setMap(locations.map);
                            //     polylines.push(line);
                            // }
                        });
                        vm.mapFilterList.push({ msGroup: false })
                    }
                }
            );
        }
        $scope.togglePolyline = function () {
            if (vm.selectedTerrainName.length) {
                clearPolyLines()
                vm.selectedTerrainName.forEach(function (each) {
                    for (var i = 0; i < polylines.length; i++) {
                        if (polylines[i].name === each.name) {
                            if (each.visible) {
                                polylines[i].setMap(locations.map);
                            }
                        } else {
                            polylines[i].setMap(null)
                        }
                    }
                })
            } else {
                clearPolyLines()
            }
        };


        function clearPolyLines() {
            for (var i = 0; i < polylines.length; i++) {

                polylines[i].setMap(null);

            }
        }


        /*  vm.closeModal = function(id) {
          vm.nameImages = null
          $("#" + id).removeClass("in");
          $(".modal-backdrop").remove();
          $("#" + id).hide();
      } */

        $scope.type = {
            terrain: {
                search: "Search",
                nothingSelected: "Map Filter",
                selectAll: "All",
                selectNone: "None",
                Done: "Done"
            },
            phase: {
                search: "Search",
                nothingSelected: "Phase Filter",
                selectAll: "All",
                selectNone: "None",
                Done: "Done"
            },
            block: {
                search: "Search",
                nothingSelected: "Block Filter",
                selectAll: "All",
                selectNone: "None",
                Done: "Done"
            }
        };

        function randomColor() {
            var unusedColors = colors.filter(function (color) {
                return !color.used;
            });
            if (unusedColors.length === 0) return null;
            var randomIndex = Math.floor(Math.random() * unusedColors.length);
            var randomColor = unusedColors[randomIndex];
            randomColor.used = true;
            return randomColor.color;
        }

        function createMetaTag(attrName, attrValue, content) {
            var meta = document.createElement('meta');
            meta.setAttribute(attrName, attrValue);
            meta.setAttribute('content', content);
            return meta;
        }

        function createTable(classNames) {
            var table = document.createElement('table');
            table.setAttribute('border', '1');
            table.setAttribute('cellpadding', '5');
            table.setAttribute('cellspacing', '0');
            table.setAttribute('id', 'sheet0');
            table.setAttribute('class', classNames);
            return table;
        }

        function createTableCell(tagName, textContent) {
            var cell = document.createElement(tagName);
            cell.textContent = textContent;
            return cell;
        }

        function showTaskLogReport(routeId) {
            vm.isReportGenerated = false;
            TaskLogRouteReportData.query(
                {
                    reportType: "daily",
                    routeId: routeId
                },
                onSuccess,
                onError
            );

            function onSuccess(data) {
                var htmlDoc = document.createElement('html');
                var head = document.createElement('head');
                var meta1 = createMetaTag('http-equiv', 'Content-Type', 'text/html; charset=utf-8');
                var meta2 = createMetaTag('name', 'generator', 'PhpSpreadsheet, https://github.com/PHPOffice/PhpSpreadsheet');
                var meta3 = createMetaTag('name', 'author', 'Apache POI');
                var meta4 = createMetaTag('name', 'company', 'Microsoft Corporation');
                head.appendChild(meta1);
                head.appendChild(meta2);
                head.appendChild(meta3);
                head.appendChild(meta4);
                var body = document.createElement('body');
                var table = createTable('sheet0 gridlines');
                var thead = document.createElement('thead');
                var tbody = document.createElement('tbody');

                function createTable(classNames) {
                    var table = document.createElement('table');
                    table.setAttribute('border', '1');
                    table.setAttribute('cellpadding', '5');
                    table.setAttribute('cellspacing', '0');
                    table.setAttribute('id', 'sheet0');
                    table.setAttribute('class', classNames);
                    return table;
                }

                function createMetaTag(attrName, attrValue, content) {
                    var meta = document.createElement('meta');
                    meta.setAttribute(attrName, attrValue);
                    meta.setAttribute('content', content);
                    return meta;
                }

                function createTableCell(tag, content) {
                    var cell = document.createElement(tag);
                    cell.textContent = content;
                    cell.style.fontSize = "13px";
                    return cell;
                }

                thead.className = 'thReport';
                table.appendChild(thead);
                tbody.className = 'tdReport';
                table.appendChild(tbody);
                var styleElement = document.createElement('style');
                styleElement.textContent = "\
                .subheader {\
                    margin: auto;\
                }\
                .thReport > tr > th{\
                    text-align: center;\
                    padding: 10px;\
                }\
                .tdReport > tr > td {\
                    text-align: center; \
                    padding: 10px;\
                }\
                ";
                document.head.appendChild(styleElement);

                var colors = ['#d6d5c9', '#ff9b85', '#ffcccc', '#ccccff', '#fae588', '#b0f2b4', '#abd3ff', '#d0b17a', '#eacdc2',];
                function getRandomColor() {
                    if (colors.length === 0) {
                        colors = ['#d6d5c9', '#ff9b85', '#ffcccc', '#ccccff', '#fae588', '#b0f2b4', '#abd3ff', '#d0b17a', '#eacdc2'];
                    }
                    var index = Math.floor(Math.random() * colors.length);
                    var color = colors[index];
                    colors.splice(index, 1);
                    return color;
                }


                var groupedData = {};
                data.forEach(function (item) {
                    if (!groupedData[item.date]) {
                        groupedData[item.date] = [];
                    }
                    groupedData[item.date].push(item);
                });
                var names = getUniqueNames(data);
                var nameHeaderRow = document.createElement('tr');
                var serialNumberHeader = createTableCell('th', 'S.No');
                serialNumberHeader.style.fontWeight = 'bold';
                serialNumberHeader.style.backgroundColor = '#7dcfb6';
                serialNumberHeader.rowSpan = 2;
                nameHeaderRow.appendChild(serialNumberHeader);
                var dateHeader = createTableCell('th', 'Date');
                dateHeader.style.backgroundColor = '#7dcfb6';
                dateHeader.style.fontWeight = 'bold';
                dateHeader.rowSpan = 2;
                nameHeaderRow.appendChild(dateHeader);
                var typeHeaderRow = document.createElement('tr');

                names.forEach(function (name, index) {
                    var nameData = data.filter(function (item) { return item.name === name; });
                    var availableTypes = getUniqueTypes(nameData);
                    var nameHeaderCell = createTableCell('th', name);
                    nameHeaderCell.style.backgroundColor = getRandomColor();
                    nameHeaderCell.style.fontWeight = 'bold';
                    nameHeaderCell.colSpan = availableTypes.length;
                    nameHeaderRow.appendChild(nameHeaderCell);
                    availableTypes.forEach(function (type) {
                        var typeHeaderCell = createTableCell('th', type);
                        typeHeaderCell.style.backgroundColor = nameHeaderCell.style.backgroundColor;
                        typeHeaderCell.style.fontWeight = 'bold';
                        typeHeaderRow.appendChild(typeHeaderCell);
                    });
                });
                var totalProgressHeader = createTableCell('th', 'Total Progress');
                totalProgressHeader.style.backgroundColor = '#cfe0c3';
                totalProgressHeader.style.fontWeight = 'bold';
                totalProgressHeader.colSpan = 3;
                nameHeaderRow.appendChild(totalProgressHeader);
                var subHeaders = [
                    {
                        type: 'Total',
                    },
                    {
                        type: "Ducting",
                    }, {
                        type: "Validation",

                    }
                ];
                subHeaders.forEach(function (subHeader) {
                    var subHeaderCell = createTableCell('th', subHeader.type);
                    subHeaderCell.style.backgroundColor = '#cfe0c3';
                    subHeaderCell.style.fontWeight = 'bold';
                    typeHeaderRow.appendChild(subHeaderCell);
                });
                thead.appendChild(nameHeaderRow);
                thead.appendChild(typeHeaderRow);
                var totals = {};

                Object.keys(groupedData)
                    .sort(function (a, b) {
                        var dateA = a.split('-').reverse().join('-');
                        var dateB = b.split('-').reverse().join('-');
                        return new Date(dateA) - new Date(dateB);
                    })
                    .forEach(function (date, index) {
                        var totalLength = 0;
                        var totalValidation = 0;
                        names.forEach(function (name) {
                            var nameData = data.filter(function (item) { return item.name === name && item.date === date; });
                            var ductingLength = 0;
                            var hddLength = 0;
                            var horizontalDirectionalDrilling = 0
                            var validationLength = 0;
                            nameData.forEach(function (item) {
                                if (item.type === 'Ducting') {
                                    ductingLength += item.length;
                                }
                                else if (item.type === 'HDD') {
                                    hddLength += item.length;

                                }
                                else if (item.type === 'HDD ( Horizontal Directional Drilling )') {
                                    horizontalDirectionalDrilling += item.length;
                                }
                                else if (item.type === 'Validation' || item.type === 'Validation (T&D)') {
                                    validationLength += item.length;
                                }
                            });
                            totalLength += (ductingLength + hddLength + horizontalDirectionalDrilling);
                            totalValidation += validationLength;

                        });
                        var tr = document.createElement('tr');
                        var serialNumberCell = createTableCell('td', index + 1);
                        serialNumberCell.style.fontSize = "13px";
                        tr.appendChild(serialNumberCell);
                        var dateCell = createTableCell('td', date);
                        dateCell.style.width = '77px'
                        dateCell.style.whiteSpace = 'nowrap';
                        dateCell.style.fontSize = "13px";
                        tr.appendChild(dateCell);
                        names.forEach(function (name) {
                            var nameData = data.filter(function (item) { return item.name === name; });
                            var availableTypes = getUniqueTypes(nameData);

                            availableTypes.forEach(function (type) {
                                var item = groupedData[date].find(function (item) {
                                    return item.name === name && item.type === type;
                                });
                                var lengthValue = item ? item.length : 0;
                                var td = createTableCell('td', lengthValue);

                                tr.appendChild(td);
                                if (!totals[type]) {
                                    totals[type] = 0;
                                }
                                totals[type] += lengthValue;
                            });
                        });
                        var total = 0;
                        for (var i = 2; i < tr.cells.length; i++) {
                            total += parseInt(tr.cells[i].textContent);
                        }
                        var columnValueCell = createTableCell('td', total);
                        columnValueCell.colSpan = 1;
                        tr.appendChild(columnValueCell);
                        var remainingDuctingCell = createTableCell('td', totalLength);
                        tr.appendChild(remainingDuctingCell);
                        var remainingValidationCell = createTableCell('td', totalValidation);
                        tr.appendChild(remainingValidationCell);
                        if (index === 0) {
                            totals['Total Progress Ducting'] = 0;
                            totals['Total Progress Validation'] = 0;
                        }
                        totals['Total Progress Ducting'] += totalLength;
                        totals['Total Progress Validation'] += totalValidation;
                        tbody.appendChild(tr);
                    });

                var staticColumns = ['Total', 'Balance Validation', 'Balance Ducting'];
                var allAvailableTypes = [];
                names.forEach(function (name) {
                    var nameData = data.filter(function (item) { return item.name === name; });
                    allAvailableTypes.push.apply(allAvailableTypes, getUniqueTypes(nameData));
                });
                var availableTypes = allAvailableTypes;
                var maxUniqueTypesCount = availableTypes.length;
                var totalLength = maxUniqueTypesCount + subHeaders.length;

                var columnTotals = {};
                for (var i = 2; i < totalLength; i++) {
                    var total = 0;
                    for (var j = 0; j < tbody.rows.length; j++) {
                        var cellValue = parseInt(tbody.rows[j].cells[i].textContent) || 0;
                        total += cellValue;
                    }
                    columnTotals[i] = total;
                }
                staticColumns.forEach(function (column) {
                    var staticRow = document.createElement('tr');
                    var columnNameCell = createTableCell('td', column);
                    columnNameCell.colSpan = 2;
                    columnNameCell.style.backgroundColor = '#cfe0c3';
                    columnNameCell.style.fontSize = "13px";
                    columnNameCell.style.fontWeight = 'bold';
                    staticRow.appendChild(columnNameCell);
                    if (column == 'Total') {
                        for (var i = 2; i < totalLength; i++) {
                            var columnValueCell = createTableCell('td', columnTotals[i].toString());
                            columnValueCell.colSpan = 1;
                            staticRow.appendChild(columnValueCell);
                        }
                        var columnValueCell = createTableCell('td', totals['Total Progress Ducting']);
                        columnValueCell.colSpan = 1;
                        staticRow.appendChild(columnValueCell);
                        var columnValueCell = createTableCell('td', totals['Total Progress Validation']);
                        columnValueCell.colSpan = 1;
                        staticRow.appendChild(columnValueCell);
                    } else {
                        for (var i = 0; i < totalLength; i++) {
                            var columnValueCell = createTableCell('td', '0');
                            columnValueCell.colSpan = 1;
                            staticRow.appendChild(columnValueCell);
                        }
                    }
                    tbody.appendChild(staticRow);
                });
                table.appendChild(thead);
                table.appendChild(tbody);
                body.appendChild(table);
                htmlDoc.appendChild(body);
                htmlDoc.appendChild(head);
                var htmlString = htmlDoc.outerHTML;
                var containerDiv = document.getElementById("generatedHtmlContainer");
                containerDiv.innerHTML = htmlString;

                function getUniqueNames(data) {
                    var uniqueNames = [];
                    data.forEach(function (item) {
                        if (uniqueNames.indexOf(item.name) === -1) {
                            uniqueNames.push(item.name);
                        }
                    });
                    return uniqueNames;
                }
                function getUniqueTypes(data) {
                    var uniqueTypes = [];
                    data.forEach(function (item) {
                        if (uniqueTypes.indexOf(item.type) === -1) {
                            uniqueTypes.push(item.type);
                        }
                    });
                    return uniqueTypes;
                }

                // Set background color for specified cells
                var jcbCells = document.querySelectorAll('.row1 .column0'); // Select cells in row 1, column 0 (JCB)
                var hddCells = document.querySelectorAll('.row1 .column1'); // Select cells in row 1, column 1 (HDD)

                // Loop through JCB cells and set background color to light green
                jcbCells.forEach(function (cell) {
                    cell.style.backgroundColor = 'lightgreen';
                });

                // Loop through HDD cells and set background color to light green
                hddCells.forEach(function (cell) {
                    cell.style.backgroundColor = 'lightgreen';
                });

                // Get the Total row
                // Select all relevant rows
                var relevantRows = ['.row2', '.row3', '.row5', '.row6'];

                // Loop through each relevant row
                relevantRows.forEach(function (rowClass) {
                    var cells = document.querySelectorAll(rowClass + ' td');

                    // Loop through the cells in the row
                    cells.forEach(function (cell) {
                        // Check if the content of the cell is '0' or empty
                        if (cell.textContent.trim() === '0' || cell.textContent.trim() === '') {
                            // Set background color to white
                            cell.style.backgroundColor = 'white';
                        }
                    });
                });

                vm.isReportGenerated = true;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }


        function clearPhase() {
            for (var k = 0; k < phasePolylines.length; k++) {
                phasePolylines[k].setMap(null);
            }
            for (var j = 0; j < phaseMarkers.length; j++) {
                phaseMarkers[j].setMap(null);
            }
        }


        function createInfoWindow(polyline, each) {
            google.maps.event.addListener(
                polyline,
                "click",
                (function () {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                        /* if (spreadPoly != null) {
                            spreadPoly.setMap(null);
                        } */
                    }

                    /*   clearTimeout(polylineTimer);
  
                      spreadPoly = new google.maps.Polyline({
                          path: {
                              lat: each.lat,
                              lng: each.lng,
                          },
                          strokeColor: "#FFFF00",
                          strokeOpacity: 0.6,
                          strokeWeight: 19,
                      });
  
                      spreadPoly.setMap(locations.map);
  
                      polylineTimer = setTimeout(function () {
                          if (spreadPoly != null) {
                              spreadPoly.setMap(null);
                              spreadPoly = null;
                          }
                      }, 5000); */

                    var name = each.name ? "<b>Name : " + each.name + "</b></br>" : ""
                    var block = each.block ? "<b>Block : " + each.block + "</b></br>" : ""
                    /*  if (openedInfoWindow != null) {
                         openedInfoWindow.close();
                     } */


                    infowindow.setContent(
                        '<div class="infoWindowhead"></br></br>' +
                        name +
                        "<b>Latitude : " + each.lat + "</b></br>" +
                        "<b>Longitude : " + each.lng + "</b></br>" +
                        block +
                        "</br></div>"
                    );


                    infowindow.open(locations.map);
                    infowindow.setPosition({
                        lat: each.lat,
                        lng: each.lng,
                    });
                    openedInfoWindow = infowindow;
                }))
        }

        //callAssetsRouteByID(vm.trRoute.id,null)
        function callAssetsRouteByID(routeId, lastId) {
            assetsByRouteId.get(routeId, lastId, function (data) {
                if (data.assets.length) {
                    /*  data.assets.forEach(function(item){
                         if(vm.storeAssetTypes.length){
                             vm.storeAssetTypes.filter(function (eachElement) {
                                 if(item.assetTypeName == eachElement.name){
                                     item.assetTypeAttributeValues = eachElement.assetTypeAttributes
                                 }
                             });
                         }
                        
                     }) */
                    var markerSymbol, marker
                    data.assets.forEach(function (item) {
                        if (item.block) {
                            var isDuplicate = false;
                            for (var i = 0; i < vm.blockFilter.length; i++) {
                                if (vm.blockFilter[i].name === item.block) {
                                    isDuplicate = true;
                                    break;
                                }
                            }
                            if (!isDuplicate) {
                                vm.blockFilter.push({ name: item.block, selected: true });
                            }
                        }
                        if (item.assetCoordinates.length) {
                            if (item.assetTypeLayout == "FIXED" && item.phase != null) {
                                isObjBool[item.phase] = true
                                locations.map.setCenter({ lat: item.assetCoordinates[0].latitude, lng: item.assetCoordinates[0].longitude });
                                var imagePath = item.assetTypeImagePath;
                                /*  if(item.assetTypeName == "GP"){
                                     item.assetTypeAttributeValues.forEach(function (value, index) {
                                         if (value.name == "GP Status") {
                                             if(value.attributeValue == "Connected"){
                                                 imagePath = 'content/images/panchayat_yellow.png';
                                             } else {
                                                 imagePath = 'content/images/panchayat_red.png';
                                             }
                                         }
                                     });
                                 } */

                                item.assetCoordinates.forEach(function (each, index) {



                                    marker = new google.maps.Marker({
                                        position: { lat: each.latitude, lng: each.longitude },
                                        map: locations.map,
                                        icon: {
                                            scaledSize: new google.maps.Size(iconSize, iconSize),
                                            url: imagePath,
                                            anchor: new google.maps.Point(
                                                iconSize / 2,
                                                iconSize / 2
                                            ),
                                            labelOrigin: new google.maps.Point(25, 22),
                                        },
                                        name: item.phase,
                                        block: item.block
                                    });


                                    google.maps.event.addListener(
                                        marker,
                                        "click",
                                        (function (marker) {
                                            var assetDetails = item;
                                            var userContent = document.createElement("div"),
                                                btnShowAttributes,
                                                btnCr,
                                                btnText,
                                                btnEditAsset,
                                                btnCreateCase,
                                                btnThreePointRef,
                                                brBreak;
                                            var otdr = "";
                                            var infoStr = "";
                                            /*  var assetTypeAttributeValuesList =
                                             assetDetails.assetTypeAttributeValues;
                                             assetTypeAttributeValuesList.sort(function (a, b) {
                                                 var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                                     nameB = a.assetTypeAttribute.name.toLowerCase();
                                                 if (nameA < nameB)
                                                     // sort string ascending
                                                     return -1;
                                                 if (nameA > nameB) return 1;
                                                 return 0; // default return value (no sorting)
                                             });
                                             for (
                                                 var i = 0;
                                                 i < assetTypeAttributeValuesList.length;
                                                 i++
                                             ) {
                                                 var home = assetTypeAttributeValuesList[i];
                                                 infoStr +=
                                                     "<br><b>" +
                                                     home.assetTypeAttribute.name +
                                                     "</b> : " +
                                                     home.attributeValue;
                                                 otdr = home;
                                             } */
                                            userContent.innerHTML =
                                                '<div class="infoWindowhead">' +
                                                assetDetails.name +
                                                '</div><div class="infoWindowContent">' +
                                                "<b>Asset Type </b> : " +
                                                assetDetails.assetTypeName +
                                                "<br><b>Phase </b> : " +
                                                assetDetails.phase +
                                                "<br>" +
                                                infoStr +
                                                "</div><br>";

                                            return function () {
                                                infowindow.setContent(userContent);
                                                infowindow.open(locations.map, marker);
                                                infowindow.setPosition(
                                                    new google.maps.LatLng(
                                                        marker.getPosition().lat(),
                                                        marker.getPosition().lng()
                                                    )
                                                );
                                                setTimeout(function () {
                                                    infowindow.close();
                                                }, 50000);
                                            };
                                        })(marker, index)
                                    );

                                    /*  markerSymbol = new google.maps.Marker({
                                         icon:  {
                                             path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                             strokeColor: tempColor[item.phase],
                                             fillColor: tempColor[item.phase],
                                             fillOpacity:1,
                                             scale: 1,
                                             labelOrigin: new google.maps.Point(-1, 7)
                                         },
                                         map: locations.map,
                                         position:  { lat: each.latitude, lng: each.longitude },
                                         name:item.phase
                                     });  */
                                    phaseMarkers.push(marker);
                                    // phaseMarkers.push(markerSymbol);
                                })
                                //createMarkers(assetsList, 'content/images/chainagePoints.png', locations.map,"assets")
                                //createMarkers(assetsList, item.assetType.imagePath , locations.map, "assets")
                            }

                            if (item.assetTypeLayout == "SPREAD" && item.phase != null) {
                                isObjBool[item.phase] = true
                                assetSpreadListPlanning = []
                                assetSpreadListMaintenance = []
                                assetSpreadListRollout = []
                                assetSpreadListPlanSurvey = []
                                item.assetCoordinates.forEach(function (each) {
                                    callSwitchPhaseSpread(each, item.phase, item.name, item.block)
                                })
                                callPhaseSpread()
                                //createMarkers(assetsList, 'content/images/chainagePoints.png', locations.map,"assets")
                                //createMarkers(assetsList, item.assetType.imagePath , locations.map, "assets")
                            }
                        }

                    })

                    callObjPhases()
                }

                if (data.assets.length >= 50) {
                    callAssetsRouteByID(vm.trRoute.id, data.assets[data.assets.length - 1].id)
                }
            })
        }
        function getAssetsByRoute() {
            RouteAssetsById.get(vm.trRoute.id, function (response) {
                vm.assets = response.assets;
                var colorMap = {
                    "Chamber": "#FF0000",
                    "Pole": "#0000FF"
                };
                vm.assets.forEach(function (asset) {
                    var coordinates = asset.assetCoordinates.map(function (coord) {
                        return { lat: coord.latitude, lng: coord.longitude };
                    });
                    if (asset.assetTypeLayout === 'FIXED') {
                        var marker = new google.maps.Marker({
                            position: coordinates[0],
                            map: locations.map,
                            title: asset.name,
                            icon: {
                                url: asset.assetTypeImagePath,
                                scaledSize: new google.maps.Size(32, 32)
                            }
                        });
                        var infowindow = new google.maps.InfoWindow();
                        google.maps.event.addListener(marker, 'click', function () {
                            if (openedInfoWindow !== null) {
                                openedInfoWindow.close();
                            }
                            var contentString = '<div class="infoWindowhead">' +
                                "<b>ID: " + asset.id + "</b><br>" +
                                "<b>Name: " + asset.name + "</b><br>" +
                                "<b>AssetTypeName: " + asset.assetTypeName + "</b><br>" +
                                "<b>Latitude: " + coordinates[0].lat + "</b><br>" +
                                "<b>Longitude: " + coordinates[0].lng + "</b><br>" +
                                "</div>";
                            infowindow.setContent(contentString);
                            infowindow.open(locations.map, marker);
                            openedInfoWindow = infowindow;
                        });
                    } else if (asset.assetTypeLayout === 'SPREAD') {
                        var polylineColor = colorMap[asset.assetTypeName] || '#0000FF';
                        var drawPolyline = new google.maps.Polyline({
                            path: coordinates,
                            geodesic: true,
                            strokeColor: polylineColor,
                            strokeOpacity: 1.0,
                            strokeWeight: 2
                        });
                        drawPolyline.setMap(locations.map);
                        var polylineInfoWindow = new google.maps.InfoWindow();
                        google.maps.event.addListener(drawPolyline, 'click', function (event) {
                            if (openedInfoWindow !== null) {
                                openedInfoWindow.close();
                            }

                            var contentString = '<div class="infoWindowhead">' +
                                "<b>ID: " + asset.id + "</b><br>" +
                                "<b>Name: " + asset.name + "</b><br>" +
                                "<b>AssetTypeName: " + asset.assetTypeName + "</b><br>" +
                                "<b>Coordinates: </b><br>";
                            coordinates.forEach(function (coord, index) {
                                contentString += "Point " + (index + 1) + ": " +
                                    "Lat: " + coord.lat + ", Lng: " + coord.lng + "<br>";
                            });
                            contentString += "</div>";
                            polylineInfoWindow.setContent(contentString);
                            polylineInfoWindow.setPosition(event.latLng);
                            polylineInfoWindow.open(locations.map);
                            openedInfoWindow = polylineInfoWindow;
                        });
                    }
                });
            });
        }

        setTimeout(function () {
            getAssetsByRoute();
        }, 3000);
        vm.page = 1;
        vm.predicate = 'modified_date';
        vm.itemsPerPage = 10;
        vm.mtrpage = 1;
        vm.mtrPerPage = 10;
        vm.materialTxnByDates = [];
        vm.materialByDate = [];
        vm.loadMaterialTxnDate = function () {
            MaterialDates.query({
                type: "route",
                id: entity.id,
                page: vm.mtrpage - 1,
                size: vm.mtrPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }, function (data, headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                vm.TotalItems = headers('X-Total-Count');
                vm.QueryCount = vm.TotalItems;
                vm.materialByDate = data.map(function (item) {
                    return {
                        timestamp: item[0],
                        total: item[1],
                        isExpand: false
                    };
                });

            });
        };
        vm.loadMaterialTxnDate();
        vm.loadMaterialTxnByDates = function (date) {
            MaterialByDates.query({
                type: 'route',
                id: entity.id,
                date: date,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }, function (data, headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.materialTxnByDates = data.map(function (item) {
                    return {
                        materialId: item[0] || "NA",
                        timestamp: item[4] || "NA",
                        transactionName: item[1] || "NA",
                        transactionType: item[2] || "NA",
                        count: item[3] || "NA",
                        status: item[5] || "NA",
                        transactionId: item[6] || "NA",
                    };
                });
            });
        };
        vm.toggleExpand = function (selectedTask) {
            angular.forEach(vm.materialByDate, function (task) {
                if (task === selectedTask) {
                    task.isExpand = !task.isExpand;
                    if (task.isExpand) {
                        vm.loadMaterialTxnByDates(task.timestamp);
                    } else {
                        vm.materialTxnByDates = [];
                    }
                } else {
                    task.isExpand = false;
                }
            });
        };
        vm.transitionMainMateril = function () {
            vm.loadMaterialTxnDate()
        }
        vm.fetchTerrain = function () {
            RouteByID.get(vm.trRoute.id, function (response) {
                vm.routePathResponse = response;
                callRoutePath(vm.routePathResponse.routePoints);
            });
            function callRoutePath(routePaths) {
                var colors = ['#FD7A24', '#0096FF'];
                var pathColor = colors[0];
                var proposedPathColor = colors[1];
                var groupedRoutePaths = {};
                var bounds = new google.maps.LatLngBounds();


                routePaths.forEach(function (coord) {
                    if (coord.sessionNumber) {
                        if (!groupedRoutePaths[coord.sessionNumber]) {
                            groupedRoutePaths[coord.sessionNumber] = { planned: [], proposed: [] };
                        }

                        if (coord.routePointType === 'COORDINATE') {
                            if (coord.phase === 'PLANNING') {
                                groupedRoutePaths[coord.sessionNumber].planned.push(coord);
                            } else if (coord.phase === 'SURVEY') {
                                groupedRoutePaths[coord.sessionNumber].proposed.push(coord);
                            }
                        }
                    }
                });


                Object.keys(groupedRoutePaths).forEach(function (sessionNumber) {
                    var sessionData = groupedRoutePaths[sessionNumber];
                    if (sessionData.planned.length) {
                        var pathCoordinates = sessionData.planned.map(function (coord) {
                            var latLng = { lat: coord.latitude, lng: coord.longitude };
                            bounds.extend(latLng);
                            return latLng;
                        });
                        var pathPolyline = new google.maps.Polyline({
                            path: pathCoordinates,
                            geodesic: true,
                            strokeColor: pathColor,
                            strokeOpacity: 1.0,
                            strokeWeight: 4,
                            zIndex: 1
                        });
                        pathPolyline.setMap(vm.newMap);
                    }

                    if (sessionData.proposed.length) {
                        var proposedPathCoordinates = sessionData.proposed.map(function (coord) {
                            var latLng = { lat: coord.latitude, lng: coord.longitude };
                            bounds.extend(latLng);
                            return latLng;
                        });

                        var proposedPathPolyline = new google.maps.Polyline({
                            path: proposedPathCoordinates,
                            geodesic: true,
                            strokeColor: proposedPathColor,
                            strokeOpacity: 1.0,
                            strokeWeight: 3,
                            zIndex: 1
                        });
                        proposedPathPolyline.setMap(vm.newMap);
                    }
                });
            }
            var bounds = new google.maps.LatLngBounds();
            vm.newMap = new google.maps.Map(
                document.getElementById("map_canvasStarts"),
                myOption($rootScope.rolloutMapInitZoom, center)
            );
            geoLocation(vm.newMap);
            TrRouteForMap.getTerrainPathByRoute(vm.trRoute.id, function (response) {
                vm.terrainPathResponse = response;
                callTerrainPath(vm.terrainPathResponse);
            });
            function callTerrainPath(terrainPaths) {
                vm.terrainPolylines = {};
                vm.terrainMarkers = {};
                terrainPaths.forEach(function (pathGroup) {
                    vm.terrainColorMap = {};
                    vm.terrainListResponse.forEach(function (terrain) {
                        vm.terrainColorMap[terrain.id] = terrain.colorcode;
                    });
                    var pathColor = vm.terrainColorMap[pathGroup.id];

                    if (pathGroup.value && Array.isArray(pathGroup.value.COORDINATE)) {
                        var pathCoordinates = pathGroup.value.COORDINATE.map(function (coord) {
                            if (Array.isArray(coord.routePointNumber)) {
                                coord.routePointNumber.sort(function (a, b) {
                                    return a - b;
                                });
                            }
                            var latLng = { lat: coord.latitude, lng: coord.longitude };
                            bounds.extend(latLng);
                            return latLng;
                        });
                        var pathPolyline = new google.maps.Polyline({
                            path: pathCoordinates,
                            geodesic: true,
                            strokeColor: pathColor,
                            strokeOpacity: 1.0,
                            strokeWeight: 3,
                            zIndex: 2
                        });
                        pathPolyline.setMap(vm.newMap);
                        vm.terrainPolylines[pathGroup.id] = pathPolyline;
                        google.maps.event.addListener(pathPolyline, 'click', function (event) {
                            if (openedInfoWindow != null) {
                                openedInfoWindow.close();
                            }

                            var terrain = vm.terrainListResponse.find(function (terrain) {
                                return terrain.id === pathGroup.id;
                            });
                            if (terrain) {
                                var contentString = '<div class="infoWindowhead"></br></br>' +
                                    "<b>Terrain Name: " + terrain.name + "</b></br>" +
                                    "<b>Terrain-Type: " + terrain.typeName + "</b></br>" +
                                    "<b>Length: " + terrain.length + "</b></br>" +
                                    "<b>Phase: " + terrain.phase + "</b></br>" +
                                    "</div>";

                                infowindow.setContent(contentString);
                                infowindow.setPosition(event.latLng);
                                infowindow.open(vm.newMap);
                                openedInfoWindow = infowindow;
                            }
                        })
                        google.maps.event.addListener(pathPolyline, 'mouseover', function () {
                            vm.highlightTableTerrain(pathGroup.id);
                        });
                        google.maps.event.addListener(pathPolyline, 'mouseout', function () {
                            vm.resetTableRowHighlight(pathGroup.id);
                        });
                    }
                });
                vm.newMap.fitBounds(bounds);
            }
            vm.terrainListResponse;
            vm.uniquePhases = [];
            var phasesMap = {};
            vm.isVerifiedFilter = false;
            vm.isNotVerifiedFilter = false;
            vm.isDeviatedFilter = false;
            for (var i = 0; i < vm.terrainListResponse.length; i++) {
                var phase = vm.terrainListResponse[i].phase;
                if (!phasesMap[phase]) {
                    vm.uniquePhases.push({ phase: phase, selected: false });
                    phasesMap[phase] = true;
                }
            }

            vm.uniqueTerrainTypes = [];
            vm.filteredTerrainList = vm.terrainListResponse;
            vm.updateTerrainTypes = function () {
                var selectedPhases = [];

                for (var j = 0; j < vm.uniquePhases.length; j++) {
                    if (vm.uniquePhases[j].selected) {
                        selectedPhases.push(vm.uniquePhases[j].phase);
                    }
                }
                if (selectedPhases.length === 0) {
                    vm.uniqueTerrainTypes = [];
                }

                vm.uniqueTerrainTypes = [];
                var terrainTypesMap = {};
                for (var k = 0; k < vm.terrainListResponse.length; k++) {
                    var terrain = vm.terrainListResponse[k];

                    var isPhaseMatched = selectedPhases.indexOf(terrain.phase) !== -1;
                    var isVerifiedMatch = (vm.isVerifiedFilter && terrain.isVerified === true) ||
                        (vm.isNotVerifiedFilter && terrain.isVerified === false) ||
                        (!vm.isVerifiedFilter && !vm.isNotVerifiedFilter);

                    var isDeviatedMatch = (vm.isDeviatedFilter && terrain.isDeviated === true) ||
                        (!vm.isDeviatedFilter);

                    if (isPhaseMatched && isVerifiedMatch && isDeviatedMatch) {
                        if (!terrainTypesMap[terrain.typeName]) {
                            vm.uniqueTerrainTypes.push({
                                typeName: terrain.typeName,
                                colorcode: terrain.colorcode,
                                selected: false
                            });
                            terrainTypesMap[terrain.typeName] = true;
                        }
                    }
                }

                for (var id in vm.terrainPolylines) {
                    if (vm.terrainPolylines.hasOwnProperty(id)) {
                        vm.terrainPolylines[id].setOptions({ strokeWeight: 3 });
                    }
                }

                vm.areFiltersSelected = function () {
                    var selectedPhases = vm.uniquePhases.some(function (phase) {
                        return phase.selected;
                    });
                    var selectedTerrainTypes = vm.uniqueTerrainTypes.some(function (terrain) {
                        return terrain.selected;
                    });
                    var selectedVerification = vm.isVerifiedFilter || vm.isNotVerifiedFilter || vm.isDeviatedFilter;
                    return selectedPhases || selectedTerrainTypes || selectedVerification;
                };
            };

            vm.applyFilters = function () {
                var selectedPhases = [];
                var selectedTerrainTypes = [];

                for (var l = 0; l < vm.uniquePhases.length; l++) {
                    if (vm.uniquePhases[l].selected) {
                        selectedPhases.push(vm.uniquePhases[l].phase);
                    }
                }

                var filteredList = vm.terrainListResponse.filter(function (terrain) {
                    return selectedPhases.length === 0 || selectedPhases.indexOf(terrain.phase) !== -1;
                });

                var isVerifiedOrNotChecked = vm.isVerifiedFilter || vm.isNotVerifiedFilter;
                var isDeviatedChecked = vm.isDeviatedFilter;

                filteredList = filteredList.filter(function (terrain) {
                    return (!isVerifiedOrNotChecked) ||
                        (vm.isVerifiedFilter && terrain.isVerified === true) ||
                        (vm.isNotVerifiedFilter && (terrain.isVerified === false || terrain.isVerified === null));
                });

                filteredList = filteredList.filter(function (terrain) {
                    return (!isDeviatedChecked) || (vm.isDeviatedFilter && terrain.isDeviated === true);
                });

                var availableTerrainTypes = [];
                filteredList.forEach(function (terrain) {
                    if (availableTerrainTypes.indexOf(terrain.typeName) === -1) {
                        availableTerrainTypes.push(terrain.typeName);
                    }
                });

                vm.uniqueTerrainTypes.forEach(function (terrainType) {
                    terrainType.isAvailable = availableTerrainTypes.indexOf(terrainType.typeName) !== -1;
                });

                for (var m = 0; m < vm.uniqueTerrainTypes.length; m++) {
                    if (vm.uniqueTerrainTypes[m].selected) {
                        selectedTerrainTypes.push(vm.uniqueTerrainTypes[m].typeName);
                    }
                }

                filteredList = filteredList.filter(function (terrain) {
                    return selectedTerrainTypes.length === 0 || selectedTerrainTypes.indexOf(terrain.typeName) !== -1;
                });

                for (var id in vm.terrainPolylines) {
                    if (vm.terrainPolylines.hasOwnProperty(id)) {
                        vm.terrainPolylines[id].setOptions({ strokeWeight: 0 });
                    }
                }

                filteredList.forEach(function (terrain) {
                    var terrainId = terrain.id;
                    if (vm.terrainPolylines[terrainId]) {
                        vm.terrainPolylines[terrainId].setOptions({ strokeWeight: 6, strokeColor: terrain.colorcode });
                    }
                });

                vm.filteredTerrainList = filteredList;
                vm.isFilterMenuOpen = false;
            };

            vm.resetFilters = function () {
                for (var o = 0; o < vm.uniquePhases.length; o++) {
                    vm.uniquePhases[o].selected = false;
                }
                for (var p = 0; p < vm.uniqueTerrainTypes.length; p++) {
                    vm.uniqueTerrainTypes[p].selected = false;
                }
                vm.isVerifiedFilter = false;
                vm.isNotVerifiedFilter = false;
                vm.isDeviatedFilter = false;
                for (var id in vm.terrainPolylines) {
                    if (vm.terrainPolylines.hasOwnProperty(id)) {
                        vm.terrainPolylines[id].setOptions({ strokeWeight: 3 });
                    }
                }
                vm.uniqueTerrainTypes = []
                vm.filteredTerrainList = vm.terrainListResponse;
            };
            vm.closeFilters = function () {
                vm.isFilterMenuOpen = false;

            };
        };
        vm.highlightTerrain = function (terrainId) {
            for (var id in vm.terrainPolylines) {
                if (id == terrainId) {
                    vm.terrainPolylines[id].setOptions({ strokeWeight: 6 });
                } else {
                    vm.terrainPolylines[id].setOptions({ strokeWeight: 3 });
                }
            }
        };
        vm.resetTerrain = function () {

            for (var id in vm.terrainPolylines) {
                if (vm.terrainPolylines.hasOwnProperty(id)) {
                    if (vm.filteredTerrainList.find(function (terrain) {
                        return terrain.id == id
                    })) {
                        vm.terrainPolylines[id].setOptions({ strokeWeight: 3 });
                    } else {
                        vm.terrainPolylines[id].setOptions({ strokeWeight: 3 });
                    }
                }
            }
        };
        vm.highlightRow = function (rowId) {
            for (var id in vm.rowPolylines) {
                if (id == rowId) {
                    vm.rowPolylines[id].setOptions({ strokeWeight: 6 });
                } else {
                    vm.rowPolylines[id].setOptions({ strokeWeight: 3 });
                }
            }
        };
        vm.resetRow = function () {
            for (var id in vm.rowPolylines) {
                if (vm.rowPolylines.hasOwnProperty(id)) {
                    if (vm.filteredRowList.find(function (row) {
                        return row.id == id
                    })) {
                        vm.rowPolylines[id].setOptions({ strokeWeight: 3 });
                    } else {
                        vm.rowPolylines[id].setOptions({ strokeWeight: 3 });
                    }
                }
            }
        };
        vm.highlightTableTerrain = function (id) {
            var terrains = document.querySelectorAll('.highlighted-terrain');
            terrains.forEach(function (terrain) {
                terrain.classList.remove('highlighted-terrain');
            });
            var terrainRow = document.getElementById('terrain-' + id);

            if (terrainRow) {
                terrainRow.classList.add('highlighted-terrain');
                terrainRow.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'nearest'
                });
            }
        };


        vm.highlightTableRow = function (id) {
            var rows = document.querySelectorAll('.highlighted-row');
            rows.forEach(function (row) {
                row.classList.remove('highlighted-row');
            });
            var row = document.getElementById('row-' + id);
            if (row) {
                row.classList.add('highlighted-row');
                row.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'nearest'
                });
            }
        };


        vm.resetTableRowHighlight = function (id) {
            var terrain = document.getElementById('terrain-' + id)
            var row = document.getElementById('row-' + id);
            if (row) {
                row.classList.remove('highlighted-row');
            }
            if (terrain) {
                terrain.classList.remove('highlighted-terrain');
            }
        };
        vm.isFilterMenuOpen = false;
        vm.toggleFilterMenu = function () {
            vm.isFilterMenuOpen = !vm.isFilterMenuOpen;
        };
        vm.fetchRow = function () {
            RouteByID.get(vm.trRoute.id, function (response) {
                vm.routePathResponse = response;
                callRoutePath(vm.routePathResponse.routePoints);
            });
            function callRoutePath(routePaths) {
                var colors = ['#FD7A24', '#0096FF'];
                var pathColor = colors[0];
                var proposedPathColor = colors[1];
                var groupedRoutePaths = {};
                routePaths.forEach(function (coord) {
                    if (coord.sessionNumber) {
                        if (!groupedRoutePaths[coord.sessionNumber]) {
                            groupedRoutePaths[coord.sessionNumber] = { planned: [], proposed: [] };
                        }
                        if (coord.routePointType === 'COORDINATE') {
                            if (coord.phase === 'PLANNING') {
                                groupedRoutePaths[coord.sessionNumber].planned.push(coord);
                            } else if (coord.phase === 'SURVEY') {
                                groupedRoutePaths[coord.sessionNumber].proposed.push(coord);
                            }
                        }
                    }
                })
                Object.keys(groupedRoutePaths).forEach(function (sessionNumber) {
                    var sessionData = groupedRoutePaths[sessionNumber];
                    if (sessionData.planned.length) {
                        var pathCoordinates = sessionData.planned.map(function (coord) {
                            var latLng = { lat: coord.latitude, lng: coord.longitude };
                            bounds.extend(latLng);
                            return latLng;
                        });
                        var pathPolyline = new google.maps.Polyline({
                            path: pathCoordinates,
                            geodesic: true,
                            strokeColor: pathColor,
                            strokeOpacity: 1.0,
                            strokeWeight: 4,
                            zIndex: 1
                        });
                        pathPolyline.setMap(vm.newMaps);
                    }
                    if (sessionData.proposed.length) {
                        var proposedPathCoordinates = sessionData.proposed.map(function (coord) {
                            var latLng = { lat: coord.latitude, lng: coord.longitude };
                            bounds.extend(latLng);
                            return latLng;
                        });
                        var proposedPathPolyline = new google.maps.Polyline({
                            path: proposedPathCoordinates,
                            geodesic: true,
                            strokeColor: proposedPathColor,
                            strokeOpacity: 1.0,
                            strokeWeight: 3,
                            zIndex: 1
                        });
                        proposedPathPolyline.setMap(vm.newMaps);
                    }
                });
            }
            var markers = [];
            var openedInfoWindow = null;
            var bounds = new google.maps.LatLngBounds();
            vm.newMaps = new google.maps.Map(
                document.getElementById("map_canvasStartss"),
                myOption($rootScope.rolloutMapInitZoom, center)
            );
            geoLocation(vm.newMaps)
            TrRouteForMap.getRoWPathByRoute(vm.trRoute.id, function (response) {
                vm.rowPathResponse = response;
                callRowPath(vm.rowPathResponse);
            });
            function callRowPath(rowPaths) {
                vm.rowPolylines = {};
                vm.rowMarkers = {};
                rowPaths.forEach(function (pathGroup) {
                    vm.rowColorMap = {};
                    vm.rowListResponse.forEach(function (row) {
                        vm.rowColorMap[row.id] = row.colorcode;
                    });
                    var pathColor = vm.rowColorMap[pathGroup.id]
                    if (pathGroup.value && Array.isArray(pathGroup.value.COORDINATE)) {
                        var pathCoordinates = pathGroup.value.COORDINATE.map(function (coord) {
                            if (Array.isArray(coord.routePointNumber)) {
                                coord.routePointNumber.sort(function (a, b) {
                                    return a - b;
                                });
                            }
                            var latLng = { lat: coord.latitude, lng: coord.longitude };
                            bounds.extend(latLng);
                            return latLng;
                        });
                        var pathPolyline = new google.maps.Polyline({
                            path: pathCoordinates,
                            geodesic: true,
                            strokeColor: pathColor,
                            strokeOpacity: 1.0,
                            strokeWeight: 3,
                            zIndex: 2
                        });
                        pathPolyline.setMap(vm.newMaps);
                        vm.rowPolylines[pathGroup.id] = pathPolyline;
                        var infowindow = new google.maps.InfoWindow();
                        google.maps.event.addListener(pathPolyline, 'click', function (event) {
                            if (openedInfoWindow != null) {
                                openedInfoWindow.close();
                            }
                            var row = vm.rowListResponse.find(function (row) {
                                return row.id === pathGroup.id;
                            });
                            if (row) {
                                var contentString = '<div class="infoWindowhead"></br>' +
                                    "<b>Terrain Name: " + row.name + "</b></br>" +
                                    "<b>Row-Type: " + row.typeName + "</b></br>" +
                                    "<b>Length: " + row.length + "</b></br>" +
                                    "<b>Phase: " + row.phase + "</b></br>" +
                                    "</div>";

                                infowindow.setContent(contentString);
                                infowindow.setPosition(event.latLng);
                                infowindow.open(vm.newMaps);
                                openedInfoWindow = infowindow;
                            }
                        });
                        google.maps.event.addListener(pathPolyline, 'mouseover', function () {
                            vm.highlightTableRow(pathGroup.id);
                        });
                        google.maps.event.addListener(pathPolyline, 'mouseout', function () {
                            vm.resetTableRowHighlight(pathGroup.id);
                        });
                    }
                    if (pathGroup.value && Array.isArray(pathGroup.value.MARKER)) {
                        pathGroup.value.MARKER.forEach(function (coord) {
                            var markerPosition = { lat: coord.latitude, lng: coord.longitude };
                            bounds.extend(markerPosition);
                            var marker = new google.maps.Marker({
                                position: markerPosition,
                                map: vm.newMaps,
                                icon: {
                                    url: 'content/images/markersPoint.png',
                                    scaledSize: new google.maps.Size(iconSize, iconSize),
                                    anchor: new google.maps.Point(
                                        iconSize / 2,
                                        iconSize / 2
                                    ),
                                }
                            });
                            markers.push(marker);
                            markerList.push(marker);

                        });
                        vm.rowMarkers[pathGroup.id] = markerList;
                    }
                    vm.newMaps.fitBounds(bounds);
                });
            }

            vm.rowListResponse;
            vm.uniquePhases = [];
            var phasesMap = {};
            for (var i = 0; i < vm.rowListResponse.length; i++) {
                var phase = vm.rowListResponse[i].phase;
                if (!phasesMap[phase]) {
                    vm.uniquePhases.push({ phase: phase, selected: false });
                    phasesMap[phase] = true;
                }
            }
            vm.uniqueRowTypes = [];
            vm.filteredRowList = vm.rowListResponse;
            vm.updateRowTypes = function () {
                var selectedPhases = [];
                for (var j = 0; j < vm.uniquePhases.length; j++) {
                    if (vm.uniquePhases[j].selected) {
                        selectedPhases.push(vm.uniquePhases[j].phase);
                    }
                }
                if (selectedPhases.length === 0) {
                    vm.uniqueRowTypes = [];

                }
                vm.uniqueRowTypes = [];
                var rowTypesMap = {};
                for (var k = 0; k < vm.rowListResponse.length; k++) {
                    var row = vm.rowListResponse[k];
                    var isPhaseMatched = selectedPhases.indexOf(row.phase) !== -1;
                    var isVerifiedMatch = (vm.isVerifiedFilter && row.isVerified === true) ||
                        (vm.isNotVerifiedFilter && row.isVerified === false) ||
                        (!vm.isVerifiedFilter && !vm.isNotVerifiedFilter);

                    var isDeviatedMatch = (vm.isDeviatedFilter && row.isDeviated === true) ||
                        (!vm.isDeviatedFilter);

                    if (isPhaseMatched && isVerifiedMatch && isDeviatedMatch) {
                        if (!rowTypesMap[row.typeName]) {
                            vm.uniqueRowTypes.push({
                                typeName: row.typeName,
                                colorcode: row.colorcode,
                                selected: false
                            });
                            rowTypesMap[row.typeName] = true;
                        }
                    }
                }
                for (var id in vm.rowPolylines) {
                    if (vm.rowPolylines.hasOwnProperty(id)) {
                        vm.rowPolylines[id].setOptions({ strokeWeight: 3 });
                    }
                }
                vm.areFiltersSelected = function () {
                    var selectedPhases = vm.uniquePhases.some(function (phase) {
                        return phase.selected;
                    });
                    var selectedRowTypes = vm.uniqueRowTypes.some(function (row) {
                        return row.selected;
                    });
                    var selectedVerification = vm.isVerifiedFilter || vm.isNotVerifiedFilter || vm.isDeviatedFilter;
                    return selectedPhases || selectedRowTypes || selectedVerification;
                };

            };
            vm.applyFilters = function () {
                var selectedPhases = [];
                var selectedRowTypes = [];
                for (var l = 0; l < vm.uniquePhases.length; l++) {
                    if (vm.uniquePhases[l].selected) {
                        selectedPhases.push(vm.uniquePhases[l].phase);
                    }
                }
                var filteredList = vm.rowListResponse.filter(function (row) {
                    return selectedPhases.length === 0 || selectedPhases.indexOf(row.phase) !== -1;
                });

                var isVerifiedOrNotChecked = vm.isVerifiedFilter || vm.isNotVerifiedFilter;
                var isDeviatedChecked = vm.isDeviatedFilter;

                filteredList = filteredList.filter(function (row) {
                    return (!isVerifiedOrNotChecked) ||
                        (vm.isVerifiedFilter && row.isVerified === true) ||
                        (vm.isNotVerifiedFilter && (row.isVerified === false || row.isVerified === null));
                });

                filteredList = filteredList.filter(function (row) {
                    return (!isDeviatedChecked) || (vm.isDeviatedFilter && row.isDeviated === true);
                });

                var availableRowTypes = [];
                filteredList.forEach(function (row) {
                    if (availableRowTypes.indexOf(row.typeName) === -1) {
                        availableRowTypes.push(row.typeName);
                    }
                });

                vm.uniqueRowTypes.forEach(function (rowType) {
                    rowType.isAvailable = availableRowTypes.indexOf(rowType.typeName) !== -1;
                });

                for (var m = 0; m < vm.uniqueRowTypes.length; m++) {
                    if (vm.uniqueRowTypes[m].selected) {
                        selectedRowTypes.push(vm.uniqueRowTypes[m].typeName);
                    }
                }

                filteredList = filteredList.filter(function (row) {
                    return selectedRowTypes.length === 0 || selectedRowTypes.indexOf(row.typeName) !== -1;
                });
                for (var id in vm.rowPolylines) {
                    if (vm.rowPolylines.hasOwnProperty(id)) {
                        vm.rowPolylines[id].setOptions({ strokeWeight: 0 });
                    }
                }
                filteredList.forEach(function (row) {
                    var rowId = row.id;
                    if (vm.rowPolylines[rowId]) {
                        vm.rowPolylines[rowId].setOptions({ strokeWeight: 6, strokeColor: row.colorcode });
                    }
                });
                vm.filteredRowList = filteredList;
                vm.isFilterMenuOpen = false;
            };
            vm.resetFilters = function () {
                for (var o = 0; o < vm.uniquePhases.length; o++) {
                    vm.uniquePhases[o].selected = false;
                }
                for (var p = 0; p < vm.uniqueRowTypes.length; p++) {
                    vm.uniqueRowTypes[p].selected = false;
                }
                vm.isVerifiedFilter = false;
                vm.isNotVerifiedFilter = false;
                vm.isDeviatedFilter = false;
                for (var id in vm.rowPolylines) {
                    if (vm.rowPolylines.hasOwnProperty(id)) {
                        vm.rowPolylines[id].setOptions({ strokeWeight: 6 });
                    }
                }

                vm.uniqueRowTypes = []
                vm.filteredRowList = vm.rowListResponse;
            };
            vm.closeFilters = function () {
                vm.isFilterMenuOpen = false;
            };
        };
        vm.SectionMap = new google.maps.Map(
            document.getElementById("SectionMap_canvasStart"),
            myOption($rootScope.rolloutMapInitZoom, center)
        );
        geoLocation(vm.SectionMap)
        if (vm.trRoute && vm.trRoute.routePoints != null && vm.trRoute.routePoints.length) {
            vm.trRoute.routePoints.sort(function (a, b) { return a.routePointNumber - b.routePointNumber });

            var groupedRoutePoints = {};
            vm.trRoute.routePoints.forEach(function (each) {
                if (each.routePointType == "COORDINATE" && each.phase != null) {
                    if (!groupedRoutePoints[each.sessionNumber]) {
                        groupedRoutePoints[each.sessionNumber] = [];
                    }
                    groupedRoutePoints[each.sessionNumber].push({ lat: each.latitude, lng: each.longitude });
                }
            });

            Object.keys(groupedRoutePoints).forEach(function (sessionNumber) {
                var sessionCoordinates = groupedRoutePoints[sessionNumber];
                var bounds = new google.maps.LatLngBounds();

                if (sessionCoordinates.length) {
                    sessionCoordinates.forEach(function (coord) {
                        bounds.extend(coord);
                    });

                    var polyline = new google.maps.Polyline({
                        path: sessionCoordinates,
                        geodesic: true,
                        strokeColor: "#FD7A24",
                        strokeOpacity: 1.0,
                        strokeWeight: 4,
                        zIndex: 1
                    });
                    polyline.setMap(vm.SectionMap);
                }
                vm.SectionMap.fitBounds(bounds);
            });
        }

        vm.getSectionPath = function () {
            //section path
            vm.sectionPolyline = {}
            routeDetail.getRoutesDetails(entity.id, function (response) {
                vm.sectionResponse = response
                vm.sectionResponse.forEach(function (section) {
                    setTimeout(function () {
                        TaskSectionById.get(section.sectionId, function (sectionVal) {
                            var sectionPathResponse = sectionVal;
                            var polylineCoordinates = []
                            var bounds = new google.maps.LatLngBounds();
                            sectionPathResponse.COORDINATE.forEach(function (coordinate) {
                                if (coordinate.routePointType === 'COORDINATE') {
                                    var latLng = {
                                        lat: coordinate.latitude,
                                        lng: coordinate.longitude
                                    };
                                    polylineCoordinates.push(latLng);
                                    bounds.extend(latLng);
                                    var polyline = new google.maps.Polyline({
                                        path: polylineCoordinates,
                                        geodesic: true,
                                        strokeColor: '#FD7A24',
                                        strokeOpacity: 1.0,
                                        strokeWeight: 4,
                                        zIndex: 1

                                    });
                                    polyline.setMap(vm.SectionMap);
                                    vm.sectionPolyline[section.sectionId] = polyline
                                }
                            });

                        })
                    }, 2000)
                })
            })
            vm.taskPolyline = vm.taskPolyline || {};
            vm.dailyLogPolyline = vm.dailyLogPolyline || {};
            vm.obstaclePolyline = vm.obstaclePolyline || {};
            vm.sectionResponse.forEach(function (task) {
                Inventory.taskBySection(task.sectionId, function (response) {
                    vm.taskLogResponse = response;
                    vm.taskLogResponse.forEach(function (task) {
                        Inventory.taskPathByID(task.id, function (response) {
                            if (response.COORDINATE !== undefined) {
                                var polylineCoordinates = [];
                                response.COORDINATE.forEach(function (coordinate) {
                                    if (coordinate.routePointType === 'COORDINATE') {
                                        var latLng = {
                                            lat: coordinate.latitude,
                                            lng: coordinate.longitude
                                        };
                                        polylineCoordinates.push(latLng);
                                    }
                                });
                                var taskPolyline = new google.maps.Polyline({
                                    path: polylineCoordinates,
                                    geodesic: true,
                                    strokeColor: '#c89f9c',
                                    strokeOpacity: 0.4,
                                    strokeWeight: 4,
                                    zIndex: 2
                                });
                                taskPolyline.setMap(vm.SectionMap);
                                vm.taskPolyline[task.id] = taskPolyline;
                            }
                        });
                        taskComment.getTaskLogsPaths(task.id, function (response) {
                            function drawPath(logType, color, zIndex) {
                                var segmentCoordinates = [];
                                DailyLogByTaskIdWithType.query({ id: task.id, type: logType }, function (logs) {
                                    response.forEach(function (item) {
                                        logs.forEach(function (log) {
                                            if (item.id == log.id) {
                                                var sortedCoordinates = item.value.COORDINATE.filter(function (coord) { 
                                                        return coord.routePointType === "COORDINATE" && coord.latitude && coord.longitude 
                                                    })
                                                    .sort(function (a, b) {
                                                        var numA = parseFloat(a.routePointNumber) || 0;
                                                        var numB = parseFloat(b.routePointNumber) || 0;
                                                        return numA - numB;
                                                    });
                                                    sortedCoordinates.forEach(function (coord) {
                                                    segmentCoordinates.push({
                                                        lat: coord.latitude,
                                                        lng: coord.longitude,
                                                        routePointNumber: parseFloat(coord.routePointNumber),
                                                    });                                  
                                                });
                                                if (segmentCoordinates.length > 1) {
                                                    drawPolyline(segmentCoordinates, color, zIndex);
                                                }
                                                segmentCoordinates = [];
                                            }
                                        });
                                    });
                                });
                                var pathPolyline = new google.maps.Polyline({
                                    path: segmentCoordinates,
                                    geodesic: true,
                                    strokeColor: color,
                                    strokeOpacity: 0.4,
                                    strokeWeight: 4,
                                    zIndex: zIndex
                                });
                                pathPolyline.setMap(vm.SectionMap);
                            }
                            function drawPolyline(coordinates, color, zIndex, bounds) {
                                var polyline = new google.maps.Polyline({
                                    path: coordinates.map(function (latLng) {
                                        return new google.maps.LatLng(latLng.lat, latLng.lng);
                                    }),
                                    geodesic: true,
                                    strokeColor: color,
                                    strokeOpacity: 1.0,
                                    strokeWeight: 4,
                                    zIndex: zIndex,
                                });
                                polyline.setMap(vm.SectionMap);
                            }
                            drawPath("OBSTACLE", "#ff0000", 4);
                            drawPath("PROGRESS", "#00e658", 3);
                        });
                    });
                });
            });
            vm.taskCombinedData = [];
            vm.sectionCombinedData = [];
            var taskDataLoaded = false;
            var sectionDataLoaded = false;

            function checkAndMergeData() {
                if (taskDataLoaded && sectionDataLoaded) {
                    var taskLengths = {};
                    var obstacleLengths = {};
                    var taskLengthsLoadedCount = 0;
                    var obstacleLengthsLoadedCount = 0;

                    vm.taskCombinedData.forEach(function (task) {
                        dailyLog.query({ id: task.taskId }, function (response) {
                            var totalLength = 0;
                            angular.forEach(response, function (log) {
                                totalLength += log.workLength;
                            });
                            taskLengths[task.taskId] = totalLength;
                            taskLengthsLoadedCount++;
                            if (taskLengthsLoadedCount === vm.taskCombinedData.length && obstacleLengthsLoadedCount === vm.taskCombinedData.length) {
                                mergeTaskSectionData(taskLengths, obstacleLengths);
                            }
                        });
                        dailyLogByTaskId.query({ id: task.taskId }, function onSuccess(response) {
                            var obstacleLength = 0;
                            angular.forEach(response, function (log) {
                                obstacleLength += log.workLength;
                            });
                            obstacleLengths[task.taskId] = obstacleLength;
                            obstacleLengthsLoadedCount++;
                            if (taskLengthsLoadedCount === vm.taskCombinedData.length && obstacleLengthsLoadedCount === vm.taskCombinedData.length) {
                                mergeTaskSectionData(taskLengths, obstacleLengths);
                            }
                        });
                    });
                    vm.updateTasksWithMaterials()
                }
            }

            function mergeTaskSectionData(taskLengths, obstacleLengths) {
                vm.mergedArray = vm.taskCombinedData.map(function (task) {
                    var matchingSection = vm.sectionCombinedData.find(function (section) {
                        return section.sectionId === task.sectionId;
                    });
                    return {
                        TaskName: task.TaskName,
                        taskId: task.taskId,
                        taskLength: task.taskLength || "N/A",
                        fromLatlng: task.fromLatlng || 'N/A',
                        toLatlng: task.toLatlng || "N/A",
                        routeId: task.routeId,
                        sectionId: task.sectionId,
                        sectionName: matchingSection ? matchingSection.sectionName : "Unknown Section",
                        vendorName: matchingSection ? matchingSection.vendorName : "Unknown Vendor",
                        totalLength: taskLengths[task.taskId] || 0,
                        obstacleLength: obstacleLengths[task.taskId] || 0,
                        isExpanded: false
                    };
                });

                vm.uniqueMaterials = [];
                vm.mtrlTxSummaries = {};

                angular.forEach(vm.mergedArray, function (item) {
                    SummaryPost.mtrlTx({ taskIds: [item.taskId] }, function (response) {
                        vm.mtrlTxSummaries[item.taskId] = response;
                        angular.forEach(response, function (material) {
                            var existing = vm.uniqueMaterials.find(function (m) {
                                return m.materialName === material.materialName && m.transactionType === material.transactionType;
                            });
                            if (existing) {
                                existing.totalNoOfUnits += material.totalNoOfUnits;
                            } else {
                                vm.uniqueMaterials.push({
                                    materialName: material.materialName,
                                    transactionType: material.transactionType,
                                    totalNoOfUnits: material.totalNoOfUnits,
                                    length: material.unitOfMeasurementName
                                });
                            }
                        });
                        vm.updateTasksWithMaterials();
                    });
                });
                loadMaterialsForTasks();
            }
            routeDetail.getRoutesDetails(entity.id, function (response) {
                vm.sectionResponse = response;
                angular.forEach(vm.sectionResponse, function (section) {
                    vm.sectionCombinedData.push({
                        sectionId: section.sectionId,
                        routeId: section.routeId,
                        sectionName: section.sectionName,
                        vendorName: section.vendorName || 'N/A',
                    });
                });
                sectionDataLoaded = true;
                function loadTasksSequentially(index) {
                    if (index >= vm.sectionResponse.length) {
                        taskDataLoaded = true;
                        checkAndMergeData();
                        return;
                    }

                    var section = vm.sectionResponse[index];
                    routeDetail.taskBySection(section.sectionId, function (taskResponse) {
                        angular.forEach(taskResponse, function (task) {
                            vm.taskCombinedData.push({
                                TaskName: task.name,
                                taskId: task.id,
                                taskLength: task.length || 'N/A',
                                fromLatlng: task.fromLatlng || 'N/A',
                                toLatlng: task.toLatLng || "N/A",
                                routeId: section.routeId,
                                sectionId: section.sectionId
                            });
                        });
                        loadTasksSequentially(index + 1);
                    });
                }
                loadTasksSequentially(0);
                vm.updateTasksWithMaterials();
            });

            vm.toggleSection = function (item) {
                vm.mergedArray.forEach(function (otherItem) {
                    if (otherItem !== item) {
                        otherItem.isExpand = false;
                    }
                });
                item.isExpand = !item.isExpand;
                if (item.isExpand) {
                    SummaryPost.mtrlTx({ taskIds: [item.taskId] }, function (response) {
                        vm.mtrlTxSummary = response;
                    });
                }
            };
            vm.tasksWithMaterials = [];
            function allMaterialsLoaded() {
                return vm.mergedArray.every(function (item) { return vm.mtrlTxSummaries && vm.mtrlTxSummaries[item.taskId] });
            }
            vm.updateTasksWithMaterials = function () {
                vm.tasksWithMaterials = [];
                if (vm.mergedArray && vm.mergedArray.length > 0) {
                    vm.mergedArray.forEach(function (item) {
                        if (vm.mtrlTxSummaries && vm.mtrlTxSummaries[item.taskId]) {
                            var materialMap = {};
                            vm.mtrlTxSummaries[item.taskId].forEach(function (material) {
                                var uniqueKey = material.materialName;
                                if (!materialMap[uniqueKey]) {
                                    materialMap[uniqueKey] = {
                                        sectionName: item.sectionName,
                                        TaskName: item.TaskName,
                                        materialName: material.materialName,
                                        unitOfMeasurementName: material.unitOfMeasurementName,
                                        Requested: 0,
                                        Received: 0,
                                        Utilized: 0,
                                        Reconciled: 0
                                    };
                                }
                                switch (material.transactionType) {
                                    case 'REQUESTED':
                                        materialMap[uniqueKey].Requested += material.totalNoOfUnits;
                                        break;
                                    case 'RECEIVED':
                                        materialMap[uniqueKey].Received += material.totalNoOfUnits;
                                        break;
                                    case 'UTILIZED':
                                        materialMap[uniqueKey].Utilized += material.totalNoOfUnits;
                                        break;
                                    case 'RECONCILED':
                                        materialMap[uniqueKey].Reconciled += material.totalNoOfUnits;
                                        break;
                                }
                            });
                            for (var key in materialMap) {
                                vm.tasksWithMaterials.push(materialMap[key]);
                            }
                        }
                    });
                }
            };
            function loadMaterialsForTasks() {
                angular.forEach(vm.mergedArray, function (item) {
                    if (!vm.mtrlTxSummaries) {
                        vm.mtrlTxSummaries = {};
                    }

                    SummaryPost.mtrlTx({ taskIds: [item.taskId] }, function (response) {
                        vm.mtrlTxSummaries[item.taskId] = response;
                        if (allMaterialsLoaded()) {
                            vm.updateTasksWithMaterials();
                        }
                    });
                });
            }
            // function mergeTaskSectionData(taskLengths, obstacleLengths) {
            //     var mergedArray = vm.taskCombinedData.map(function (task) {
            //         var matchingSection = vm.sectionCombinedData.find(function (section) {
            //             return section.sectionId === task.sectionId;
            //         });
            //         return {
            //             TaskName: task.TaskName,
            //             taskId: task.taskId,
            //             taskLength: task.taskLength || "N/A",
            //             fromLatlng: task.fromLatlng || 'N/A',
            //             toLatlng: task.toLatlng || "N/A",
            //             routeId: task.routeId,
            //             sectionId: task.sectionId,
            //             sectionName: matchingSection ? matchingSection.sectionName : "Unknown Section",
            //             vendorName: matchingSection ? matchingSection.vendorName : "Unknown Vendor",
            //             totalLength: taskLengths[task.taskId] || 0,
            //             obstacleLength: obstacleLengths[task.taskId] || 0,
            //             isExpanded: false
            //         };
            //     });
            //     vm.mergedArray = mergedArray;
            //     loadMaterialsForTasks();
            // }
        }
        vm.downloadMaterialTnx = function () {
            var csvContent = "data:text/csv;charset=utf-8,";
            csvContent += "Sl No,Section Name,Task Name,Material Name,Unit,Requested,Received,Utilized,Reconciled\n";
            vm.tasksWithMaterials.forEach(function (material, index) {
                var row = [
                    index + 1,
                    material.sectionName,
                    material.TaskName,
                    material.materialName,
                    material.unitOfMeasurementName || 0,
                    material.Requested || 0,
                    material.Received || 0,
                    material.Utilized || 0,
                    material.Reconciled || 0
                ].join(",");
                csvContent += row + "\n";
            });

            var encodedUri = encodeURI(csvContent);
            var downloadLink = document.createElement("a");
            downloadLink.href = encodedUri;
            downloadLink.download = entity.name + "_Material_List.csv";
            downloadLink.click();
        };

        vm.startPoint = null;
        vm.endPoint = null;
        vm.highlightLog = function (id) {
            var polyline = vm.dailyLogPolyline[id];
            if (polyline) {
                polyline.setOptions({
                    strokeWeight: 16
                });
                var path = polyline.getPath().getArray();
                var fromLatLng = path[0];
                var toLatLng = path[path.length - 1];
                if (vm.startPoint) {
                    vm.startPoint.setMap(null);
                }
                if (vm.endPoint) {
                    vm.endPoint.setMap(null);
                }
                vm.startPoint = new google.maps.Marker({
                    position: fromLatLng,
                    map: vm.SectionMap,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: "content/images/sections.png",
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            -4
                        ),
                        labelOrigin: new google.maps.Point(25, 22)
                    },
                    label: {
                        text: 'Start',
                        color: '#000000',
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                });
                vm.endPoint = new google.maps.Marker({
                    position: toLatLng,
                    map: vm.SectionMap,
                    icon: {
                        scaledSize: new google.maps.Size(iconSize, iconSize),
                        url: "content/images/sections.png",
                        anchor: new google.maps.Point(
                            iconSize / 2,
                            -4
                        ),
                        labelOrigin: new google.maps.Point(25, 22)
                    },
                    label: {
                        text: 'End',
                        color: '#000000',
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }
                });
            }
        };
        vm.resetLog = function () {
            Object.keys(vm.dailyLogPolyline).forEach(function (key) {
                var polyline = vm.dailyLogPolyline[key];
                if (polyline) {
                    polyline.setOptions({
                        strokeWeight: 6
                    });
                }
            });
            if (vm.startPoint) {
                vm.startPoint.setMap(null);
                vm.startPoint = null;
            }
            if (vm.endPoint) {
                vm.endPoint.setMap(null);
                vm.endPoint = null;
            }
        };
        vm.highlightTask = function (taskId) {
            for (var id in vm.taskPolyline) {
                if (id == taskId) {
                    vm.taskPolyline[id].setOptions({ strokeWeight: 12 });
                } else {
                    vm.taskPolyline[id].setOptions({ strokeWeight: 7 });
                }
            }
        }
        vm.resetTask = function () {
            for (var id in vm.taskPolyline) {
                if (vm.taskPolyline.hasOwnProperty(id)) {
                    vm.taskPolyline[id].setOptions({ strokeWeight: 7 });
                }
            }
        }
        vm.highlightSection = function (sectionId) {
            var iconSize = 30;
            for (var id in vm.sectionPolyline) {
                if (vm.sectionPolyline.hasOwnProperty(id)) {
                    if (id == sectionId) {
                        vm.sectionPolyline[id].setOptions({
                            strokeWeight: 7
                        });
                    }
                }
            }
        };
        vm.resetRoute = function () {
            for (var id in vm.sectionPolyline) {
                if (vm.sectionPolyline.hasOwnProperty(id)) {
                    vm.sectionPolyline[id].setOptions({ strokeWeight: 5 });
                }
            }
        }
        setTimeout(function () {
            TaskStatusById.post(
                { routeIds: [entity.id] },
                function (statusresponse) {
                    vm.taskstatus = statusresponse;
                    if (vm.taskstatus.length) {
                        callTaskStatusByStagCat()
                    }
                }
            );
        }, 5000)
        function callTaskStatusByStagCat() {
            vm.objectTaskStatus = {}
            vm.taskstatus.forEach(function (eachElement) {
                if (eachElement.taskCategory == "Route-Task") {
                    createObjMapping(eachElement)
                } else if (eachElement.taskCategory == "Section-Task") {
                    createObjMapping(eachElement)
                }
            })
        }
        function createObjMapping(eachElement) {
            if (!vm.objectTaskStatus[eachElement.taskCategory]) {
                vm.objectTaskStatus[eachElement.taskCategory] = {};
            }
            if (!vm.objectTaskStatus[eachElement.taskCategory][eachElement.taskStage]) {
                vm.objectTaskStatus[eachElement.taskCategory][eachElement.taskStage] = []
            }
            if (eachElement.totalWorkLength && eachElement.taskLength) {
                vm.objectTaskStatus[eachElement.taskCategory][eachElement.taskStage].push({
                    taskTypeName: eachElement.taskTypeName,
                    totalWorkLength: eachElement.totalWorkLength,
                    taskLength: eachElement.taskLength,
                    percentage: eachElement.percentage
                });
            }
        }
        vm.taskColors = {
            'Machinery Trenching': '#33FF57',
            'HDD ( Horizontal Directional Drilling )': '#33FF57',
            'Validation': '#3357FF'
        };

        setTimeout(function () {
            callTransaction("Material")
        }, 7000)
        setTimeout(function () {
            callTransaction("Resource")
        }, 8000)
        function callTransaction(str) {

            if (str == "Resource") {
                SummaryPost.resTx({
                    "projectIds": [entity.projectId],
                    "routeIds": [entity.id]
                }, function (response) {
                    if (response.length) {
                        vm.resTxSummary = response
                        var data = [];
                        vm.mapResourceTransaction = {};

                        response.forEach(function (item) {
                            if (!vm.mapResourceTransaction[item.resourceName]) {
                                vm.mapResourceTransaction[item.resourceName] = {
                                    item: item.resourceName,
                                    PLANNED: 0,
                                    REQUESTED: 0,
                                    RECEIVED: 0,
                                    UTILIZED: 0,
                                    RECONCILED: 0
                                };
                                data.push(vm.mapResourceTransaction[item.resourceName]);
                            }
                            vm.mapResourceTransaction[item.resourceName][item.transactionType] += item.totalNoOfResources;
                        });

                        d3.selectAll("#RESOURCES > *").remove();
                        showDashboardData(data, 'RESOURCES')
                    }
                })
            } else if (str == "Material") {
                SummaryPost.mtrlTx(
                    {
                        projectIds: [entity.projectId],
                        routeIds: [entity.id],
                    },
                    function (response) {
                        vm.mtrlTxSummary = response;
                        if (response.length) {
                            var data = [];
                            var map = {};

                            response.forEach(function (item) {
                                if (!map[item.materialName]) {
                                    map[item.materialName] = {
                                        item: item.materialName,
                                        PLANNED: 0,
                                        REQUESTED: 0,
                                        RECEIVED: 0,
                                        UTILIZED: 0,
                                        RECONCILED: 0,
                                    };
                                    data.push(map[item.materialName]);
                                }
                                map[item.materialName][item.transactionType] +=
                                    item.totalNoOfUnits;
                            });

                            //console.log(data)

                            d3.selectAll("#materialChart > *").remove();
                            callUOM(data)
                            showDashboardData(data, "materialChart");
                        }
                    })
            }

        }

        function callUOM(data) {
            vm.uomMaterialTransaction = {}
            data.forEach(function (item) {
                if (item.UOM != null) {
                    if (!vm.uomMaterialTransaction[item.UOM]) {
                        vm.uomMaterialTransaction[item.UOM] = []
                    }
                    vm.uomMaterialTransaction[item.UOM].push(item)
                }
            })


            if (vm.uomMaterialTransaction) {
                if (vm.storeMaterialTransactionName.length) {
                    vm.storeMaterialTransactionName.forEach(function (eachElement) {
                        d3.selectAll("#" + eachElement + " > *").remove();
                    })
                }
                var parentContainer = document.getElementById(
                    "parentMaterial"
                );
                var newDiv = document.createElement(
                    "div"
                );
                Object.keys(vm.uomMaterialTransaction).forEach(function (key) {
                    vm.storeMaterialTransactionName.push(key)
                    newDiv.id = key
                    parentContainer.appendChild(newDiv);
                    showDashboardData(
                        vm.uomMaterialTransaction[key],
                        key
                    );
                })
            }
        }
        function showDashboardData(data, id) {
            var margin, width, height, svg, x, y, xAxis, yAxis;
            // Your existing code for setting up the c
            margin = { top: 20, right: 30, bottom: 85, left: 40 };

            // Get the current width and height of the window
            width = window.innerWidth * 0.8 - margin.left - margin.right;
            height =
                window.innerHeight * 0.5 - margin.top - margin.bottom - 100;

            x = d3.scale
                .ordinal()
                .rangeRoundBands([0, width], 0.1)
                .domain(
                    data.map(function (d) {
                        return d.item;
                    })
                );

            y = d3.scale
                .linear()
                .range([height, 0])
                .domain([
                    0,
                    d3.max(data, function (d) {
                        return d3.max([
                            d.PLANNED,
                            d.REQUESTED,
                            d.RECEIVED,
                            d.UTILIZED,
                            d.RECONCILED,
                        ]);
                    }),
                ]);

            xAxis = d3.svg.axis().scale(x).orient("bottom");

            //yAxis = d3.svg.axis().scale(y).orient("left");

            yAxis = d3.svg.axis()
                .scale(y)
                .orient("left")
                .ticks(5) // Set the number of ticks
                .tickFormat(d3.format("d")); // Use a format that removes decimals


            svg = d3
                .select("#" + id)
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr(
                    "transform",
                    "translate(" + margin.left + "," + margin.top + ")"
                );

            svg.append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(0," + height + ")")
                .call(xAxis)
                .selectAll("text")
                .style("text-anchor", "end")
                .style("font-family", "sans-serif")
                .style("font-size", "11px")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-35)");

            svg.append("g").attr("class", "y axis").call(yAxis);

            // Add text next to the y-axis
            svg.append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - margin.left)
                .attr("x", 0 - height / 2 - (margin.right + 40))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text(id);

            var colors = [
                "#AF7AC5",
                "#7FB3D5",
                "#76D7C4",
                "#E59866",
                "#ffdcf1",
            ];

            var categories = [
                "PLANNED",
                "REQUESTED",
                "RECEIVED",
                "UTILIZED",
                "RECONCILED",
            ];

            var tooltip1 = d3
                .select("body")
                .append("div")
                .attr("class", "toolTipA");



            categories.forEach(function (category, i) {
                svg.selectAll(".bar-" + category)
                    .data(data)
                    .enter()
                    .append("rect")
                    .attr("class", "bar-" + category)
                    .attr("x", function (d) {
                        return (
                            x(d.item) + (x.rangeBand() / categories.length) * i
                        );
                    })
                    .attr("width", x.rangeBand() / categories.length)
                    .attr("y", function (d) {
                        return y(Math.max(0.1, d[category]));
                    })
                    .attr("height", function (d) {
                        return Math.max(1, height - y(Math.max(0.1, d[category])));
                    })
                    .style("fill", colors[i])

                    .on("mouseover", function (d) {

                        if (d[category] != 0) {
                            tooltip1
                                .style("left", d3.event.pageX - 50 + "px")
                                .style("top", d3.event.pageY - 50 + "px")
                                .style("display", "inline-block")
                                .style("fill", "black")
                                .html(category + ' - ' + d[category]);
                            return tooltip1.style("visibility", "visible");
                        }

                    })
                    .on("mousemove", function () {
                        return tooltip1
                            .style("top", d3.event.pageY - 10 + "px")
                            .style("left", d3.event.pageX + 10 + "px");
                    })
                    .on("mouseout", function () {
                        return tooltip1.style("visibility", "hidden");
                    });
            });


            /*  var legend = svg
                 .selectAll(".legend")
                 .data(categories)
                 .enter()
                 .append("g")
                 .attr("class", "legend")
                 .attr("transform", function (d, i) {
                     return "translate(" + i * 80 + ", -20)";
                 });
 
             legend
                 .append("rect")
                 .attr("x", 0)
                 .attr("width", 18)
                 .attr("height", 18)
                 .style("fill", function (d, i) {
                     return colors[i];
                 });
 
             legend
                 .append("text")
                 .attr("x", 25)
                 .attr("y", 9)
                 .attr("dy", ".35em")
                 .style("text-anchor", "start")
                 .text(function (d) {
                     return d;
                 }); */

            /* var element = document.getElementById('getHeight');
                       vm.height = element.clientHeight;  */
        }

        $scope.names = ['PLANNED', 'REQUESTED', 'RECEIVED', 'UTILIZED', 'RECONCILED'];
        $scope.legends = [
            { name: 'PLANNED', color: '#AF7AC5' },
            { name: 'REQUESTED', color: '#7FB3D5' },
            { name: 'RECEIVED', color: '#76D7C4' },
            { name: 'UTILIZED', color: '#E59866' },
            { name: 'RECONCILED', color: '#ffdcf1' }
        ];

        function addLatLng(event, map) {
            var path = polyMeasureTool.getPath();
            path.push(event.latLng);


            addPointer(event.latLng, map);

            var point1, point2
            for (var i = 0; i < path.getLength() - 1; i++) {
                point1 = path.getAt(i);
                point2 = path.getAt(i + 1);
                segmentDistance = google.maps.geometry.spherical.computeDistanceBetween(point1, point2);


                // Add distance marker
                midpoint = google.maps.geometry.spherical.interpolate(point1, point2, 0.5);

            }


            if (path.getLength() > 1) {
                totalDistance += segmentDistance;
                var marker = new google.maps.Marker({
                    position: midpoint,
                    map: map,
                    icon: {
                        url: 'content/images/iconTransparent.png',
                    },
                    label: {
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "14px",
                        text: formatDistance(segmentDistance),
                    },
                });
                distanceMarkers.push(marker);

                var totalDistanceMarker = new google.maps.Marker({
                    position: point2,
                    map: map,
                    icon: {
                        url: 'content/images/iconTransparent.png',
                    },
                    label: {
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "14px",
                        text: "Total : " + formatDistance(totalDistance),
                    },
                });
                totalDistanceMarkers.push(totalDistanceMarker);
            }

        }

        function addPointer(location, map) {
            var pointerIcon = {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 5,
                strokeColor: 'black',
                fillColor: 'white',
                fillOpacity: 1,
                strokeWeight: 2
            };

            var pointer = new google.maps.Marker({
                position: location,
                map: map,
                icon: pointerIcon
            });
            pointerMarkers.push(pointer);
        }
        function formatDistance(distance) {
            // Convert meters to kilometers
            var km = distance / 1000;

            // Show in meters if less than 1 km, otherwise show in kilometers
            if (km < 1) {
                return distance.toFixed(0) + ' m';
            } else {
                return km.toFixed(2) + ' km';
            }
        }

        function clearMeasureTool(arr) {
            for (var i = 0; i < arr.length; i++) {
                arr[i].setMap(null)
            }
        }

        function geoLocation(map) {
            var goTo = document.createElement("button");
            goTo.setAttribute("title", "Location Coordinates");
            goTo.classList.add("custom-map-control-button");
            var gotoIcon = document.createElement("i");
            gotoIcon.classList.add("fa");
            gotoIcon.classList.add("fa-location-arrow");
            gotoIcon.classList.add("geo");
            goTo.appendChild(gotoIcon);
            map.controls[google.maps.ControlPosition.RIGHT_TOP].push(goTo);

            goTo.addEventListener("click", function () {
                gotoLatitute();
            });


            measureTool = document.createElement("button");
            measureTool.setAttribute("title", "Measure Distance");
            measureTool.classList.add("custom-map-control-button");
            measureTool.id = "measureDistance";
            measureToolIcon.classList.add("fa");
            measureToolIcon.classList.add("fa-arrows-h");
            measureToolIcon.classList.add("ruler");
            measureTool.appendChild(measureToolIcon);
            map.controls[google.maps.ControlPosition.RIGHT_TOP].push(measureTool);
            measureTool.addEventListener("click", function () {

                if (measureEnabled) {
                    google.maps.event.removeListener(vm.clickListener);

                    totalDistance = 0
                    clearMeasureTool(distanceMarkers)
                    clearMeasureTool(pointerMarkers)
                    clearMeasureTool(arrpolyMeasureTool)
                    clearMeasureTool(totalDistanceMarkers)
                    distanceMarkers = []
                    totalDistanceMarkers = []
                    pointerMarkers = []
                    arrpolyMeasureTool = []


                    measureEnabled = false

                    measureTool.setAttribute("title", "Measure Distance");
                    measureToolIcon.classList.remove("fa-times");
                    measureToolIcon.classList.add("fa-arrows-h");


                } else {
                    measureEnabled = true
                    polyMeasureTool = new google.maps.Polyline({
                        strokeColor: 'black',
                        strokeOpacity: 1.0,
                        strokeWeight: 3
                    });
                    polyMeasureTool.setMap(map);
                    arrpolyMeasureTool.push(polyMeasureTool)

                    vm.clickListener = map.addListener('click', function (event) {
                        addLatLng(event, map)
                    });


                    measureTool.setAttribute("title", "Clear Distance");
                    measureToolIcon.classList.remove("fa-arrows-h");
                    measureToolIcon.classList.add("fa-times");
                }
            });

            // locationButton.addEventListener("click", function () {
            //     // Try HTML5 geolocation.
            //     if (navigator.geolocation) {
            //         navigator.geolocation.getCurrentPosition(
            //             function (position) {
            //                 var pos = {
            //                     lat: position.coords.latitude,
            //                     lng: position.coords.longitude,
            //                 };

            //                 infoWindow.setPosition(pos);
            //                 infoWindow.setContent("Location found.");
            //                 infoWindow.open(map);
            //                 // var image = {
            //                 //     url: "content/images/geoLocation.png", // url
            //                 //     scaledSize: new google.maps.Size(25, 25), // scaled size
            //                 //     origin: new google.maps.Point(0, 0), // origin
            //                 //     anchor: new google.maps.Point(0, 0), // anchor
            //                 // };
            //                 // var markerGeo = new google.maps.Marker({
            //                 //     position: pos,
            //                 //     icon: image,
            //                 // });
            //                 // map.setZoom(20);
            //                 // markerGeo.setMap(map);
            //                 map.setZoom(18);
            //                 map.setCenter(pos);
            //             },
            //             function () {
            //                 handleLocationError(
            //                     true,
            //                     infoWindow,
            //                     map.getCenter()
            //                 );
            //             },
            //             {
            //                 enableHighAccuracy: true,
            //                 timeout: 5000,
            //                 maximumAge: 0,
            //             }
            //         );
            //     } else {
            //         // Browser doesn't support Geolocation
            //         handleLocationError(false, infoWindow, map.getCenter());
            //     }
            // });

            function handleLocationError(
                browserHasGeolocation,
                infoWindow,
                pos
            ) {
                infoWindow.setPosition(pos);
                infoWindow.setContent(
                    browserHasGeolocation
                        ? "Error: The Geolocation service failed."
                        : "Error: Your browser doesn't support geolocation."
                );
                infoWindow.open(map);
            }
        }
        var input = document.getElementById("field_pinLat");
        input.addEventListener("input", function () {
            this.value = this.value.replace(/[^-0-9\,\.]/, "");
        });

        function showZoomLocation() {
            var latlng = vm.getLocationCoordinate.split(",");
            if (gotoMarker != null) {
                gotoMarker.setMap(null);
            }

            if (vm.getLocationCoordinate != null) {
                var assetCenterLatLng = {
                    lat: parseFloat(latlng[0]),
                    lng: parseFloat(latlng[1]),
                };

                if ($scope.tab == 10) {
                    vm.newMaps.setCenter(assetCenterLatLng);
                    vm.newMaps.setZoom(18);
                    var startMarker = new google.maps.Marker({
                        map: vm.newMaps,
                        position: assetCenterLatLng,
                    });
                    input.value = ''
                }
                if ($scope.tab == 9) {
                    vm.newMap.setCenter(assetCenterLatLng);
                    vm.newMap.setZoom(18);
                    var startMarker = new google.maps.Marker({
                        map: vm.newMap,
                        position: assetCenterLatLng,
                    });
                    input.value = ''
                }
                if ($scope.tab == 1) {
                    locations.map.setCenter(assetCenterLatLng);
                    // locations.map.setZoom(18);
                    var startMarker = new google.maps.Marker({
                        map: locations.map,
                        position: assetCenterLatLng,
                    });
                    input.value = ''
                }
                gotoMarker = startMarker;
                $("#modalLatLongs").removeClass("in");
                $(".modal-backdrop").remove();
                $("#modalLatLongs").hide();
            }

        }
        function gotoLatitute() {
            $("#modalLatLongs").show();
            /* noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
        }

        function gotoLatituteCity() {
            $("#modalLatLongs").show();
            /* noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
        }

        function closeGotoLatitute(id) {
            $("#" + id).removeClass("in");
            $(".modal-backdrop").remove();
            $("#" + id).hide();
        }
        function closeGotoLatituteCity() {
            $("#modalLatLongs").removeClass("in");
            $(".modal-backdrop").remove();
            $("#modalLatLongs").hide();
        }

        function showLayers() {
            vm.currentZoom = locations.map.getZoom();

            vm.getCenter = locations.map.center;

            $("#modalLayer").show();
            /* noDataText = document.getElementById("noData");
            noDataText.style.display = "none"; */
        }
        vm.downloadMaterial = function () {
            BaseUrl.getBaseUrl(function (baseurl) {
                var request = new XMLHttpRequest();
                request.open('GET', baseurl.stringValue + "api/rollout/mtrl-tx-summary-export-excel/" + vm.trRoute.projectId + "/" + vm.trRoute.id, true);
                request.setRequestHeader('Authorization', 'Bearer ' + $localStorage.authenticationToken);
                request.responseType = 'blob';

                request.onload = function (e) {
                    if (this.status === 200) {
                        var blob = this.response;
                        if (blob.size > 0) {
                            if (window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveBlob(blob, 'test.xlsx');
                            }
                            else {
                                var downloadLink = window.document.createElement('a');
                                var contentTypeHeader = request.getResponseHeader("Content-Type");
                                downloadLink.href = window.URL.createObjectURL(new Blob([blob], {
                                    type: contentTypeHeader
                                }));
                                downloadLink.download = 'MtrlTxSummaryExportExcel.xlsx';
                                document.body.appendChild(downloadLink);
                                downloadLink.click();
                                document.body.removeChild(downloadLink);
                            }
                        }
                    }
                };
                request.send();
            });
        }

        vm.downloadResource = function () {
            BaseUrl.getBaseUrl(function (baseurl) {
                var request = new XMLHttpRequest();
                request.open('GET', baseurl.stringValue + "api/rollout/res-tx-summary-export-excel/" + vm.trRoute.projectId + "/" + vm.trRoute.id, true);
                request.setRequestHeader('Authorization', 'Bearer ' + $localStorage.authenticationToken);
                request.responseType = 'blob';

                request.onload = function (e) {
                    if (this.status === 200) {
                        var blob = this.response;
                        if (blob.size > 0) {
                            if (window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveBlob(blob, 'test.xlsx');
                            }
                            else {
                                var downloadLink = window.document.createElement('a');
                                var contentTypeHeader = request.getResponseHeader("Content-Type");
                                downloadLink.href = window.URL.createObjectURL(new Blob([blob], {
                                    type: contentTypeHeader
                                }));
                                downloadLink.download = 'ResTxSummaryExportExcel.xlsx';
                                document.body.appendChild(downloadLink);
                                downloadLink.click();
                                document.body.removeChild(downloadLink);
                            }
                        }
                    }
                };
                request.send();
            });
        }
        vm.updateMarkerVisibility = function () {
            if (vm.objectRouteImg && typeof vm.objectRouteImg.toggleMarkers === 'function') {
                vm.objectRouteImg.toggleMarkers();
            }
        };
		
		function loadAssets(routeId, lastId){
			
			routeDetail.getAssetsById(routeId,lastId, function (response) {
			               vm.assets = response.assets;
						   console.log(vm.assets);
			               var colorMap = {
			                   "Chamber": "#FF0000",
			                   "Pole": "#0000FF"
			               };
			               vm.assets.forEach(function (asset) {
							
			                   var coordinates = asset.assetCoordinates.map(function (coord) {
			                       return { lat: coord.latitude, lng: coord.longitude };
			                   });
			                   if (asset.assetTypeLayout === 'FIXED' && asset.phase === 'SURVEY') {
			                       var marker = new google.maps.Marker({
			                           position: coordinates[0],
			                           map: locations.map,
			                           title: asset.name,
			                           icon: {
			                               url: asset.assetTypeImagePath,
			                               scaledSize: new google.maps.Size(32, 32)
			                           }
			                       });
			                       var infowindow = new google.maps.InfoWindow();
			                       google.maps.event.addListener(marker, 'click', function () {
			                           if (openedInfoWindow !== null) {
			                               openedInfoWindow.close();
			                           }
			                           var contentString = '<div class="infoWindowhead">' +
			                               "<b>ID: " + asset.id + "</b><br>" +
			                               "<b>Name: " + asset.name + "</b><br>" +
			                               "<b>AssetTypeName: " + asset.assetTypeName + "</b><br>" +
			                               "<b>Latitude: " + coordinates[0].lat + "</b><br>" +
			                               "<b>Longitude: " + coordinates[0].lng + "</b><br>" +
			                               "</div>";
			                           infowindow.setContent(contentString);
			                           infowindow.open(locations.map, marker);
			                           openedInfoWindow = infowindow;
			                       });
			                   } else if (asset.assetTypeLayout === 'SPREAD' && asset.phase === 'SURVEY') {
			                       var polylineColor = '#0000FF';
			                       var drawPolyline = new google.maps.Polyline({
			                           path: coordinates,
			                           geodesic: true,
			                           strokeColor: polylineColor,
			                           strokeOpacity: 1.0,
			                           strokeWeight: 3
			                       });
			                       drawPolyline.setMap(locations.map);
			                       var polylineInfoWindow = new google.maps.InfoWindow();
			                       google.maps.event.addListener(drawPolyline, 'click', function (event) {
			                           if (openedInfoWindow !== null) {
			                               openedInfoWindow.close();
			                           }

			                           var contentString = '<div class="infoWindowhead">' +
			                               "<b>ID: " + asset.id + "</b><br>" +
			                               "<b>Name: " + asset.name + "</b><br>" +
			                               "<b>AssetTypeName: " + asset.assetTypeName + "</b><br>" +
			                               "<b>Coordinates: </b><br>";
			                           coordinates.forEach(function (coord, index) {
			                               contentString += "Point " + (index + 1) + ": " +
			                                   "Lat: " + coord.lat + ", Lng: " + coord.lng + "<br>";
			                           });
			                           contentString += "</div>";
			                           polylineInfoWindow.setContent(contentString);
			                           polylineInfoWindow.setPosition(event.latLng);
			                           polylineInfoWindow.open(locations.map);
			                           openedInfoWindow = polylineInfoWindow;
			                       });
			                   }
			               });
					
			           });
					  
		}
    }
})();